import { Box, Typography } from '@mui/material'
import React from 'react'
import a1 from "../imgs/a1.jpeg"
import a2 from "../imgs/a2.jpeg"
import useDarkMode from 'use-dark-mode';
function AboutPage() {
    const darkmode = useDarkMode();
    return (
        <Box sx={{ margin: "0 auto", mt: "80px", width: "90%", }}>
            <Typography sx={{ fontSize: "45px", fontWeight: "900", color: darkmode.value ? "white" : "#205e60", fontFamily: "Regular-Family", textAlign: "center" }}>About Us</Typography>
            <Box sx={{ display: "flex", gap: "50px", flexDirection: "column" }}>
                <Box sx={{ display: "flex", gap: "85px", justifyContent: "space-between", mt: "100px" }}>
                    <Box>
                        <Typography sx={{ color: darkmode.value ? "white" : "#21495F", fontSize: "35px", fontWeight: '700' }}>Foundation</Typography>
                        <Typography sx={{ color: darkmode.value ? "white" : "#21495F", fontSize: "20px", fontWeight: "500" }}>SubSource was launched on May 2nd, 2024, in response to Subscene’s announcement of shutting down. Initially, our mission was to preserve and continue the legacy of Subscene by hosting their Persian and English subtitle databases. However, the overwhelming demand from speakers and transRegular-Familyrs of other languages inspired us to broaden our horizons.
                        </Typography>
                    </Box>
                    <Box>
                        <Box sx={{ width: "650px", backgroundColor: "#80B9C2", display: "flex", justifyContent: "center", borderRadius: "40px" }}>
                            <img src={a1} alt='Foundation' width={350} height={350} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "85px", justifyContent: "space-between", mt: "100px" }}>
                    <Box>
                        <img src={a2} alt='Foundation' height={370} width={650} style={{ borderRadius: "40px" }} />
                    </Box>

                    <Box>
                        <Typography sx={{ color: darkmode.value ? "white" : "#21495F", fontSize: "35px", fontWeight: '700' }}>Vision</Typography>
                        <Typography sx={{ color: darkmode.value ? "white" : "#21495F", fontSize: "20px", fontWeight: "500" }}>Our vision now extends beyond Farsi subtitles. We’ve expanded our database and infrastructure to include contributors from diverse linguistic backgrounds across the globe. Our hope is to foster a collaborative community where passionate individuals can come together to enhance the accessibility of subtitles for movies, TV shows, and other media. By bridging language gaps, we aim to make entertainment more inclusive and enjoyable for everyone.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AboutPage
import { Box, Divider, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import '../css/LatestsPage.css'
import ReactTimeAgo from 'react-time-ago'
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid } from 'react-loading-icons';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import NotFound from './NotFound';
import useDarkMode from 'use-dark-mode';
import LanguageFilter from '../Components/Latests/LanguageFilter';

function LatestsPage2() {
    const darkmode = useDarkMode();
    const [searchQuery, setSearchQuery] = useSearchParams();
    const navigate = useNavigate();
    const [latests, setLatests] = useState([]);
    const [langFilter, setLangFilter] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [popularSort, setPopularSort] = useState(false);
    const [typeFilter, setTypeFilter] = useState('all');
    const pageCount = 20;
    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            setLangFilter(JSON.parse(window.localStorage.getItem('langs')) || []);
            setPopularSort(window.localStorage.getItem('sort_popular') === 'true');
            setTypeFilter(window.localStorage.getItem('type_filter') || 'all');
        } catch (error) {
            console.log({ error })
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/latestsSubs", { params: { typeFilter: window.localStorage.getItem('type_filter') || "all", page: searchQuery.get("page") || 1, langs: window.localStorage.getItem('langs'), sort_pop: window.localStorage.getItem('sort_popular') === 'true' }, withCredentials: true }).then(res => {
            if (res.data.success) {
                document.title = `Latest subtitles in SubSource.`
                var subs = window.localStorage.getItem('sort_popular') === 'true' ? res.data.latests.sort((a, b) => b.downloads - a.downloads) : res.data.latests.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                setLatests(subs)
                setLoading(false)
                setDataLoading(false);
            }
        }).catch(err => {
            if (err.response.status === 404) {
                setNotFound(true)
            }
            console.log(err)
        });
    }, [searchQuery])
    console.log(popularSort)
    const handleSaveFilters = () => {
        setLoading(true);
        setSearchQuery({
            'page': 1
        });
        try {
            window.localStorage.setItem('langs', JSON.stringify(langFilter));
        } catch (error) {
            console.log({ error })
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/latestsSubs", { params: { typeFilter: window.localStorage.getItem('type_filter') || "all", langs: window.localStorage.getItem('langs'), page: searchQuery.get("page") || 1, sort_pop: window.localStorage.getItem('sort_popular') === 'true' }, withCredentials: true }).then(res => {
            if (res.data.success) {
                document.title = `Latest subtitles in SubSource.`
                var subs = window.localStorage.getItem('sort_popular') === 'true' ? res.data.latests.sort((a, b) => b.downloads - a.downloads) : res.data.latests.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                setLatests(subs)
                setLoading(false);
            }
        }).catch(err => {
            if (err.response.status === 404) {
                setNotFound(true)
            }
            setLoading(false);
            console.log(err)
        });
    }
    const handlePageClick = (event) => {
        if (!dataLoading) {
            setDataLoading(true)
            setSearchQuery({
                'page': parseInt(event.selected || 0) + 1,
                'sort_pop': window.localStorage.getItem('sort_popular') === 'true',
                'type': window.localStorage.getItem('type_filter') || 'all'
            });
        }
    };
    const handleSortChange = () => {
        setDataLoading(true);
        const sortByDownload = window.localStorage.getItem('sort_popular') === 'true';
        setSearchQuery({
            'page': searchQuery.get("page") || 1,
            'sort_pop': sortByDownload,
            'type': window.localStorage.getItem('type_filter') || 'all'
        });
    }
    const handleTypeFilterChange = () => {
        setDataLoading(true);
        const sortByDownload = window.localStorage.getItem('sort_popular') === 'true';
        setSearchQuery({
            'page': searchQuery.get("page") || 1,
            'sort_pop': sortByDownload,
            'type': window.localStorage.getItem('type_filter') || 'all'
        });
    }
    if (notFound) {
        return (
            <NotFound />
        )
    }
    if (loading)
        return (<>
            <Box sx={{ height: "800px", display: "flex", justifyContent: "center", alignItems: "center", color: "black" }}>
                <Grid fill={darkmode.value ? "white" : 'black'} />
            </Box>
        </>)
    return (
        <Box sx={{ display: 'flex', margin: "80px 120px 80px 120px", flexDirection: "column", fontFamily: "Regular-Family" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Typography fontSize={"35px"} color={darkmode.value ? "white" : "#21495F"} fontWeight={"700"} sx={{ mr: "1rem", fontFamily: "Regular-Family" }}>Browse Subtitles</Typography>
                <Paper>
                    <ToggleButton disabled={dataLoading} value={!popularSort} selected={!popularSort} onClick={(e) => { if (popularSort) { setPopularSort(false); window.localStorage.setItem('sort_popular', 'false'); handleSortChange() } }} disableRipple sx={{ fontSize: "12px", fontWeight: "bold", textWrap: "nowrap", '&.Mui-selected': { backgroundColor: "#0080808f", boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)", "&:hover": { backgroundColor: "#0080808f" } }, textTransform: "none", "&:hover": { backgroundColor: "initial" }, py: "0.5rem", px: "0.75rem" }}>
                        Latest
                    </ToggleButton>
                </Paper>
                <Paper>
                    <ToggleButton disabled={dataLoading} value={popularSort} selected={popularSort} onClick={(e) => { if (!popularSort) { setPopularSort(true); window.localStorage.setItem('sort_popular', 'true'); handleSortChange() } }} disableRipple sx={{ fontSize: "12px", fontWeight: "bold", textWrap: "nowrap", '&.Mui-selected': { backgroundColor: "#0080808f", boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)", "&:hover": { backgroundColor: "#0080808f" } }, textTransform: "none", "&:hover": { backgroundColor: "initial" }, py: "0.5rem", px: "0.75rem" }}>
                        Popular
                    </ToggleButton>
                </Paper>
            </Box>
            <Divider sx={{ mb: "1.5rem", mt: "0.5rem", borderColor: "black" }} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: "0.5rem" }}>
                    <LanguageFilter langFilter={langFilter} setLangFilter={setLangFilter} reload={handleSaveFilters} setLoading={setLoading} /> {/* Use LanguageFilter here */}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                    <ToggleButton disabled={dataLoading} selected={typeFilter === 'all'} onClick={(e) => { if (typeFilter !== "all") { setTypeFilter('all'); window.localStorage.setItem('type_filter', 'all'); handleTypeFilterChange() } }} disableRipple sx={{ fontSize: "12px", fontWeight: "bold", textWrap: "nowrap", '&.Mui-selected': { backgroundColor: "#0080808f", boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)", "&:hover": { backgroundColor: "#0080808f" } }, textTransform: "none", "&:hover": { backgroundColor: "initial" }, py: "0.5rem", px: "0.75rem" }}>
                        All
                    </ToggleButton>
                    <ToggleButton disabled={dataLoading} selected={typeFilter === 'movie'} onClick={(e) => { if (typeFilter !== "movie") { setTypeFilter('movie'); window.localStorage.setItem('type_filter', 'movie'); handleTypeFilterChange() } }} disableRipple sx={{ fontSize: "12px", fontWeight: "bold", textWrap: "nowrap", '&.Mui-selected': { backgroundColor: "#0080808f", boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)", "&:hover": { backgroundColor: "#0080808f" } }, textTransform: "none", "&:hover": { backgroundColor: "initial" }, py: "0.5rem", px: "0.75rem" }}>
                        Movies
                    </ToggleButton>
                    <ToggleButton disabled={dataLoading} selected={typeFilter === 'tv'} onClick={(e) => { if (typeFilter !== "tv") { setTypeFilter('tv'); window.localStorage.setItem('type_filter', 'tv'); handleTypeFilterChange() } }} disableRipple sx={{ fontSize: "12px", fontWeight: "bold", textWrap: "nowrap", '&.Mui-selected': { backgroundColor: "#0080808f", boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)", "&:hover": { backgroundColor: "#0080808f" } }, textTransform: "none", "&:hover": { backgroundColor: "initial" }, py: "0.5rem", px: "0.75rem" }}>
                        TV-Series
                    </ToggleButton>
                </Box>
            </Box>
            <Box sx={{ position: "relative", mt: "0.5rem" }}>
                {dataLoading ? <Box sx={{ position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#00000036", width: "100%", height: "100%" }}>
                    <Grid fill={darkmode.value ? "white" : 'black'} fontSize={'0.5rem'} />
                </Box> : <></>}
                <TableContainer component={Paper} sx={{ borderRadius: "2px" }}>
                    <Table sx={{ borderCollapse: "separate" }} size="small" aria-label="Subtitles" className={`allSubsTable-latest ${darkmode.value && "dark-mode"}`} >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: "1px", color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>Language</TableCell>
                                <TableCell align="left" sx={{ color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>Title</TableCell>
                                <TableCell align="center" sx={{ width: "1px", color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>Type</TableCell>
                                <TableCell align="center" sx={{ width: "1px", color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>H.I.</TableCell>
                                <TableCell align="center" sx={{ width: "1px", color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>Comments</TableCell>
                                <TableCell align="center" sx={{ width: "1px", color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>Owner</TableCell>
                                <TableCell align="center" sx={{ width: "1px", color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>Date</TableCell>
                                <TableCell align="center" sx={{ width: "1px", color: darkmode.value ? "black !important" : "initial", borderBlock: "none" }}>Downloads</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {latests.map((sub, index) => (
                                <Link href={sub.full_link} key={index} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(sub.full_link) } }} sx={{ display: "contents", "&:visited>*>td": { color: darkmode.value ? "#a561e3" : "#551A8B" } }}>
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell sx={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                                            {sub.lang}
                                        </TableCell>
                                        <TableCell align="left" sx={{ maxWidth: "1px" }}><Tooltip title={sub.title} placement='top'><Typography sx={{ width: "auto", maxWidth: "min-content", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontWeight: "bold", fontSize: "12px" }}>{sub.title}</Typography></Tooltip></TableCell>
                                        <TableCell align="center" sx={{ whiteSpace: "nowrap", fontSize: "12px", color: darkmode.value ? "white !important" : "black !important" }}>{sub.type || "N/A"}</TableCell>
                                        <TableCell align="center" sx={{ width: "1%", color: darkmode.value ? "white !important" : "black !important" }}>{sub.hi ? <><HearingDisabledIcon sx={{ color: "darkgray", fontSize: "16px" }} /></> : <></>}</TableCell>
                                        <TableCell align="center" sx={{ width: "1%", color: darkmode.value ? "white !important" : "black !important" }}>{sub.cms}</TableCell>
                                        <TableCell align="center" sx={{ width: "1%", fontSize: "12px" }}><Link href={"/u/" + sub.ownerId} onClick={(e) => { e.stopPropagation(); if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + sub.ownerId) } }} sx={{ textDecoration: "none", color: "black", "&:visited": { color: "#551A8B" } }}><Tooltip title={sub.owner} placement='left'><Typography fontSize={"0.8rem"} sx={{ maxWidth: "110px", textOverflow: "ellipsis", overflow: 'hidden', textWrap: "nowrap" }}>{sub.owner}</Typography></Tooltip></Link></TableCell>
                                        <TableCell align="center" sx={{ color: darkmode.value ? "white !important" : "black !important", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px", fontSize: "12px", whiteSpace: "nowrap" }}>{moment().diff(sub.date, 'days') > 30 ? moment(sub.date).format("DD/MM/YYYY") : <ReactTimeAgo date={new Date(sub.date)} />}</TableCell>
                                        <TableCell align="center" sx={{ width: "1%", color: darkmode.value ? "white !important" : "black !important" }}>{sub.downloads}</TableCell>
                                    </TableRow>
                                </Link>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>
            {
                <ReactPaginate
                    containerClassName={`allsubs-pagination ${darkmode.value && "dark-mode"}`}
                    breakLabel="..."
                    breakLinkClassName='breaklink'
                    nextLabel="»"
                    nextClassName='nextpage'
                    previousClassName='prevpage'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    forcePage={(searchQuery.get("page") || 1) - 1}
                    previousLabel="«"
                    renderOnZeroPageCount={null}
                    pageClassName='page'
                    pageLinkClassName='pagelink'
                    activeClassName='active'
                />
            }
        </Box>
    )
}

export default LatestsPage2
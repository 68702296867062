import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'

const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
function SeriesTitle({ seasons, bigPoster }) {
    return (
        <Box sx={{ display: "flex", minWidth: "365px", minHeight: "750px", justifyContent: "center", my: "2rem", backgroundImage: `url(${bigPoster || seasons[0].img}),url(https://api.subsource.net/notfound2.jpg)`, backgroundSize: "cover", backgroundPositionX: "center", alignSelf: "flex-start" }} >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", backgroundColor: "transparent", width: "100%", m: "1.6rem", py: "0.25rem" }}>
                <Box sx={{ backgroundColor: "#f0ffffb5", minWidth: "12rem", maxWidth: "18rem", display: "flex", flexDirection: "column", width: "fit-content", p: "0.75rem", color: "black", borderRadius: "10px", alignSelf: "center" }}>
                    <Typography variant={seasons[0].title.length <= 15 ? "h5" : "h6"} sx={{ width: "100%", textAlign: "left", marginBottom: "0.25rem" }} fontFamily={'Regular-Family'} fontWeight={'bold'}>{seasons[0].title}</Typography>
                    <Typography variant='h7' sx={{ width: "100%", textAlign: "left" }} fontFamily={'Regular-Family'} fontWeight={'bold'}>{seasons.filter(x => x.season !== 0).length === 0 ? "N/A" : seasons.filter(x => x.season !== 0).length + " Seasons"}</Typography>
                </Box>
                <Box sx={{
                    backgroundColor: "#f0ffffb5",
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    transition: "0.15s all ease-in-out",
                    borderRadius: "10px",
                    px: "0.5rem",
                    color: "black",
                    alignSelf: "center"
                }}>
                    <a href={seasons[0]?.imdbLink?.startsWith('tt') ? 'https://www.imdb.com/title/' + seasons[0]?.imdbLink : seasons[0]?.imdbLink} target='_blank' rel="noreferrer"><Avatar children={<img src="https://upload.wikimedia.org/wikipedia/commons/4/46/Imdb_logo_rounded_corners.png" width={'40px'} alt='imdb' />} sx={{ backgroundColor: "gold", m: "0.25rem" }} /></a>
                    <Box sx={{ display: "flex", flexDirection: "column", alignContent: "center", justifyItems: "center", width: "40%", marginRight: "0.5rem" }}>
                        <Typography fontFamily={'Arial'} fontSize={'1.1rem'} textAlign={'center'} fontWeight={"bold"}>{seasons[0].rating}</Typography>
                        <Typography fontFamily={'Arial'} fontSize={'0.75rem'} fontWeight={"bold"} textAlign={'center'} sx={{ opacity: "75%" }}>({formatCash(seasons[0].vc)})</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SeriesTitle
import { Box, Button, Dialog, DialogTitle, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useRef, useState } from 'react'
import useSnackbar from '../hooks/useSnackbar';

function UserMessage({ show, toggleShow, username, userId }) {
    const [sendingMessage, setSendingMessage] = useState(false);
    const { showSnackbar } = useSnackbar();
    const msgRef = useRef();
    const handleSend = () => {
        if (msgRef.current.value !== "") {
            setSendingMessage(true);
            axios.post(process.env.REACT_APP_API_URL + "/api/createChat", { userid: userId, msg: msgRef.current.value }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    window.location.href = "/dashboard/messages"
                } else {
                    showSnackbar(res.data.errMsg || "Error.", 'error')
                    setSendingMessage(false);
                }
            }).catch(err => {
                setSendingMessage(false);
            });
        }
    }
    return (
        <Dialog open={show} onClose={toggleShow} sx={{ fontFamily: "Regular-Family", marginBottom: "1rem" }}>
            <DialogTitle sx={{ fontFamily: "Regular-Family" }}>
                Sending message to {username}
            </DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: "column", mx: "1.25rem", my: "0.5rem" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.15rem" }}></Typography>
                <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    disabled={sendingMessage}
                    maxRows={5}
                    inputRef={msgRef}
                    sx={{ minWidth: "400px" }}
                    placeholder='Type your message here...'
                />
                <Box sx={{ mt: "1rem", display: 'flex', justifyContent: "flex-end", gap: "0.5rem", mb: "0.5rem" }}>
                    <Button variant='contained' color='primary' disabled={sendingMessage} onClick={handleSend}>Send</Button>
                    <Button variant='outlined' color='error' disabled={sendingMessage} onClick={toggleShow}>Close</Button>
                </Box>

            </Box>
        </Dialog >
    )
}

export default UserMessage
import { Avatar, Badge, Box, Button, Divider, Skeleton, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import "./DashboardTickets.css"
import { useUserContext } from '../../../Contexts/userContext';
import NewTicketModal from './NewTicketModal';
import ReactTimeAgo from 'react-time-ago'
import axios from 'axios';
import useDarkMode from 'use-dark-mode';
import { useQueryClient } from '@tanstack/react-query';
import useSnackbar from '../../../hooks/useSnackbar';

function DashboardTickets() {
    const { showSnackbar } = useSnackbar();
    const darkmode = useDarkMode();
    const user = useUserContext();
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(undefined);
    const replyText = useRef();
    const [submitting, setSubmitting] = useState(false);
    const [modalopen, setModalOpen] = useState(false);
    const queryClient = useQueryClient()
    const modalOnClose = () => {
        setModalOpen(false);
    }
    const openModal = () => {
        setModalOpen(true);
    }
    const handleTicketClick = (tn) => {
        if (!submitting) {
            let t = tickets.find((x) => x.ticketNumber === tn)
            setSelected(t)
            setTicketsNewMessagesAsSeen(t)
        }
    }
    const setTicketsNewMessagesAsSeen = (t) => {
        try {
            if (t.ticketMessages.find((i) => i.from?.toLowerCase() === 'support' && i?.isNew) || true)
                axios
                    .post(
                        process.env.REACT_APP_API_URL + '/api/user/mySubsource/tickets/seen',
                        { id: t.ticketNumber },
                        { withCredentials: true }
                    )
                    .then(() => {
                        let n = tickets.map((ticket) => {
                            let res = { ...ticket }
                            if (ticket.ticketNumber === t.ticketNumber) {
                                res.ticketMessages = res.ticketMessages.map((message) => {
                                    if (message.from.toLowerCase() === 'support') return { ...message, isNew: false }
                                    return message
                                })
                            }

                            return res
                        })
                        setTickets(n)
                        queryClient.invalidateQueries({
                            queryKey: ['getNotifs']
                        })
                    })
        } catch (error) { }
    }
    const handleSubmitReply = (tn) => {
        if (replyText.current.value !== "") {
            const text = replyText.current.value;
            setSubmitting(true);
            axios.post(process.env.REACT_APP_API_URL + "/api/user/mySubsource/tickets/update", { id: tn, text: text }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    var cm = selected.ticketMessages;
                    cm.push({
                        from: "target",
                        fromUsername: user.username,
                        avatar: user.avatar,
                        date: new Date(),
                        text: text,
                    });
                    setSelected(p => ({ ...p, ticketMessages: cm }));
                    var _new = tickets.map(x => {
                        if (x.ticketNumber == tn) {
                            x.ticketStatus = 0
                        }
                        return x;
                    })
                    setTickets(_new);
                    replyText.current.value = "";
                    setSubmitting(false);
                } else {
                    setSubmitting(false);
                    showSnackbar("Error.")
                }
            }).catch(err => {
                setSubmitting(false);
                showSnackbar("Error.")
            })


        }
    }
    const handleCloseTicket = (tn) => {
        setSubmitting(true);
        axios.post(process.env.REACT_APP_API_URL + "/api/user/mySubsource/tickets/close", { id: tn }, { withCredentials: true }).then(res => {
            if (res.data.success) {
                var _new = tickets.map(x => {
                    if (x.ticketNumber == tn) {
                        x.ticketStatus = 1
                    }
                    return x;
                })
                setTickets(_new);
                setSelected(undefined)
                setSubmitting(false);
            } else {
                showSnackbar("Error.");
                setSubmitting(false);
            }
        }).catch(err => {
            showSnackbar("Error.");
            setSubmitting(false);
        })
    }
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/user/mySubsource/tickets/get", { withCredentials: true }).then(res => {
            if (res.data.success) {
                setTickets(res?.data?.tickets);
            }
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        })
    }, [])
    return (
        loading ?
            <>
                <Box sx={{ display: "flex", flexDirection: "row", backgroundColor: darkmode.value ? "#2a2a2a" : "white", minHeight: "700px", gap: "1rem" }}>
                    <Box sx={{ width: "450px", mt: "2rem", ml: "2rem" }}>
                        <Skeleton animation="wave" variant='text' sx={{ fontSize: "56px", fontFamily: "Regular-Family" }} />
                        <Skeleton animation="wave" variant='rectangular' sx={{ width: "100%", height: "39px" }} />
                        <Box sx={{ display: 'flex', my: "30px", gap: "0.25rem", flexDirection: "column" }}>
                            {[1, 2, 3, 4, 5].map((ticket, index) => (
                                <>
                                    <Skeleton variant='rounded' animation="wave" sx={{ height: "76px" }} />
                                </>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: !selected ? "center" : "initial", gap: "1rem" }}>
                        <Skeleton variant='rounded' sx={{ height: "10%", width: "auto" }}></Skeleton>
                        <Skeleton variant='rounded' sx={{ height: "30%", width: "100%", }}></Skeleton>
                        <Skeleton variant='rounded' sx={{ height: "10%", width: "100%", }}></Skeleton>
                        <Skeleton variant='rounded' sx={{ height: "30%", width: "100%", }}></Skeleton>
                    </Box>
                </Box> </> : <>
                <NewTicketModal open={modalopen} onClose={modalOnClose} />
                <Box sx={{ display: "flex", flexDirection: "row", backgroundColor: darkmode.value ? "#2a2a2a" : "white", minHeight: "700px" }}>
                    <Box sx={{ width: "450px", mt: "2rem", ml: "2rem" }}>
                        <Typography sx={{ fontSize: "28px", fontFamily: "Regular-Family", color: darkmode.value ? "white" : "#21495f", fontWeight: "500" }}>My Tickets</Typography>
                        <Button onClick={openModal} fullWidth variant='contained' color='info' sx={{ mt: "1rem", textTransform: "none", fontSize: "18px" }} size='small' disableRipple>Create a new ticket</Button>
                        <Box sx={{ display: 'flex', my: "30px", gap: "0.25rem", flexDirection: "column" }}>
                            {tickets.length === 0 ?
                                <>
                                    <Typography sx={{ color: darkmode.value ? "white" : "initial" }}>No Tickets</Typography>
                                </>
                                :
                                tickets.sort((a, b) => (b.ticketNumber - a.ticketNumber)).map((ticket, index) => (
                                    <>
                                        <Box sx={{ position: "relative" }} className={`tickets-box ${selected?.ticketNumber === ticket.ticketNumber ? "active" : ""} ${darkmode.value && "dark-mode"}`} onClick={() => { handleTicketClick(ticket.ticketNumber) }}>
                                            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                                {ticket.ticketStatus === 0 ? <HourglassFullIcon sx={{ color: "#ffd233" }} /> : <CheckCircleRoundedIcon sx={{ color: "darkgreen" }} />}
                                                <Box sx={{ display: 'flex', flexDirection: "column" }}>
                                                    <Typography sx={{ fontSize: "15px", fontFamily: "Regular-Family", fontWeight: "Bold", color: darkmode.value ? "white" : "initial" }}>{ticket.ticketSubject}</Typography>
                                                    <Typography sx={{ fontSize: "15px", fontFamily: "Regular-Family", color: darkmode.value ? "rgba(255,255,255,0.65)" : "rgba(0, 0, 0, 0.65)", fontWeight: "bold" }}>#{ticket.ticketNumber}</Typography>
                                                </Box>
                                            </Box>
                                            <Badge
                                                badgeContent={
                                                    ticket?.ticketMessages?.filter(
                                                        (i) => i.from?.toLowerCase() === 'support' && i?.isNew
                                                    )?.length
                                                }
                                                color="primary"
                                            ></Badge>
                                        </Box>
                                    </>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: !selected ? "center" : "initial" }}>
                        {!selected ?
                            <>
                                <Typography fontSize={"30px"} s sx={{ alignSelf: "center", fontWeight: "Bold", color: darkmode.value ? 'white' : "initial" }}>Select a ticket.</Typography>
                            </>
                            :
                            <>
                                <Box sx={{ display: "flex", flexDirection: "column", padding: "35px", width: "100%" }}>
                                    <Box sx={{ mb: "2rem", display: 'flex', justifyContent: "space-between" }}>
                                        <Typography fontSize={"25px"} fontWeight={"bold"} sx={{ color: darkmode.value ? 'white' : "initial" }}>{selected.ticketSubject} #{selected.ticketNumber}</Typography>
                                        <Button disabled={selected.ticketStatus !== 0 || submitting} variant='outlined' color='error' disableRipple onClick={() => { handleCloseTicket(selected.ticketNumber) }}>Close Ticket</Button>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                        {selected.ticketMessages.map(msg => (
                                            <>
                                                <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem", backgroundColor: "rgb(0 128 128 / 15%)", padding: "0.35rem" }}>
                                                    <Avatar src={msg.avatar} sx={{ width: "35px", height: "35px" }}>{msg.fromUsername.slice(0, 1)}</Avatar>
                                                    <Box sx={{ display: 'flex', flexDirection: "column" }}>
                                                        <Typography sx={{ fontWeight: "bold", fontSize: "13px", color: darkmode.value ? 'white' : "initial" }}>{msg.fromUsername}</Typography>
                                                        <Typography sx={{ fontWeight: "bold", fontSize: "11px", color: darkmode.value ? 'rgb(255,255,255,0.65)' : "rgb(0,0,0,0.65)" }}><ReactTimeAgo date={new Date(msg.date)} /></Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ mx: "3rem", py: "1rem" }}>
                                                    <Typography sx={{ whiteSpace: "pre-line", fontFamily: "Regular-Family", color: darkmode.value ? 'white' : "initial" }} dir="auto" >{msg.text}</Typography>
                                                </Box>
                                            </>
                                        ))}
                                    </Box>
                                    <Divider sx={{ mb: "1rem", mt: "1rem" }} />
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                        <Typography sx={{ fontFamily: "Regular-Family", fontSize: "25px", color: darkmode.value ? 'white' : "initial" }}>Reply</Typography>
                                        <TextField
                                            placeholder='Type your message here.'
                                            multiline
                                            minRows={3}
                                            inputProps={{ dir: "auto" }}
                                            InputProps={{ sx: { fontFamily: "Regular-Family" } }}
                                            maxRows={5}
                                            inputRef={replyText}
                                        >
                                        </TextField>
                                        <Button variant='contained' color='success' disableRipple sx={{ textTransform: "none", }} disabled={submitting} onClick={() => { handleSubmitReply(selected.ticketNumber) }}>Submit</Button>
                                    </Box>
                                </Box>
                            </>
                        }

                    </Box>
                </Box>
            </>

    )
}

export default DashboardTickets
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import moment from 'moment'
const RegularFont = 'Regular-Family';
const BoldFont = 'Regular-Family';
function Bubble({ text, time, me }) {
    return (
        me ? <Box sx={{
            maxWidth: "17rem",
            backgroundColor: "cornflowerblue",
            my: "1rem",
            '--r': "25px",
            '--t': "15px",
            WebkitMask: 'radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)calc(var(--r) / -2) padding-box,radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space padding-box',
            '--_d': '100%',
            borderRight: `var(--t) solid #0000`,
            marginLeft: `var(--t)`,
            overflowWrap: "anywhere",
            placeSelf: "end",
            padding: `calc(2 * var(--r) / 3)`
        }}>
            <Typography fontFamily={"Regular-Family"} variant="h5" color="initial" sx={{ width: "100%", whiteSpace: "break-spaces" }} dir="auto">{text}</Typography>
            <Typography fontFamily={"Regular-Family"} fontSize={'0.75rem'} color="initial" sx={{ width: "100%" }}>{moment(time).format('lll')}</Typography>
        </ Box> :
            <Box sx={{
                maxWidth: "17rem",
                backgroundColor: "gainsboro",
                my: "1rem",
                '--r': "25px",
                '--t': "15px",
                WebkitMask: 'radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)calc(var(--r) / -2) padding-box,radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space padding-box',
                '--_d': '0%',
                borderLeft: ` var(--t) solid #0000`,
                marginRight: `var(--t)`,
                overflowWrap: "anywhere",
                padding: `calc(2 * var(--r) / 3)`,
                placeSelf: "start",
            }}>
                <Typography fontFamily={"Regular-Family"} variant="h5" color="initial" sx={{ width: "100%", whiteSpace: "break-spaces" }}>{text}</Typography>
                <Typography fontFamily={"Regular-Family"} fontSize={'0.75rem'} color="initial" sx={{ width: "100%" }}>{moment(time).format('lll')}</Typography>
            </ Box>
    )
}

export default Bubble
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function BadUrl() {
    const location = useLocation();
    const navigate = useNavigate();
    const url = location.pathname;

    useEffect(() => {
        const regex = /\/\/+/g;
        if (url.match(regex)) {
            const cleanUrl = url.replace(regex, "/");
            navigate(cleanUrl)
        } else {
            navigate('/')
        }
    }, [navigate, url])


    return (
        <div></div>
    )
}

export default BadUrl
import React, { useState } from 'react'
import { Box, ToggleButton, Typography } from '@mui/material'
import useDarkMode from 'use-dark-mode';
function PrivacyPolicyPage() {
    const darkmode = useDarkMode(false);
    const [language, setLanguage] = useState('en');

    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <Box sx={{ margin: "0 auto", mt: "80px", width: "1100px", color: darkmode.value ? "white" : "initial" }}>
            <Typography sx={{ fontSize: "45px", fontWeight: "bold", color: darkmode.value ? "white" : "#205e60", fontFamily: "Regular-Family", textAlign: "center", display: language === "en" ? "block" : "none" }}>Privacy Policy of SubSource</Typography>
            <Typography sx={{ fontSize: "30px", fontWeight: "bold", color: darkmode.value ? "white" : "initial", fontFamily: "Regular-Family", textAlign: "center", display: language === "fa" ? "block" : "none" }}>سیاست حفظ حریم خصوصی ساب‌سورس</Typography>
            <Box sx={{ display: "flex", gap: "1rem", justifyContent: 'center', mt: "1rem" }}>
                <ToggleButton disableRipple onClick={handleChange} selected={language === "en"} size='small' value="en" key="left" sx={{ textTransform: "none", fontFamily: "Regular-Family" }}>
                    English
                </ToggleButton>
                <ToggleButton disableRipple onClick={handleChange} selected={language === "fa"} size='small' value="fa" key="center" sx={{ textTransform: "none", fontFamily: "Regular-Family" }}>
                    فارسی
                </ToggleButton>
            </Box>
            <Box sx={{ mt: "80px", px: "2rem", fontFamily: "Regular-Family", lineHeight: "2rem", fontSize: "18px", display: language === 'en' ? "block" : "none" }}>
                <strong>How does SubSource keep your information safe?</strong><br /><br />
                At SubSource, we understand the importance of your privacy. In today's data-driven world, we want to assure you that we prioritize the security and confidentiality of your information. This policy outlines how we collect, use, and protect your data.

                <br /><br /><br /><strong>What information does SubSource collect from you?</strong><br /><br />
                SubSource collects two types of information from users:
                Public Profile Information: This includes details you choose to make publicly visible on your profile, such as your username and social media links.
                Account Information: This data is essential for account creation and using our services. It includes your email address and IP address.

                <br /><br /><br /><strong>How is your information protected?</strong><br /><br />
                Your data is protected by industry-standard security measures. This includes secure storage with encryption at rest on our servers protected by robust firewalls. We utilize HTTPS encryption to ensure your information is transmitted securely during signup, and we store your data only for as long as necessary to provide you with our services.

                <br /><br /><br /><strong>How does SubSource Uses your information?</strong><br /><br />
                We are legally and ethically obligated to retain your data, but it is always stored in encrypted format. We respect your privacy and will never send you unsolicited commercial emails.

                <br /><br /><br /><strong>Who does access your information?</strong><br /><br />
                Only our high-level admins access some of your information. This access is strictly limited to providing you with the best possible support experience. We are committed to protecting your privacy and have robust safeguards in place to prevent unauthorized access to your information.

                <br /><br /><br /><strong>How much is SubSource allowed to change your account?</strong><br /><br />
                To maintain a positive and secure environment for all users, SubSource reserves the right to remove content that violates our terms of service. Users who violate these terms may have their access to the site restricted or terminated.
            </Box>
            <Box sx={{ mt: "80px", px: "2rem", fontFamily: "Regular-Family", lineHeight: "2rem", fontSize: "18px", display: language === 'fa' ? "block" : "none", direction: "rtl" }}>
                <strong>ساب‌سورس چگونه از اطلاعات شما حراست می‌کند؟</strong><br /><br />
                امروزه با گسترش جریان اطلاعات و استفاده روزمره کاربران از اینترنت و وسایل ارتباطی، حریم خصوصی کاربران به یکی از جدی‌ترین دغدغه‌ها تبدیل شده ‌است. نگرانی بسیاری از کاربران، محفوظ ماندن اطلاعات شخصی و ایمیل است که برای ثبت نام و لاگین شدن به ما می‌سپارند. باید گفت ما نیز اهمیت این موضوع را درک می‌کنیم و حریم خصوصی کاربران برای ما اهمیت بسیاری دارد. در ذیل سیاست حفظ حریم خصوصی و محرمانگی اطلاعات کاربران را ملاحظه خواهید کرد.


                <br /><br /><br /><strong>چه اطلاعاتی از شما دریافت می‌شود؟</strong><br /><br />
                ساب‌سورس دو نوع اطلاعات از کاربران خود دریافت می‌کند:<br></br> 1- اطلاعاتی که خود کاربر به طور عمومی در حساب کاربری خود قرار می‌دهد (از قبیل نام کاربری و حساب‌های رسانه‌های احتماعی)<br></br> 2- اطلاعاتی که برای ورود و استفاده از سرویس‌های سایت لازم است. این اطلاعات شامل آی‌پی و آدرس ایمیل کاربر می‌شود.

                <br /><br /><br /><strong>چگونه از اطلاعات شما محافظت می‌شود؟</strong><br /><br />
                با وجود این‌که امنیت به صورت نسبی تعریف شده است اما در طراحی وب‌سایت ساب‌سورس سعی شده که از بالاترین سطوح امنیتی سخت‌افزاری و نرم‌افزاری در نظر گرفته شود. در زمان ثبت نام، تمامی اطلاعات و داده‌ها بر روی سرورهای ایمن ما ذخیره و توسط فایروال‌های مستحکم محافظت خواهند شد. اطلاعات شخصی شما نیز به صورت رمزنگاری‌شده در بستر امن HTTPS به وب‌سایت ما منتقل و برای تعاملات بعدی نگهداری خواهند شد.
                <br /><br /><br /><strong>چه استفاده‌ای اطلاعات شما خواهد شد؟</strong><br /><br />
                چه از نظر قانونی، چه از نظر اخلاقی، ما موظف به صیانت از اطلاعات شما هستیم. اطلاعات شخصی شما نیز صرفاً جهت تعاملات بعدی به صورت کدگذاری‌شده نگهداری می‌شوند. ما هم از اسپم متنفریم و ایمیل‌ها و پیام‌های تبلیغاتی را برای کاربران‌مان ارسال نخواهیم کرد.
                <br /><br /><br /><strong>چه کسانی به اطلاعات شما دسترسی دارند؟</strong><br /><br />
                فقط و فقط ادمین‌های رده بالا به برخی از اطلاعات شما دسترسی دارند، آن هم برای ارائه پشتیبانی بهتر و با کیفیت‌تر مورد استفاده قرار می‌گیرد. تمامی اطلاعات شخصی شما نزد ما محفوظ خواهد ماند و در اختیار افراد یا سازمان‌های دیگری قرار نخواهد گرفت. همچین ساب‌سورس به رمز عبور حساب شما دسترسی ندارد و تمامی پسوردها به صورت هش و رمزگذاری شده ذخیره می‌شوند.
                <br /><br /><br /><strong>ساب‌سورس تا چه حد مجاز است در حساب شما تغییرات اعمال کند؟</strong><br /><br />
                ساب‌سورس این حق را برای خود محفوظ می‌دارد که محتوای حساب‌ها را در جهت هماهنگی با قوانین سایت تغییر دهد و یا حذف کند. لذا در صورت تخطی از قوانین سایت، ساب‌سورس این حق را دارد که با صلاحدید خود و بدون هیچگونه اطلاع‌رسانی حساب کاربر خاطی را مسدود سازد.            </Box>
        </Box>
    )
}

export default PrivacyPolicyPage
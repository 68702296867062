import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import headerImage from './imgs/Headerrrrr.webp';
import footerImage from './imgs/Footerrrrr.webp';
import headerImageDark from './imgs/Headerrrrr_dark.png';
import footerImageDark from './imgs/Footerrrrr_dark.png';
import { Suspense, lazy, useState } from 'react';
import { HeaderContext } from './Contexts/userContext';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import { CurrentMovieContext } from './Contexts/currentMovieContext';
import { UserApi } from './api/userApi';
import { useQuery } from '@tanstack/react-query';
import useDarkMode from 'use-dark-mode';
import MainLayout from './Pages/MainLayout';
import Dashboard from './Components/Dashboard/Dashboard';
import MoviePage from './Pages/MoviePage2';
import SubtitlePage from './Pages/SubtitlePage';
import RatingsPage from './Pages/RatingsPage';
import UploadPage from './Pages/UploadPage';
import UploadPage2 from './Pages/UploadPage2';
import UserProfilePage from './Pages/UserProfilePage';
import AddMoviePage from './Pages/AddMoviePage';
import BadUrl from './Pages/BadUrl';
import EditSubtitlePage from './Pages/EditSubtitlePage';
import AllSubsPage from './Pages/AllSubsPage';
//import ResetPassword from './Pages/ResetPassword';
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import TermsPage from './Pages/TermsPage';
import DmcaPage from './Pages/DmcaPage';
import UserRatings from './Pages/UserRatings';
import HomePage from './Pages/HomePage';
import UserRatingsGiven from './Pages/UserRatingsGiven';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import { Grid } from 'react-loading-icons';
import LatestsPage from './Pages/LatestsPage2';
import EditSubtitleAdminPage from './Pages/EditSubtitleAdminPage';
import FullSearch from './Pages/FullSearch';
import { SnackbarProvider } from 'notistack';
const AdminPage = lazy(() => import('./Pages/AdminPage'));
const ResetPassword = lazy(() => import('./Pages/ResetPassword'));
//const LatestsPage = lazy(() => import('./Pages/LatestsPage2'));
/* 

const EditSubtitlePage = lazy(() => import('./Pages/EditSubtitlePage'));
const AllSubsPage = lazy(() => import('./Pages/AllSubsPage'));

const AboutPage = lazy(() => import('./Pages/AboutPage'));
const ContactPage = lazy(() => import('./Pages/ContactPage'));
const TermsPage = lazy(() => import('./Pages/TermsPage'));
const DmcaPage = lazy(() => import('./Pages/DmcaPage'));
const UserRatings = lazy(() => import('./Pages/UserRatings'));
const Maintenance = lazy(() => import('./Pages/Maintenance'));
const HomePage = lazy(() => import('./Pages/HomePage')); */
function App() {
  const darkmode = useDarkMode(false, { element: document.getElementById("root") });
  const [pageLoading, setPageLoading] = useState(true);
  const [currentMovieCtx, setCurrentMovieCtx] = useState(null);
  if (localStorage.getItem('darkMode') == null) { darkmode.disable(); }
  const { data: user, } = useQuery({
    queryKey: ["getUser"],
    gcTime: 0,
    queryFn: async () => {
      try {
        const _user = await UserApi.get();
        setPageLoading(false);
        return _user;
      } catch (error) {
        /* if (error) {
          setPageLoading(false);
          if (error.code === "ERR_NETWORK") {
            setMaintenance(true);
          }
        } */
      }
    },
  })
  const darkTheme = createTheme({
    palette: {
      mode: darkmode.value ? "dark" : "light",
    },
  });

  if (pageLoading) {
    return (<></>)
  }
  /* if (maintenance) {
    return (
      <Maintenance />
    )
  } */

  if (darkmode.value) {
    document.body.style.backgroundColor = "#121212";
    let style = document.createElement('style');
    style.innerHTML = `
      #root{
        background-image: url(${headerImageDark}), url(${footerImageDark});
        background-size: contain;
        background-repeat: no-repeat, no-repeat, repeat-x;
        background-position: center 50px, center bottom, 0 0;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      }`;
    document.head.appendChild(style);
  } else {
    document.body.style.backgroundColor = "white";
    let style = document.createElement('style');
    style.innerHTML = `
      #root {
        background-image: url(${headerImage}), url(${footerImage});
        background-size: contain;
        background-repeat: no-repeat, no-repeat, repeat-x;
        background-position: center 50px, center bottom, 0 0;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      }`;
    document.head.appendChild(style);
  }
  return (
    <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
      <CurrentMovieContext.Provider value={{ currentMovieCtx, setCurrentMovieCtx }} >
        <HeaderContext.Provider value={user}>
          <ThemeProvider theme={darkTheme}>
            <Routes>
              <Route path="/" element={<MainLayout />} >
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/dmca" element={<DmcaPage />} />
                <Route path="/policy" element={<PrivacyPolicyPage />} />
                <Route path="/contactus" element={<ContactPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/reset_password/:token" element={<Suspense fallback={<Box sx={{ height: "800px", display: "flex", justifyContent: "center", alignItems: "center", color: "black" }}>
                  <Grid fill={darkmode.value ? "white" : 'black'} />
                </Box>}><ResetPassword /></Suspense>} />
                <Route index element={<HomePage />} />
                <Route path="/addMovie" element={user ? <AddMoviePage /> : <HomePage />} />
                <Route path="/recent" element={<LatestsPage />} />
                <Route path="/dashboard">
                  <Route index element={user ? <Dashboard currentTab={1} /> : <Navigate to={'/'} />} />
                  <Route path='mysubsource' element={user ? <Dashboard currentTab={1} /> : <Navigate to={'/'} />} />
                  <Route path='settings' element={user ? <Dashboard currentTab={2} /> : <Navigate to={'/'} />} />
                  <Route path='messages' element={user ? <Dashboard currentTab={3} /> : <Navigate to={'/'} />} />
                  <Route path='downloads' element={user ? <Dashboard currentTab={4} /> : <Navigate to={'/'} />} />
                  <Route path='tickets' element={user ? <Dashboard currentTab={0} /> : <Navigate to={'/'} />} />
                </Route>
                <Route path='subtitles'>
                  <Route index element={<Navigate to={'/'} />} />
                  <Route path=':movieName' element={<MoviePage />} >
                    <Route path=':secondParam' element={<MoviePage />} >
                      <Route path=':thirdParam' element={<MoviePage />} />
                    </Route>
                  </Route>
                </Route>
                <Route path='subtitle'>
                  <Route index element={<Navigate to={'/'} />} />
                  <Route path=':movie/:lang/:id' element={<SubtitlePage />} />
                </Route>
                <Route path='ratings/:id' element={<RatingsPage />} />
                <Route path='search/:query' element={<FullSearch />} />
                <Route path='edit/:id' element={user ? <EditSubtitlePage /> : <Navigate to={'/'} />} />
                <Route path='editAdmin/:id' element={user ? <EditSubtitleAdminPage /> : <Navigate to={'/'} />} />
                <Route path='/upload' >
                  <Route index element={<UploadPage />} />
                  <Route path=':id' element={<UploadPage2 />} />
                </Route>
                <Route path='/u/:id' >
                  <Route index element={<UserProfilePage />} />
                  <Route path='subtitles' element={<AllSubsPage />}></Route>
                  <Route path='ratings' element={<UserRatings />}></Route>
                  <Route path='ratings_given' element={<UserRatingsGiven />}></Route>
                </Route>
                <Route path='/admin' >
                  <Route index element={<AdminPage />} />
                </Route>
                <Route
                  path="/*"
                  element={<BadUrl />} />
              </Route>

            </Routes>
          </ThemeProvider>
        </HeaderContext.Provider >
      </CurrentMovieContext.Provider>
    </SnackbarProvider>
  );
}

export default App;

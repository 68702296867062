/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Typography, Paper, Avatar, ToggleButton, Link, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FlagIcon from '@mui/icons-material/Flag';
import { Grid } from 'react-loading-icons';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import ReleaseTypeFilter from './ReleaseTypeFilter';
import SubtitlesTable from './SubtitlesTablev2';
import useDarkMode from 'use-dark-mode';
import MovieReport from '../Reports/MovieReport';
import LanguageFilter from './LanguageFilter'; // Import the new LanguageFilter component
const mainFont = "Regular-Family"

function Subtitles({ backToSerie, movieLink, typesFilter, setTypesFilter, totalSubs, movie, subs, langFilter, setLangFilter, loading, setLoading, urlFilter, reload }) {
    window.scrollTo(0, 0);
    const darkmode = useDarkMode();;
    const [sortByDate, setSortByDate] = useState(window.localStorage.getItem('bydate') !== undefined ? window.localStorage.getItem('bydate') === "1" ? true : false : false);
    const [reportShow, setReportShow] = useState(false);
    const navigate = useNavigate();
    const toggleSortByDate = () => {
        window.localStorage.setItem("bydate", sortByDate === true ? "0" : "1");
        setSortByDate(p => !p)
    }
    const toggleReportShow = () => {
        setReportShow(p => !p);
    }
    return (
        <Box sx={{ marginTop: "1rem", overflow: "hidden", mb: "3rem" }}>
            <MovieReport show={reportShow} toggleShow={toggleReportShow} movieId={movie.id} />
            <Box sx={{ px: { md: "3%", xl: "10%" } }}>
                {backToSerie ? <Box sx={{ display: backToSerie ? "flex" : 'none', flexDirection: "row", alignItems: "center", cursor: "pointer", width: "fit-content" }} onClick={() => { navigate("/subtitles/" + movieLink) }} >
                    <Paper sx={{ width: "fit-content", py: "0.25rem", px: "0.25rem", m: "0.25rem", backgroundColor: "rgb(255,255,255,0.6)", transform: "scale(0.75)" }}><ArrowBackIosNewIcon sx={{ fontSize: "0.75rem", color: "black" }} /></Paper>
                    <Typography fontFamily={mainFont} fontWeight={"bold"} fontSize={"1rem"} sx={{ lineHeight: "unset", color: darkmode.value ? "white" : 'black' }} > {"Back to Season List"}</Typography>
                </Box> :
                    <Typography fontFamily={mainFont} fontWeight={"bold"} fontSize={"1.5rem"} sx={{ color: darkmode.value ? "white" : "initial" }}>Subtitles For</Typography>
                }
                <Divider sx={{ borderColor: darkmode.value ? "white" : "initial" }} />
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Box sx={{ display: "flex", marginTop: "1rem", flexDirection: "row" }}>
                        <Box>
                            <img style={{ objectFit: "cover", borderRadius: "10px" }} src={movie.poster} width={'150px'} alt='poster' height={"225px"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://api.subsource.net/notfound.jpg";
                            }} />
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography variant={'h5'} fontFamily={mainFont} fontWeight={"bold"} sx={{ marginTop: "-5px", marginLeft: "0.5rem", color: darkmode.value ? "white" : "initial" }}>{movie.fullName}</Typography>
                                <Link sx={{ cursor: "pointer" }} onClick={toggleReportShow}><FlagIcon sx={{ ml: "1rem", "&:hover": { color: "red" }, cursor: "pointer", fontSize: '1.25rem', }} /></Link>
                            </Box>
                            <Box>
                                <Typography fontSize={"1rem"} fontFamily={mainFont} fontWeight={"bold"} sx={{ marginTop: "7px", marginLeft: "0.5rem", color: darkmode.value ? "white" : "initial" }}>Year: {movie.year}</Typography>
                            </Box>
                            <Box sx={{ marginLeft: "0.25rem", marginTop: "0.5rem", height: "100%", display: "flex", alignItems: "flex-end" }}>
                                <a href={movie?.imdbLink?.startsWith('tt') ? 'https://www.imdb.com/title/' + movie?.imdbLink : movie?.imdbLink} target='_blank' rel="noreferrer"><Avatar children={<img src="https://upload.wikimedia.org/wikipedia/commons/4/46/Imdb_logo_rounded_corners.png" width={'35px'} alt='imdb' />} sx={{ backgroundColor: "gold", m: "0.25rem", width: "35px", height: "35px", boxShadow: "0px 3px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" }} /></a>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", alignItems: "flex-end", justifyContent: "flex-start", display: "flex", gap: "1rem" }}>
                        <ReleaseTypeFilter typesFilter={typesFilter} setTypesFilter={setTypesFilter} urlFilter={urlFilter} setLoading={setLoading} reload={reload} />
                        <LanguageFilter langFilter={langFilter} setLangFilter={setLangFilter} reload={reload} setLoading={setLoading} />

                        <Paper>
                            <ToggleButton value={sortByDate} selected={sortByDate} onClick={toggleSortByDate} disableRipple sx={{ textWrap: "nowrap", '&.Mui-selected': { backgroundColor: "#0080808f", boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)", "&:hover": { backgroundColor: "#0080808f" } }, textTransform: "none", "&:hover": { backgroundColor: "initial" }, py: "0.5rem", px: "0.75rem" }}>
                                <SwapVertIcon sx={{ marginRight: "0.5rem" }} /> By Date
                            </ToggleButton>
                        </Paper>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                            <Tooltip title="Subtitles with unidentified release types cannot be found by Filtering.
                                        Try without Filter Type, too." placement='top' arrow>
                                <ErrorOutlineSharpIcon sx={{ width: "20px", height: "20px", ml: "0.4rem", color: darkmode.value ? "white" : "initial" }} />
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>

                {loading ?
                    <Box sx={{ height: "800px", display: "flex", justifyContent: "center", alignItems: "center", color: "black" }}>
                        <Grid fill={darkmode.value ? "white" : 'black'} />
                    </Box> : <>
                        <SubtitlesTable subs={subs} sortByDate={sortByDate} />
                        <Typography textAlign={"center"} fontSize={"30px"} sx={{ marginTop: "2rem", fontFamily: "Regular-Family", color: "#21495F", fontWeight: "Bold", display: totalSubs > 0 && subs.length === 0 ? "content" : "none" }}>No subtitles were found with selected filters</Typography>
                        <Typography textAlign={"center"} fontSize={"30px"} sx={{ marginTop: "2rem", fontFamily: "Regular-Family", color: "#21495F", fontWeight: "Bold", display: totalSubs === 0 ? "content" : "none" }}>No subtitles available</Typography>
                    </>
                }
            </Box>
        </Box>
    )
}

export default Subtitles
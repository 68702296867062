import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Fade, FormControl, FormControlLabel, FormLabel, InputBase, Link, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';

import axios from 'axios'
import useSnackbar from '../../hooks/useSnackbar';
function SubtitleMove({ show, toggleShow, subId }) {
    const { showSnackbar } = useSnackbar();
    const [movieId, setMovieId] = useState('');
    const [movie, setMovie] = useState(undefined);
    const [loadingMovie, setLoadingMovie] = useState(false);
    const [moving, setMoving] = useState(false);
    const handleMovieidChange = (e) => {
        setMovieId(e.target.value)
    }
    const handleLoadMovie = () => {
        setLoadingMovie(true);
        axios.post(process.env.REACT_APP_API_URL + "/api/admin/subtitle/move/getMovie", { newMovieId: movieId }, { withCredentials: true }).then(res => {
            if (res.data.success) {
                setMovie(res?.data?.movie)
            } else {
                showSnackbar(res?.data?.errMsg || "Error")
            }
            setLoadingMovie(false);
        }).catch(err => {
            setLoadingMovie(false);
            console.log(err)
        })
    }
    const handleMoveSubtitle = () => {
        setMoving(true);
        axios.post(process.env.REACT_APP_API_URL + "/api/admin/subtitle/move", { subId: subId, newMovieId: movieId }, { withCredentials: true }).then(res => {
            if (res.data.success) {
                showSnackbar(res?.data?.msg, 'success');
                window.location.reload();
            } else {
                showSnackbar(res?.data?.errMsg || "Error")
            }
            setMoving(false);
        }).catch(err => {
            setMoving(false);
            console.log(err)
        })
    }
    return (
        <Dialog open={show} onClose={toggleShow} sx={{ fontFamily: "Regular-Family", marginBottom: "1rem" }}>
            <DialogContent sx={{ display: "flex", justifyContent: "space-between", gap: "2rem" }}>

                <Box sx={{ width: "200px" }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem", textWrap: "nowrap" }}>Move Subtitle</Typography>
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", height: "230px" }}>
                        <TextField
                            variant='outlined'
                            size='small'
                            fullWidth
                            sx={{ mt: '2rem' }}
                            label="Movie ID"
                            value={movieId}
                            onChange={handleMovieidChange}
                        />
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <LoadingButton variant='contained' color='info' sx={{ textTransform: "none" }} disableRipple loading={loadingMovie} onClick={handleLoadMovie}>Load</LoadingButton>
                            <LoadingButton variant='contained' color='success' sx={{ textTransform: "none" }} disableRipple disabled={!movie} loading={moving} onClick={handleMoveSubtitle}>Move</LoadingButton>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "400px" }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>Movie Details</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>Title: {movie?.title}</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>Type: {movie?.type}</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>Season: {movie?.season || "N/A"}</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>IMDB: <Link sx={{ textDecoration: "none" }} href={"https://www.imdb.com/title/" + movie?.imdb} target="_blank">{movie?.imdb}</Link></Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}><Link sx={{ textDecoration: "none" }} href={movie?.sslink} target="_blank">SubSource Page</Link></Typography>
                        </Box>
                        <Box sx={{ display: movie ? "contents" : 'none' }}>
                            <img src={movie?.poster} alt={movie?.title} width={"150px"} height={"225px"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://api.subsource.net/notfound.jpg";
                            }}></img>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            {/* <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>Move Subtitle</Typography>
            

            </TextField>
            <Divider orientation='horizontal' sx={{ borderLeft: "1px solid black", mx: "1rem" }} />

            <Box sx={{ width: "100px" }}>
                
            </Box> */}
        </Dialog >
    )
}

export default SubtitleMove
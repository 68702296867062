import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode';
const langs = [
    "Select Language",
    'English',
    'Farsi/Persian',
    'Arabic',
    'Bengali',
    'Indonesian',
    'Abkhazian',
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Aragonese',
    'Armenian',
    'Assamese',
    'Asturian',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Big 5 code',
    'Bosnian',
    'Brazillian Portuguese',
    'Breton',
    'Bulgarian',
    'Burmese',
    'Catalan',
    'Chinese',
    'Chinese (Cantonese)',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Chinese BG code',
    'Chinese Bilingual',
    'Croatian',
    'Czech',
    'Danish',
    'Dari',
    'Dutch',
    'Espranto',
    'Estonian',
    'Extremaduran',
    'Finnish',
    'French',
    'French (Canada)',
    'French (France)',
    'Gaelic',
    'Gaelician',
    'Georgian',
    'German',
    'Greek',
    'Greenlandic',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Interlingua',
    'Irish',
    'Italian',
    'Japanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Korean',
    'Kurdish',
    'Kyrgyz',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Manipuri',
    'Marathi',
    'Mongolian',
    'Montenegrin',
    'Navajo',
    'Nepali',
    'Northen Sami',
    'Norwegian',
    'Occitan',
    'Odia',
    'Pashto',
    'Polish',
    'Portuguese',
    'Romanian',
    'Russian',
    'Santli',
    'Serbian',
    'Sindhi',
    'Sinhala',
    'Sinhalese',
    'Slovak',
    'Slovenian',
    'Somali',
    'Sorbian',
    'Spanish',
    'Spanish (Latin America)',
    'Spanish (Spain)',
    'Swahili',
    'Swedish',
    'Syriac',
    'Tagalog',
    'Tamil',
    'Tatar',
    'Telugu',
    'Tetum',
    'Thai',
    'Toki Pona',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh'
]
function LanguageInput({ lang, setLang, disabled }) {
    const darkmode = useDarkMode(false);
    const onChnage = (e) => {
        setLang(e.target.value)
    }
    return (
        <Box sx={{ marginTop: "1rem", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "0.5rem", fontWeight: "bold", color: darkmode.value ? "white" : "initial" }}>Language</Typography>
            <Box sx={{ minWidth: 120 }}>
                <FormControl>
                    <Select
                        disabled={disabled}
                        variant='standard'
                        labelId="Language"
                        id="lang"
                        value={lang}
                        label="Language"
                        onChange={onChnage}
                    >
                        {langs.map((lang, index) => (
                            <MenuItem key={index} value={lang} disabled={index === 0}>{lang}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default LanguageInput
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#homepage-news-container {
    height: auto;
    width: 30%;
    display: flex;
    flex-direction: column;
    min-width: 30%;
}

#homepage-news-head-title {
    font-size: 30px;
    font-family: Regular-Family;
    font-weight: bold;
    text-wrap: nowrap;
    color: #21495F;
}

#homepage-news-head-title.dark-mode {
    color: white;
}

#homepage-newscards-container {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    margin-top: 0.5rem;
}

#homepage-newscard {
    box-shadow: -2px 3px 5px 0px #00000040;
    background: #EEF7F759;
    border-radius: 10px;
}

#homepage-newscard.MuiAccordion-root:before {
    display: none;
}

.newsTextBox {
    font-size: 15px;
    font-family: Regular-Family;
    white-space: break-spaces;
    padding-right: 5px;
    line-height: 1.5;

}

.newsTextBox a {
    text-decoration: none !important;
    color: #1976d2;
}

.newsTextBox.dark-mode a {
    text-decoration: none !important;
    color: #81b9f1;
}

.newsTextBox p {
    margin: 0;
}

.newsTextBox img {
    width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Components/HomePage/News/News.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;IACtC,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;AACf","sourcesContent":["#homepage-news-container {\r\n    height: auto;\r\n    width: 30%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    min-width: 30%;\r\n}\r\n\r\n#homepage-news-head-title {\r\n    font-size: 30px;\r\n    font-family: Regular-Family;\r\n    font-weight: bold;\r\n    text-wrap: nowrap;\r\n    color: #21495F;\r\n}\r\n\r\n#homepage-news-head-title.dark-mode {\r\n    color: white;\r\n}\r\n\r\n#homepage-newscards-container {\r\n    display: flex;\r\n    gap: 0.5rem;\r\n    flex-direction: column;\r\n    margin-top: 0.5rem;\r\n}\r\n\r\n#homepage-newscard {\r\n    box-shadow: -2px 3px 5px 0px #00000040;\r\n    background: #EEF7F759;\r\n    border-radius: 10px;\r\n}\r\n\r\n#homepage-newscard.MuiAccordion-root:before {\r\n    display: none;\r\n}\r\n\r\n.newsTextBox {\r\n    font-size: 15px;\r\n    font-family: Regular-Family;\r\n    white-space: break-spaces;\r\n    padding-right: 5px;\r\n    line-height: 1.5;\r\n\r\n}\r\n\r\n.newsTextBox a {\r\n    text-decoration: none !important;\r\n    color: #1976d2;\r\n}\r\n\r\n.newsTextBox.dark-mode a {\r\n    text-decoration: none !important;\r\n    color: #81b9f1;\r\n}\r\n\r\n.newsTextBox p {\r\n    margin: 0;\r\n}\r\n\r\n.newsTextBox img {\r\n    width: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

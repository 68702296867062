import { useSnackbar as useNotistackSnackbar } from 'notistack';

const useSnackbar = () => {
    const { enqueueSnackbar } = useNotistackSnackbar();
    const showSnackbar = (message, variant = 'error', options = {}) => {
        enqueueSnackbar(message, {
            variant,
            ...options,
        });
    };

    return {
        showSnackbar,
    };
};

export default useSnackbar;

import React, { useEffect } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardMySubscene from './MySubsource/DashboardMySubsource';
import DashboardProfile from './Profile/DashboardProfile';
import DashboardTickets from './Tickets/DashboardTickets';
import DashboardDownloads from './Downloads/DashboardDownloads';
import DashboardMessages from './Messages/DashboardMessages';
import useDarkMode from 'use-dark-mode';
const BoldFont = 'Regular-Family';

function Dashboard({ currentTab }) {
    const [currTab, setCurrTab] = React.useState(currentTab);
    const location = useLocation()
    const navigate = useNavigate();
    const darkmode = useDarkMode();
    const handleChangeTab = (event, newValue) => {
        const tabs = ['tickets', 'mysubsource', 'settings', 'messages', 'downloads']
        setCurrTab(newValue);
        navigate('/dashboard/' + tabs[newValue])
    };
    useEffect(() => {
        const tabs = ['tickets', 'mysubsource', 'settings', 'messages', 'downloads']
        location.pathname.split("/dashboard/")[1] !== undefined && setCurrTab(tabs.indexOf(location.pathname.split("/dashboard/")[1]));
    }, [location])

    const tabStyle = { textTransform: 'none', fontFamily: BoldFont, fontSize: '1.15rem' }
    return (
        <>
            <Box sx={{ mx: { md: "0%", lg: "13%" }, height: "100%", backgroundColor: darkmode.value ? "rgb(18,18,18)" : "white", paddingTop: "2rem", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0)" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currTab} onChange={handleChangeTab} aria-label="basic tabs example" centered variant='fullWidth'>
                        <Tab label='My SubSource' value={1} sx={tabStyle} />
                        <Tab label='Messages' value={3} sx={tabStyle} />
                        <Tab label='Tickets' value={0} sx={tabStyle} />
                        <Tab label='My Downloads' value={4} sx={tabStyle} />
                        <Tab label='User Settings' value={2} sx={tabStyle} />
                    </Tabs>
                </Box>
                <Box sx={{ background: darkmode.value ? "linear-gradient(180deg, rgb(18 18 18) 0%, rgba(18, 18, 18, 1) 14%, rgb(6 47 47) 100%)" : "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 14%, rgba(152,200,200,1) 100%)" }}>
                    {currTab == 0 && <DashboardTickets />}
                    {currTab == 1 && <DashboardMySubscene />}
                    {currTab == 2 && <DashboardProfile />}
                    {currTab == 3 && <DashboardMessages />}
                    {currTab == 4 && <DashboardDownloads />}

                </Box>
            </Box >
        </>
    )
}

export default Dashboard
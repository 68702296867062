import { Box, Divider, Link, Skeleton, Typography } from '@mui/material';
import React from 'react'
import useDarkMode from 'use-dark-mode';
import { useNavigate } from 'react-router-dom';
import "./RecentSubs.css";
import RecentSub from './RecentSub';
function RecentSubs({ isLoading, subs }) {
    const navigate = useNavigate();
    const darkmode = useDarkMode();

    if (isLoading) {
        return (
            <>
                <Box sx={{ mt: "1rem", mb: "0.5rem", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Link onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/recent") } }} href="/recent" sx={{ textDecoration: "none", fontSize: "30px", fontFamily: "Regular-Family", fontWeight: "bold", color: darkmode.value ? "white" : "#21495F" }}>Recent Subtitles</Link>
                        <span style={{ fontSize: "12px", color: darkmode.value ? "white" : "black", opacity: "0.5", marginLeft: "5px", marginTop: "5px", fontFamily: "Regular-Family", fontWeight: "bold" }}>(updates every minute)</span>
                    </Box>
                    <Typography sx={{ fontSize: "30px", fontFamily: "Regular-Family", verticalAlign: "", fontWeight: "bold", color: darkmode.value ? "white" : "#21495F" }}>Owner</Typography>
                </Box>
                <Divider sx={{ borderColor: darkmode.value ? "white" : "black", borderBottomWidth: "2px", mb: "1.25rem" }} />
                <Box>
                    <Box sx={{ padding: "5px" }}>
                        {[1, 2, 3, 4, 5, 6].map(rec => (
                            <Box key={rec} sx={{ display: "flex", mb: "5px", gap: "2rem", justifyContent: "space-between", borderBottom: "1px solid transparent" }}>
                                <Skeleton animation="wave" variant='rounded' sx={{ fontSize: "20px", width: "100%" }} />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <>
            <Box sx={{ mt: "1rem", mb: "0.5rem", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Link onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/recent") } }} href="/recent" sx={{ textDecoration: "none", fontSize: "30px", fontFamily: "Regular-Family", fontWeight: "bold", color: darkmode.value ? "white" : "#21495F" }}>Recent Subtitles</Link>
                    <span style={{ fontSize: "12px", color: darkmode.value ? "white" : "black", opacity: "0.5", marginLeft: "5px", marginTop: "5px", fontFamily: "Regular-Family", fontWeight: "bold" }}>(updates every minute)</span>
                </Box>
                <Typography sx={{ fontSize: "30px", fontFamily: "Regular-Family", verticalAlign: "", fontWeight: "bold", color: darkmode.value ? "white" : "#21495F" }}>Owner</Typography>
            </Box>
            <Divider sx={{ borderColor: darkmode.value ? "white" : "black", borderBottomWidth: "2px", mb: "1.25rem" }} />
            <Box sx={{ padding: "5px" }}>
                {subs?.map(rec => (
                    <RecentSub rec={rec} key={rec.id} />
                ))}
            </Box>
        </>
    )
}

export default RecentSubs
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import SeriesTitle from '../Components/SeriesSeasons/SeriesTitle2';
import SeriesSeasons2 from '../Components/SeriesSeasons/SeriesSeasons2';
import Subtitles from '../Components/Subtitles/Subtitles2';
import { Grid, } from 'react-loading-icons';
import { useUserContext } from '../Contexts/userContext';
import NotFound from './NotFound';
import useDarkMode from 'use-dark-mode';
import { CurrentMovieContext } from '../Contexts/currentMovieContext';
const formattedLangToNormal = (lang) => {
    const swap = (json) => {
        var ret = {};
        for (var key in json) {
            ret[json[key]] = key;
        }
        return ret;
    }
    const formatted_langs = {
        'Farsi/Persian': "farsi_persian",
        'English': "english",
        'Arabic': "arabic",
        "Bengali": "bengali",
        "Albanian": "albanian",
        "Brazillian Portuguese": "brazilian_portuguese",
        "Portuguese": "portuguese",
        "Croatian": "croatian",
        "Czech": "czech",
        "Danish": "danish",
        "Dutch": "dutch",
        "Chinese": "chinese",
        "Chinese(Traditional)": "chinese_traditional",
        "Chinese(Simplified)": "chinese_simplified",
        "French(France)": "french_france",
        "French(Canada)": "french_canada",
        "Spanish(Latin America)": "spanish_latin_america",
        "Spanish(Spain)": "spanish_spain",
        "Serbian": "serbian",
        "Burmese": "burmese",
        "Finnish": "finnish",
        "Malayalam": "malayalam",
        "Urdu": "urdu",
        "Latvian": "latvian",
        "Lithuanian": "lithuanian",
        "Tagalog": "tagalog",
        "French": "french",
        "German": "german",
        "Greek": "greek",
        "Hebrew": "hebrew",
        "Kurdish": "kurdish",
        "Bosnian": "bosnian",
        "Basque": "basque",
        "Catalan": "catalan",
        "Kannada": "kannada",
        "Greenlandic": "greenlandic",
        "Macedonian": "macedonian",
        "Hindi": "hindi",
        "Estonian": "estonian",
        "Indonesian": "indonesian",
        "Italian": "italian",
        "Japanese": "japanese",
        "Korean": "korean",
        "Malay": "malay",
        "Slovenian": "slovenian",
        "Icelandic": "icelandic",
        "Sinhala": "Sinhala",
        "Norwegian": "norwegian",
        "Polish": "polish",
        "Romanian": "romanian",
        "Russian": "russian",
        "Spanish": "spanish",
        "Turkish": "turkish",
        "Swedish": "swedish",
        "Slovak": "slovak",
        "Telugu": "telugu",
        "Tamil": "tamil",
        "Thai": "thai",
        "Vietnamese": "vietnamese",
        "Hungarian": "hungarian",
        "Ukrainian": "ukrainian",
        "Bulgarian": "bulgarian",
        "Chinese BG code": "chinese_bg_code",
        "Big 5 code": "big_5_code",
    }
    const swapped = swap(formatted_langs);
    return swapped[lang];
}
function MoviePage() {
    const darkmode = useDarkMode();
    const user = useUserContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isMovie, setIsMovie] = useState(false);
    const [subs, setSubs] = useState([]);
    const [movieData, setMovieData] = useState({});
    const [seasons, setSeasons] = useState(false);
    const [bigPoster, setBigPoster] = useState(undefined);
    const [langFilter, setLangFilter] = useState();
    const [typesFilter, setTypesFilter] = useState();
    const [subsLoading, setSubsLoading] = useState(false);
    const [urlFilter, setUrlFilter] = useState(undefined);
    const [totalSubs, setTotalSubs] = useState(undefined);
    const [notFound, setNotFound] = useState(false);
    const { setCurrentMovieCtx } = useContext(CurrentMovieContext)
    const params = useParams();
    const location = useLocation();
    useEffect(() => {
        setLoading(true);
        setIsMovie(false);
        setSubs([]);
        setMovieData({});
        setSeasons(false);
        setBigPoster(undefined)
        var lf = [];
        var rf = [];
        try {
            lf = JSON.parse(window.localStorage.getItem('langs')) || [];
            setLangFilter(JSON.parse(window.localStorage.getItem('langs')) || [])
        } catch (error) {
            console.log({ error })
        }
        try {
            rf = JSON.parse(window.localStorage.getItem('release_types')) || [];
            setTypesFilter(JSON.parse(window.localStorage.getItem('release_types')) || [])
        } catch (error) {
            console.log({ error })
        }
        axios.post(process.env.REACT_APP_API_URL + "/api/getMovie", { langs: lf, movieName: params?.movieName, season: params?.secondParam?.toLowerCase()?.includes("season") ? params.secondParam.toLowerCase() : undefined }, { withCredentials: true }).then(res => {
            if (res.data.success) {
                if (res?.data?.movie) {
                    document.title = `Download Subtitles For ${res?.data?.movie?.fullName}`
                }
                if (res?.data?.seasons?.length > 0) {
                    document.title = `Download Subtitles For ${res?.data?.seasons[0]?.title}`
                }
                if (params.thirdParam && formattedLangToNormal(params.thirdParam) !== undefined) {
                    setUrlFilter(formattedLangToNormal(params.thirdParam))
                    setTotalSubs(res.data.subs.length)
                    setSubs(res.data.subs.filter(x => x.lang === formattedLangToNormal(params.thirdParam)))
                    setMovieData(res.data.movie)
                    setLoading(false);
                    if (user) {
                        setCurrentMovieCtx(res?.data?.movie?.id)
                    }
                } else {
                    if (params.secondParam && (params.secondParam.includes('season') || formattedLangToNormal(params.secondParam) !== undefined)) {
                        if (params.secondParam.includes('season')) {
                            if (lf.length > 0) {
                                console.log("season with filter")
                                setTotalSubs(res.data.subs.length)
                                rf.length > 0 ? setSubs(res.data.subs.filter(x => lf.includes(x.lang) && rf.includes(x.rt))) : setSubs(res.data.subs.filter(x => lf.includes(x.lang)));
                                setMovieData(res.data.movie);
                                setLoading(false);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }

                            } else {
                                setTotalSubs(res.data.subs.length)
                                rf.length > 0 ? setSubs(res.data.subs.filter(x => rf.includes(x.rt))) : setSubs(res.data.subs);
                                setMovieData(res.data.movie)
                                setLoading(false);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }
                            }
                        } else {
                            setUrlFilter(formattedLangToNormal(params.secondParam))
                            setTotalSubs(res.data.subs.length)
                            setSubs(res.data.subs.filter(x => x.lang === formattedLangToNormal(params.secondParam)))
                            setMovieData(res.data.movie)
                            setLoading(false);
                            if (user) {
                                setCurrentMovieCtx(res?.data?.movie?.id)
                            }
                        }
                    } else {
                        if (res?.data?.seasons) {
                            setIsMovie(false);
                            setSeasons(res.data.seasons.sort((a, b) => a.season - b.season));
                            setBigPoster(res?.data?.bigPoster)
                            if (user) {
                                setCurrentMovieCtx(res?.data?.movie?.id)
                            }

                            setLoading(false);
                        } else {
                            if (lf.length > 0) {
                                setTotalSubs(res.data.subs.length)
                                rf.length > 0 ? setSubs(res.data.subs.filter(x => lf.includes(x.lang) && rf.includes(x.rt))) : setSubs(res.data.subs.filter(x => lf.includes(x.lang)));
                                setMovieData(res.data.movie);
                                setLoading(false);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }

                            } else {
                                setTotalSubs(res.data.subs.length)
                                rf.length > 0 ? setSubs(res.data.subs.filter(x => rf.includes(x.rt))) : setSubs(res.data.subs);
                                setMovieData(res.data.movie)
                                setLoading(false);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }

                            }
                        }
                    }
                }
            } else {
                setLoading(false);
            }
        }).catch(err => {
            if (err?.response?.status === 404) {
                setNotFound(true);
            }
            console.log({ err })
        })

        // eslint-disable-next-line
    }, [location.pathname, user?.id])
    const handleSeasonClick = (seasonNumber) => {
        navigate(location.pathname + "/season-" + seasonNumber)
        setSeasons(false);
        setBigPoster(undefined);
        setIsMovie(false);
        setMovieData(false);
        setLoading(true);
    }
    const handleReload = () => {
        axios.post(process.env.REACT_APP_API_URL + "/api/getMovie", { movieName: params?.movieName, season: params?.secondParam?.toLowerCase()?.includes("season") ? params.secondParam.toLowerCase() : undefined }, { withCredentials: true }).then(res => {
            if (res?.data?.success) {
                if (res?.data?.movie) {
                    document.title = `Download Subtitles For ${res?.data?.movie?.fullName}`
                }
                if (res?.data?.seasons?.length > 0) {
                    document.title = `Download Subtitles For ${res?.data?.seasons[0]?.title}`
                }
                if (params.thirdParam && formattedLangToNormal(params.thirdParam) !== undefined) {
                    setUrlFilter(formattedLangToNormal(params.thirdParam))
                    setTotalSubs(res.data.subs.length)
                    setSubs(res.data.subs.filter(x => x.lang === formattedLangToNormal(params.thirdParam)))
                    setMovieData(res.data.movie)
                    setLoading(false);
                    if (user) {
                        setCurrentMovieCtx(res?.data?.movie?.id)
                    }
                } else {
                    if (params.secondParam && (params.secondParam.includes('season') || formattedLangToNormal(params.secondParam) !== undefined)) {
                        if (params.secondParam.includes('season')) {
                            if (langFilter.length > 0) {
                                setTotalSubs(res.data.subs.length)
                                typesFilter.length > 0 ? setSubs(res.data.subs.filter(x => langFilter.includes(x.lang) && typesFilter.includes(x.rt))) : setSubs(res.data.subs.filter(x => langFilter.includes(x.lang)));
                                setMovieData(res.data.movie);
                                setLoading(false);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }
                            } else {
                                setTotalSubs(res.data.subs.length)
                                typesFilter.length > 0 ? setSubs(res.data.subs.filter(x => typesFilter.includes(x.rt))) : setSubs(res.data.subs);
                                setMovieData(res.data.movie)
                                setLoading(false);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }
                            }

                        } else {
                            setUrlFilter(formattedLangToNormal(params.secondParam))
                            setTotalSubs(res.data.subs.length)
                            setSubs(res.data.subs.filter(x => x.lang === formattedLangToNormal(params.secondParam)))
                            setMovieData(res.data.movie)
                            setLoading(false);
                            if (user) {
                                setCurrentMovieCtx(res?.data?.movie?.id)
                            }
                        }
                    } else {
                        if (res?.data?.seasons) {
                            setIsMovie(false);
                            setSeasons(res.data.seasons.sort((a, b) => a.season - b.season));
                            setBigPoster(res?.data?.bigPoster)
                            if (user) {
                                setCurrentMovieCtx(res?.data?.movie?.id)
                            }
                            setLoading(false);
                        } else {
                            if (langFilter.length > 0) {
                                setIsMovie(false);
                                setMovieData(res.data.movie);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }
                                setLoading(false);
                                setTotalSubs(res.data.subs.length)
                                typesFilter.length > 0 ? setSubs(res.data.subs.filter(x => langFilter.includes(x.lang) && typesFilter.includes(x.rt))) : setSubs(res.data.subs.filter(x => langFilter.includes(x.lang)));
                            } else {
                                setIsMovie(false);
                                setMovieData(res.data.movie);
                                if (user) {
                                    setCurrentMovieCtx(res?.data?.movie?.id)
                                }
                                setLoading(false);
                                setTotalSubs(res.data.subs.length)
                                typesFilter.length > 0 ? setSubs(res.data.subs.filter(x => typesFilter.includes(x.rt))) : setSubs(res.data.subs);
                            }
                        }
                    }
                }
                setSubsLoading(false)
            } else {
                setLoading(false);
            }
        }).catch(err => {
            if (err.response.status === 404) {
                setNotFound(true);
            }
            console.log({ err })
        })
    }
    if (notFound) {
        return (
            <NotFound />
        )
    }
    return (
        loading ?
            <>
                <Box sx={{ height: "800px", display: "flex", justifyContent: "center", alignItems: "center", color: "black" }}>
                    <Grid fill={darkmode.value ? "white" : 'black'} />
                </Box>
            </>
            :
            isMovie ?
                <>
                    <Box>
                        <Subtitles backToSerie={false} typesFilter={typesFilter} setTypesFilter={setTypesFilter} totalSubs={totalSubs} subs={subs} movie={movieData} langFilter={langFilter} setLangFilter={setLangFilter} loading={subsLoading} setLoading={setSubsLoading} urlFilter={urlFilter} reload={handleReload} />
                    </Box>
                </>
                :
                seasons ?
                    <Box sx={{ mx: { md: "0%", lg: "13%", maxWidth: "1500px", display: "flex", flexDirection: "row" }, paddingBottom: "5%", backgroundColor: "transparent", minHeight: "80vh" }}>
                        <SeriesTitle seasons={seasons} bigPoster={bigPoster} />
                        <SeriesSeasons2 seasons={seasons} handleSeasonClick={handleSeasonClick} />
                    </Box>
                    :
                    <>
                        <Box>
                            <Subtitles backToSerie={movieData?.type === "TVSeries"} movieLink={params?.movieName} typesFilter={typesFilter} setTypesFilter={setTypesFilter} totalSubs={totalSubs} subs={subs} movie={movieData} langFilter={langFilter} setLangFilter={setLangFilter} loading={subsLoading} setLoading={setSubsLoading} urlFilter={urlFilter} reload={handleReload} />
                        </Box>
                    </>
    )
}

export default MoviePage
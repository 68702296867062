import { Switch } from '@mui/material';
import React from 'react';
import useDarkMode from 'use-dark-mode';
import { DarkModeToggle } from "react-dark-mode-toggle-2";


const DarkModeToggleBtn = () => {
    const darkMode = useDarkMode(false);

    return (
        <DarkModeToggle onChange={darkMode.toggle} isDarkMode={darkMode.value} size={'3.5rem'} speed={2} />
    );
};

export default DarkModeToggleBtn;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parallelogram {
	transform: perspective(300px) rotateX(-30deg);
	-o-transform: perspective(300px) rotateX(-30deg);
	-moz-transform: perspective(300px) rotateX(-30deg);
	transform-origin: 100% 50%;
	margin: 10px 90px;
}`, "",{"version":3,"sources":["webpack://./src/css/SubtitlePage.css"],"names":[],"mappings":"AAAA;CAEC,6CAA6C;CAC7C,gDAAgD;CAChD,kDAAkD;CAIlD,0BAA0B;CAC1B,iBAAiB;AAClB","sourcesContent":[".parallelogram {\r\n\t-webkit-transform: perspective(300px) rotateX(-30deg);\r\n\ttransform: perspective(300px) rotateX(-30deg);\r\n\t-o-transform: perspective(300px) rotateX(-30deg);\r\n\t-moz-transform: perspective(300px) rotateX(-30deg);\r\n\t-webkit-transform-origin: 100% 50%;\r\n\t-moz-transform-origin: 100% 50%;\r\n\t-o-transform-origin: 100% 50%;\r\n\ttransform-origin: 100% 50%;\r\n\tmargin: 10px 90px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Button, Paper, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp';

const langs = [
    "Select Language",
    'English',
    'Farsi/Persian',
    'Arabic',
    'Bengali',
    'Indonesian',
    'Abkhazian',
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Aragonese',
    'Armenian',
    'Assamese',
    'Asturian',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Big 5 code',
    'Bosnian',
    'Brazillian Portuguese',
    'Breton',
    'Bulgarian',
    'Burmese',
    'Catalan',
    'Chinese',
    'Chinese (Cantonese)',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Chinese BG code',
    'Chinese Bilingual',
    'Croatian',
    'Czech',
    'Danish',
    'Dari',
    'Dutch',
    'Espranto',
    'Estonian',
    'Extremaduran',
    'Finnish',
    'French',
    'French (Canada)',
    'French (France)',
    'Gaelic',
    'Gaelician',
    'Georgian',
    'German',
    'Greek',
    'Greenlandic',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Interlingua',
    'Irish',
    'Italian',
    'Japanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Korean',
    'Kurdish',
    'Kyrgyz',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Manipuri',
    'Marathi',
    'Mongolian',
    'Montenegrin',
    'Navajo',
    'Nepali',
    'Northen Sami',
    'Norwegian',
    'Occitan',
    'Odia',
    'Pashto',
    'Polish',
    'Portuguese',
    'Romanian',
    'Russian',
    'Santli',
    'Serbian',
    'Sindhi',
    'Sinhala',
    'Sinhalese',
    'Slovak',
    'Slovenian',
    'Somali',
    'Sorbian',
    'Spanish',
    'Spanish (Latin America)',
    'Spanish (Spain)',
    'Swahili',
    'Swedish',
    'Syriac',
    'Tagalog',
    'Tamil',
    'Tatar',
    'Telugu',
    'Tetum',
    'Thai',
    'Toki Pona',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh'
]

const LanguageFilter = ({ langFilter, setLangFilter, reload, setLoading }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const handleChangeLanguage = (event) => {
        const { target: { value } } = event;
        setLangFilter(typeof value === 'string' ? value.split(',') : value);
    };
    const handleSaveFilters = () => {
        setIsEditing(false);
        try {
            window.localStorage.setItem('langs', JSON.stringify(langFilter));
        } catch (error) {
            console.log({ error });
        }
        setLoading(true)
        reload();
    };
    const handleScroll = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    return (
        <>
            {isEditing ? (
                <div style={{ display: "flex", gap: "1rem" }}>
                    <FormControl style={{ minWidth: 120 }} size="small">
                        <InputLabel htmlFor="selected-language">Language</InputLabel>
                        <Select
                            open={isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            value={langFilter}
                            onChange={handleChangeLanguage}
                            inputProps={{
                                name: 'language',
                                id: 'selected-language',
                            }}
                            size="small"
                            multiple
                            input={<OutlinedInput label="Language" />}
                            renderValue={(selected) => {
                                return selected.length === 0 ? "All Languages" : selected.join(', ');
                            }}
                            displayEmpty={false}
                            MenuProps={{
                                PaperProps: {
                                    onMouseDown: (e) => e.preventDefault(),
                                },
                                disableScrollLock: true,
                            }}
                            sx={{ width: "250px" }}
                        >
                            {langs.map((lang, index) => (
                                <MenuItem key={lang} value={lang} disabled={index === 0}>
                                    <Checkbox checked={langFilter.indexOf(lang) > -1} />
                                    <ListItemText primary={lang} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="outlined" disableRipple sx={{ height: "auto" }} onClick={handleSaveFilters}>
                        <SaveIcon />
                    </Button>
                </div>
            ) : (
                <Paper>
                    <Button
                        onClick={() => setIsEditing(true)}
                        disableRipple
                        sx={{
                            textWrap: "nowrap",
                            '&.Mui-selected': {
                                backgroundColor: "#0080808f",
                                boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                "&:hover": { backgroundColor: "#0080808f" },
                            },
                            textTransform: "none",
                            "&:hover": { backgroundColor: "initial" },
                            py: "0.5rem",
                            px: "0.75rem"
                        }}
                    >
                        <FilterAltSharpIcon sx={{ mr: "0.5rem" }} />
                        <Typography variant="h7">
                            {langFilter.length === 0 ? "All Languages" : langFilter.length === 1 ? langFilter[0] : `${langFilter[0]} And ${langFilter.length - 1} more.`}
                        </Typography>
                    </Button>
                </Paper>
            )}
        </>
    );
};

export default LanguageFilter;

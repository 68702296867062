import { Box, Paper, Typography } from '@mui/material'
import { convert } from 'html-to-text'
import React from 'react'
import useDarkMode from 'use-dark-mode';

function SubtitlePreview({ preview, show, isNew }) {
    const darkmode = useDarkMode();
    return (
        <Paper elevation={6} sx={{ mb: "2rem", display: show ? "flex" : "none", width: "450px", backgroundColor: darkmode.value ? "unset" : "white", marginTop: "2rem", "& .MuiTypography-root": { fontFamily: "Regular-Family" } }} id={'preview'} >
            <Box sx={{ p: "1rem", overflow: "hidden" }} >
                <Typography sx={{ whiteSpace: "break-spaces", wordBreak: "break-word", fontSize: "0.75rem", fontFamily: "Regular-Family" }}>{isNew ? preview : convert(preview)}</Typography>
            </Box>
        </Paper>
    )
}

export default SubtitlePreview
import { Typography, Link, Tooltip, Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import { decode } from 'html-entities';
import { TableVirtuoso } from 'react-virtuoso'
import useDarkMode from 'use-dark-mode';
import up1Icon from '../../icons/up1.webp';
import up2Icon from '../../icons/up2.webp';
import up3Icon from '../../icons/up3.webp';
import tr1Icon from '../../icons/tr1.webp';
import tr2Icon from '../../icons/tr2.webp';
import tr3Icon from '../../icons/tr3.webp';

const badge_definition = [
    { badge: 'up1', description: 'Verified Uploader\nMore than 200 subtitles uploaded!', image: up1Icon },
    { badge: 'up2', description: 'Persistent Uploader\nMore than 1000 subtitles uploaded!', image: up2Icon },
    { badge: 'up3', description: 'Legendary Uploader\nMore than 5000 subtitles uploaded!', image: up3Icon },
    { badge: 'tr1', description: 'Verified Translator\nMore than 75 translations & 1 year of practice!', image: tr1Icon },
    { badge: 'tr2', description: 'Distinguished Translator\nMore than 400 translations & 4 years of practice!', image: tr2Icon },
    { badge: 'tr3', description: 'The Ancient One\nMore than 1000 translations & 10 years of practice!', image: tr3Icon },
];
function SubtitlesTablev2({ subs, sortByDate }) {
    const darkmode = useDarkMode();
    const navigate = useNavigate();
    return (
        <TableVirtuoso
            style={{ marginTop: "2rem", maxWidth: "100%", overflow: "hidden", borderRadius: "10px", borderCollapse: "collapse", minWidth: "100%" }}
            className='moviesubs-table2'
            data={sortByDate ?
                JSON.parse(JSON.stringify(subs)).sort(function (a, b) {
                    return a['lang'] < b['lang'] ? -1 : (a['lang'] > b['lang'] ? 1 : (a['date'] > b['date'] ? -1 : 1));
                }) : subs}
            useWindowScroll
            fixedHeaderContent={() => (
                <tr style={{ background: darkmode.value ? "#144141" : "#79A7A7", height: "50px" }}>
                    <th style={{ textAlign: "left", width: "11%", }}><Box className='headerColumn'>Language</Box></th>
                    <th style={{ textAlign: "left", width: "35%" }}><Box className='headerColumn'>Release Name</Box></th>
                    <th style={{ textAlign: "center", width: "4%" }}><Box className='headerColumn'>H.I.</Box></th>
                    <th style={{ textAlign: "center", width: "10%" }}><Box className='headerColumn'>Type</Box></th>
                    <th style={{ textAlign: "center", width: "8%" }}><Box className='headerColumn'>Owner</Box></th>
                    <th style={{ textAlign: "center", width: "4%" }}><Box className='headerColumn'>Badge</Box></th>
                    <th style={{ textAlign: "center", width: "28%" }}><Box className='headerColumn'>Caption</Box></th>
                </tr>
            )}
            increaseViewportBy={{ top: 1000, bottom: 1000 }}
            totalCount={subs.length}
            components={{
                TableRow: props => {
                    return (
                        <tr
                            data-part="row"
                            {...{ [props['data-index'] % 2 ? 'data-odd' : 'data-even']: true }}
                            {...{ [darkmode.value ? 'data-dark' : 'data-light']: true }}
                            {...{ 'data-last-lang': subs[props['data-index']]?.lang !== subs[props['data-index'] + 1]?.lang && props['data-index'] !== subs.length - 1 }}
                            {...props}
                        >

                        </tr>
                    );
                },
            }}
            itemContent={(index, sub) => (
                <>
                    <Link href={sub.fullLink} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(sub.fullLink) } }} sx={{ display: "contents", color: darkmode.value ? "white" : "black", "&:visited": { color: darkmode.value ? "#a561e3" : "#551A8B" } }}>
                        <td style={{ maxWidth: '11%', height: "40px", verticalAlign: "middle" }}>
                            <Box className="bodyColumn" sx={{ fontSize: "12px", textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}>
                                {sub.lang}
                            </Box>
                        </td>
                        <td align="left" style={{ maxWidth: '35%', height: "40px", verticalAlign: "middle" }}>
                            <Box className="bodyColumn">
                                <Tooltip title={sub.releaseName} placement='top'>
                                    <Typography sx={{ width: "auto", maxWidth: "min-content", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontSize: "12px" }}>
                                        {sub.releaseName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </td>
                        <td align="center" style={{ maxWidth: "4%", height: "40px", verticalAlign: "middle" }}>
                            <Box className="bodyColumn">
                                {sub.hi === 1 ? <><HearingDisabledIcon sx={{ color: "darkgray", fontSize: "18px" }} /></> : <></>}
                            </Box>
                        </td>
                        <td align="center" style={{ maxWidth: "10%", height: "40px", verticalAlign: "middle" }}>
                            <Box className="bodyColumn" sx={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: "12px", textWrap: "nowrap" }}>
                                {sub.rt}
                            </Box>
                        </td>
                        <td align="center" style={{ maxWidth: "8%", height: "40px", verticalAlign: "middle" }}>
                            <Box className="bodyColumn" sx={{ whiteSpace: "nowrap", maxWidth: "115px", textOverflow: "ellipsis", overflow: 'hidden', fontSize: "12px" }}>
                                <Tooltip title={sub.uploadedBy} placement='top' >
                                    {sub.uploadedBy}
                                </Tooltip>
                            </Box>
                        </td>
                        <td align="center" style={{ maxWidth: "4%", height: "40px", verticalAlign: "middle" }}>
                            {sub.uploaderBadges.map((badgeId) => {
                                const badge = badge_definition.find((badge) => badge.badge === badgeId);
                                if (badge) {
                                    return (
                                        <Box sx={{ display: "flex", ml: "10px", width: "fit-content", bgcolor: "white", boxShadow: "0 4px 11px 0 #252c6126,0 1px 3px 0 #5d649433", borderRadius: "9999px" }}>
                                            <Tooltip key={badge.badge} title={<span style={{ whiteSpace: 'pre-line', textAlign: "center", display: "inline-block" }}>{badge.description}</span>} arrow placement='top'>
                                                <img src={badge.image} alt={badge.badge} width="32px" height="auto" />
                                            </Tooltip>
                                        </Box>
                                    );
                                }
                                return null;
                            })}
                        </td>
                        <td align="center" style={{ maxWidth: "28%", height: "40px", verticalAlign: "middle" }}>
                            <Box className="bodyColumn" sx={{ fontFamily: "Regular-Family !important", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontSize: "12px", paddingRight: "5px" }}>
                                <Tooltip title={<span style={{ fontFamily: "Regular-Family" }}>{decode(sub.commentary)}</span>} placement='top' >
                                    <Typography sx={{ fontFamily: "Regular-Family", fontSize: "12px", overflow: "hidden", textOverflow: "ellipsis", }}>{decode(sub.commentary)}</Typography>
                                </Tooltip>
                            </Box>
                        </td>
                    </Link>
                </>
            )}
        />
    )
}

export default SubtitlesTablev2
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th {
    text-align: left;
}

thead {
    background-color: #91aab7;
    color: white;
    border-color: #91aab7;
}

table {
    margin: 0;
    border-collapse: collapse;
    border-spacing: 15px;
    background-color: #eef7f759;
}

tbody>tr {
    border-top: 7px solid #eef7f759;
    border-bottom: 7px solid #eef7f759;
}`, "",{"version":3,"sources":["webpack://./src/css/LatestsPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,SAAS;IACT,yBAAyB;IACzB,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA;IACI,+BAA+B;IAC/B,kCAAkC;AACtC","sourcesContent":["th {\r\n    text-align: left;\r\n}\r\n\r\nthead {\r\n    background-color: #91aab7;\r\n    color: white;\r\n    border-color: #91aab7;\r\n}\r\n\r\ntable {\r\n    margin: 0;\r\n    border-collapse: collapse;\r\n    border-spacing: 15px;\r\n    background-color: #eef7f759;\r\n}\r\n\r\ntbody>tr {\r\n    border-top: 7px solid #eef7f759;\r\n    border-bottom: 7px solid #eef7f759;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import axios from 'axios'
import useDarkMode from 'use-dark-mode'
import useSnackbar from '../../../hooks/useSnackbar'
const subjects = [
    'Technical Problem',
    'Change Poster or Title',
    'Claim Subscene Profile',
    'Plagiarism/Copying',
    'Release Name Issues',
    'Suggestions & Criticisms',
    'Edit or Delete My Subtitles',
    'Apply for a Rank',
    'Other Issues'
];
const languages = [
    "Farsi/Persian",
    "Arabic",
    "Bengali",
    "Others"
]
const formattedLanguages = {
    "Farsi/Persian": 'farsi_persian',
    "Arabic": 'arabic',
    "Bengali": 'bengali',
    "Others": 'others'
}
const rankOptions = [
    {
        title: 'A translator badge',
        value: 0
    },
    {
        title: 'An uploader badge',
        value: 1
    }
]
function NewTicketModal({ open, onClose }) {
    const { showSnackbar } = useSnackbar();
    const darkmode = useDarkMode();
    const [subject, setSubject] = useState(-1);
    const [language, setlanguage] = useState(-1);
    const [rank, setRank] = useState(-1)
    const [submitting, setSubmitting] = useState(false);
    const urlRef = useRef();
    const descriptionRef = useRef();
    const onSubjectChange = (e) => {
        setSubject(e.target.value)
    }
    const onLanguageChange = (e) => {
        setlanguage(e.target.value)
    }
    const onRankChange = (e) => {
        setRank(e.target.value)
    }
    const handleSubmit = () => {
        if (subject < 0) {
            return showSnackbar("Please select a subject.")
        }
        if ([3, 4, 6, 7].includes(subject) && language < 0) {
            return showSnackbar("Please select a language.")
        }
        if (descriptionRef.current.value.length === 0) {
            return showSnackbar("Please explain about your problem.")
        }
        setSubmitting(true);
        axios.post(process.env.REACT_APP_API_URL + "/api/user/mySubsource/tickets/create", { subject: subjects[subject], rank: rank ?? undefined, language: [3, 4, 6, 7].includes(subject) ? formattedLanguages[languages[language]] : undefined, url: urlRef?.current?.value || undefined, desc: descriptionRef?.current?.value || undefined }, { withCredentials: true }).then(res => {
            if (res.data.success) {
                window.location.reload();
            } else {
                setSubmitting(false);
                showSnackbar("Error.")
            }
        }).catch(err => {
            setSubmitting(false);
            showSnackbar("Error.")
        })
    }
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={"New Ticket"} maxWidth="md">
            <DialogTitle id={'New Ticket'} sx={{ fontFamily: "Regular-Family", fontSize: "35px" }}>
                New Ticket
            </DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Box sx={{ minWidth: "600px", display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ mb: "0.5rem", color: darkmode.value ? "white" : "#21495f", fontSize: "18px" }}>Subject/Issue</Typography>
                    <Select
                        value={subject}
                        onChange={onSubjectChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        size='small'
                    >
                        <MenuItem disabled value={-1}>Select Subject/Issue</MenuItem>
                        {subjects.map((subject, index) => (
                            <MenuItem value={index}>{subject}</MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ minWidth: "600px", display: [3, 4, 6, 7].includes(subject) ? "flex" : "none", flexDirection: "column", }}>
                    <Typography sx={{ mb: "0.5rem", color: darkmode.value ? "white" : "#21495f", fontSize: "18px" }}>Language</Typography>
                    <Select
                        value={language}
                        onChange={onLanguageChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        size='small'
                    >
                        <MenuItem disabled value={-1}>Select a Language</MenuItem>
                        <MenuItem value={0}>Farsi/Persian</MenuItem>
                        <MenuItem value={1}>Arabic</MenuItem>
                        <MenuItem value={2}>Bengali</MenuItem>
                        <MenuItem value={3}>Others</MenuItem>
                    </Select>
                </Box>
                {/* ranks options */}
                <Box
                    sx={{
                        display: subject === 7 ? 'flex' : 'none',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        sx={{ mb: '0.5rem', color: darkmode.value ? 'white' : '#21495f', fontSize: '18px' }}
                    >
                        I want to apply for ...
                    </Typography>
                    <Select
                        value={rank}
                        onChange={onRankChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        size="small"
                    >
                        <MenuItem disabled value={-1}>
                            Select an option
                        </MenuItem>
                        {rankOptions.map((op) => (
                            <MenuItem key={op.value} value={op.value}>
                                {op.title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ minWidth: "600px", display: [0, 1, 3, 4, 8].includes(subject) ? "flex" : "none", flexDirection: "column" }}>
                    <Typography sx={{ mb: "0.5rem", color: darkmode.value ? "white" : "#21495f", fontSize: "18px" }}>URL (optional)</Typography>
                    <TextField
                        size='small'
                        placeholder='Enter URL here.'
                        inputRef={urlRef}
                    />
                </Box>
                <Box sx={{ minWidth: "600px", display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ mb: "0.5rem", color: darkmode.value ? "white" : "#21495f", fontSize: "18px" }}>Explain More <span style={{ color: "darkred", display: ![3, 4, 6, 7].includes(subject) ? "contents" : 'none' }}>(in English)</span></Typography>
                    <TextField
                        placeholder='Explain your problem in detail...'
                        multiline
                        minRows={3}
                        maxRows={3}
                        inputRef={descriptionRef}
                        inputProps={{ dir: "auto" }}
                        InputProps={{ sx: { fontFamily: "Regular-Family" } }}
                    />
                </Box>
            </DialogContent >
            <DialogActions sx={{ pr: "1.5rem", pb: "1rem" }}>
                <Button
                    disabled={submitting}
                    variant='contained'
                    onClick={handleSubmit}
                    color="success"
                    sx={{ textTransform: "none" }}
                >
                    Submit
                </Button>
                <Button
                    disabled={submitting}
                    variant='outlined'
                    onClick={onClose}
                    color="error"
                    sx={{ textTransform: "none" }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default NewTicketModal
import { Box, Button, ButtonGroup, Divider, Link, Paper, Typography } from '@mui/material'
import axios from 'axios';
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import { useUserContext } from '../../Contexts/userContext';
import useSnackbar from '../../hooks/useSnackbar';
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function SubtitleDetails({ rated, details, togglePreview, toggleReport, isOwned, subId, privs, toggleMoveSub }) {
    const darkmode = useDarkMode();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const handleEditClick = () => {
        if (privs?.includes("5")) navigate('/editadmin/' + details.id)
        else if (isOwned) navigate(`/edit/${details.id}`)
    }
    const user = useUserContext();
    const isRated = rated !== undefined;
    const good = rated === "good" ? 1 : 0;
    const bad = rated === 'bad' ? 1 : 0;
    const totalRates = parseInt(details?.rating?.rated) + (isRated ? 1 : 0);
    const goodRates = parseInt(details?.rating?.goodRate) + good;
    const badRates = parseInt(details?.rating?.badRate) + bad;
    const handleDeleteSub = () => {
        var answer = window.confirm("Confirm Deleting Sub?");
        if (answer) {
            axios.post(process.env.REACT_APP_API_URL + "/api/admin/subtitle/delete", { subId: subId }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    window.location.reload();
                } else {
                    showSnackbar(res?.data?.errMsg || "Error")
                }
            }).catch(err => {
                showSnackbar("Error")
                console.log(err)
            })
        }
    }
    return (
        <Paper elevation={6} sx={{ width: "450px", backgroundColor: darkmode.value ? "unset" : "white", "& .MuiTypography-root": { fontFamily: "Arial" }, marginBottom: "2rem" }}>
            <Box sx={{ p: "1rem", }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", height: "30px" }}>
                    <Typography sx={{ fontFamily: "Regular-Family", fontWeight: "bold", fontSize: "1.15rem", }}>Subtitle Details</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <ButtonGroup variant="contained" orientation='vertical' aria-label="subinfo" sx={{ width: "fit-content", "& .MuiButton-root": { borderColor: "#fff" } }} disableRipple >
                            <Button sx={{ color: darkmode.value ? 'white' : "white", "&:hover": { backgroundColor: "#0b9f9f" }, backgroundColor: "#4b8686", textTransform: "none", fontSize: "0.7rem", py: "0.25rem", px: "0.5rem", fontFamily: 'Regular-Family' }} onClick={togglePreview}>Preview</Button>
                            {user !== null && <Button sx={{ color: darkmode.value ? 'white' : "white", "&:hover": { backgroundColor: "#0b9f9f" }, backgroundColor: "#4b8686", textTransform: "none", fontSize: "0.7rem", py: "0.25rem", px: "0.5rem", fontFamily: 'Regular-Family' }} onClick={toggleReport}>Report</Button>}
                            {isOwned || privs?.includes("5") ? <Button sx={{ color: darkmode.value ? 'white' : "white", "&:hover": { backgroundColor: "#0b9f9f" }, backgroundColor: "#4b8686", textTransform: "none", fontSize: "0.7rem", py: "0.25rem", px: "0.5rem", fontFamily: 'Regular-Family' }} onClick={handleEditClick}>Edit</Button> : ""}
                            {privs?.includes("1") && <Button sx={{ color: darkmode.value ? 'white' : "white", "&:hover": { backgroundColor: "#0b9f9f" }, backgroundColor: "#4b8686", textTransform: "none", fontSize: "0.7rem", py: "0.25rem", px: "0.5rem", fontFamily: 'Regular-Family' }} onClick={toggleMoveSub}>Move</Button>}
                            {privs?.includes("2") && <Button sx={{ color: darkmode.value ? 'white' : "white", "&:hover": { backgroundColor: "#0b9f9f" }, backgroundColor: "#4b8686", textTransform: "none", fontSize: "0.7rem", py: "0.25rem", px: "0.5rem", fontFamily: 'Regular-Family' }} onClick={handleDeleteSub}>Delete</Button>}
                        </ButtonGroup>
                    </Box>
                </Box>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Uploaded: </strong>{moment(details?.uploadDate).format('lll')}&nbsp;&nbsp;({moment(details.uploadDate).fromNow()})</Typography>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Hearing Impaired: </strong>{details?.hi !== undefined ? details?.hi === 1 ? "Yes" : "No" : "N/A"}</Typography>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Foriegn Parts: </strong>{details?.fp !== undefined ? details?.fp === true ? "Yes" : "No" : "N/A"}</Typography>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Files: </strong>{details?.files || 'N/A'}&nbsp;({details?.size ? numberWithCommas(details.size) + " Bytes" : 'N/A'})</Typography>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Production Type: </strong>{details?.pt || "N/A"}</Typography>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Frame Rate: </strong>{details?.fr || "N/A"}</Typography>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Release Type: </strong>{details?.rt || "N/A"}</Typography>
                <Divider sx={{ borderColor: "rgb(0,0,0,0.25)", my: "0.5rem" }} />
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Rated: </strong>{totalRates > 0 ? Math.round((goodRates / totalRates) * 10) + "/10" : "N/A"} From {totalRates > 0 ? <Link underline={'none'} href={"/ratings/" + subId} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/ratings/" + subId) } }}>{totalRates} Users</Link> : `${totalRates} Users`}</Typography>
                {goodRates > 0 ? <>
                    <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Voted as Good By: </strong>{goodRates || "N/A"}</Typography>
                </> : <></>}
                {badRates > 0 ? <>
                    <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Voted as Bad By: </strong>{badRates || "N/A"}</Typography>
                </> : <></>}
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Regular-Family" }}><strong>Downloads: </strong>{details?.downloads || "N/A"}</Typography>
            </Box>
        </Paper>
    )
}

export default SubtitleDetails
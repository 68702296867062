import { Avatar, Box, Divider, Rating, Typography, Button, Paper, Tooltip, Link, Container } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../css/SubtitlePage.css'
import { decode } from 'html-entities';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DownloadIcon from '@mui/icons-material/Download';
import SubtitleComments from '../Components/Subtitle/SubtitleComments'
import SubtitleDetails from '../Components/Subtitle/SubtitleDetails'
import SubtitlePreview from '../Components/Subtitle/SubtitlePreview'
import goodIcon from "../icons/good.png"
import badIcon from "../icons/bad.png"
import { Grid } from 'react-loading-icons'
import { useUserContext } from '../Contexts/userContext'
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import SubtitleMove from '../Components/Subtitle/SubtitleMove'
import useDarkMode from 'use-dark-mode'
import NotFound from './NotFound'
import { CurrentMovieContext } from '../Contexts/currentMovieContext'
import SubtitleReport from '../Components/Reports/SubtitleReport'
import up1Icon from '../icons/up1.webp';
import up2Icon from '../icons/up2.webp';
import up3Icon from '../icons/up3.webp';
import tr1Icon from '../icons/tr1.webp';
import tr2Icon from '../icons/tr2.webp';
import tr3Icon from '../icons/tr3.webp';
import useSnackbar from '../hooks/useSnackbar'

const badge_definition = [
    { badge: 'up1', description: 'Verified Uploader\nMore than 200 subtitles uploaded!', image: up1Icon },
    { badge: 'up2', description: 'Persistent Uploader\nMore than 1000 subtitles uploaded!', image: up2Icon },
    { badge: 'up3', description: 'Legendary Uploader\nMore than 5000 subtitles uploaded!', image: up3Icon },
    { badge: 'tr1', description: 'Verified Translator\nMore than 75 translations & 1 year of practice!', image: tr1Icon },
    { badge: 'tr2', description: 'Distinguished Translator\nMore than 400 translations & 4 years of practice!', image: tr2Icon },
    { badge: 'tr3', description: 'The Ancient One\nMore than 1000 translations & 10 years of practice!', image: tr3Icon },
];
const mainFont = "Regular-Family"
function SubtitlePage() {
    const { showSnackbar } = useSnackbar();
    const darkmode = useDarkMode();
    const navigate = useNavigate();
    const user = useUserContext();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [downloaded, setDownloaded] = useState(false);
    const [data, setData] = useState({});
    const [err, setErr] = useState(false);
    const [previewShow, setPreviewShow] = useState(window.localStorage.getItem('preview') !== undefined ? window.localStorage.getItem('preview') === "1" ? true : false : false);
    const [reportShow, setReportShow] = useState(false);
    const [rated, setRated] = useState(undefined);
    const [comments, setComments] = useState([]);
    const [isHovering, setIsHovering] = useState({});
    const [copied, setCopied] = useState({});
    const [copiedAll, setCopiedAll] = useState(false);
    const [rating, setRating] = useState(false);
    const [moveShow, setMoveShow] = useState(false);
    const [submittingVote, setSubmittingVote] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const { setCurrentMovieCtx } = useContext(CurrentMovieContext)
    const addComment = (comment) => {
        setComments(p => [...p, comment]);
    }
    const togglePreviewShow = () => {
        window.localStorage.setItem("preview", previewShow === true ? "0" : "1");
        setPreviewShow(p => !p);
    }
    const toggleReportShow = () => {
        setReportShow(p => !p);
    }
    const handleRate = (rate) => {
        if (!rating) {
            setRating(true);
            axios.post(process.env.REACT_APP_API_URL + "/api/rateSub", { id: params.id, rate: rate }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setRated(rate)
                } else if (res.data.errMsg) {
                    showSnackbar(res.data.errMsg);
                }
                setRating(false);
            }).catch(err => {
                console.log(err);
                setRating(false);
            })
        }
    }
    const handleRemoveRate = () => {
        if (!rating) {
            setRating(true);
            axios.post(process.env.REACT_APP_API_URL + "/api/rateSub", { id: params.id, remove: true }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setRated(undefined)
                } else if (res.data.errMsg) {
                    showSnackbar(res.data.errMsg);
                }
                setRating(false);
            }).catch(err => {
                setRating(false);
            })
        }
    }
    /* const handleDownload = () => {
        axios.post(process.env.REACT_APP_API_URL + "/api/downloadSub", { id: params.id }, { responseType: "blob", withCredentials: true }).then(res => {
            if (res?.data && user?.id) {
                setDownloaded(true);
            }
            fileDownload(res.data, data.sub.fileName);
        }).catch(err => {
            console.log(err);
        })
    } */
    const handleDownload2 = (e) => {
        e.preventDefault();
        setDownloaded(true);
        console.log({ user })
        window.location.href = process.env.REACT_APP_API_URL + "/api/downloadSub/" + data.sub.downloadToken
    }
    useEffect(() => {
        document.getElementById("preview")?.scrollIntoView();
    }, [previewShow])
    const voteComment = (id, vote) => {
        if (!user) {
            showSnackbar("You need to be logged in.");
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + "/api/subCommentVote", { cmid: id, vote: comments.filter(x => x.id === id)[0].vote === vote ? 0 : vote }, { withCredentials: true }).then(res => {
            console.log({ id, vote })
            var _newComments = comments.map(cm => {
                if (cm.id === id) {
                    cm.vote === vote ? cm.votes -= vote : cm.votes += vote - cm.vote;
                    cm.vote = cm.vote === vote ? 0 : vote;
                }
                return cm;
            })
            setComments(_newComments);
            setSubmittingVote(false);
        }).catch(err => {
            console.log(err);
        })
    }
    useEffect(() => {
        const fetchSub = () => {
            window.scrollTo(0, 0);
            axios.post(process.env.REACT_APP_API_URL + "/api/getSub", { movie: params?.movie.toLowerCase(), lang: params?.lang?.toLowerCase(), id: params.id }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setData(res.data);
                    setDownloaded(res.data.downloaded || false);
                    setComments(res.data.comments);
                    setLoading(false);
                    if (user) {
                        setCurrentMovieCtx(res?.data?.movie?.id);
                    }
                    if (res?.data?.sub?.userRated !== undefined) {
                        setRated(res?.data?.sub?.userRated === false ? 'bad' : 'good')
                    }
                    document.title = `Download ${res.data.sub.lang} Subtitle For ${res.data.movie.title}`
                } else {
                    setLoading(false);
                }
            }).catch(err => {
                if (err?.response?.status === 404) {
                    setNotFound(true)
                }
                setLoading(false);
                setErr(true);
                console.log({ err });
            })
        }
        fetchSub();
        // eslint-disable-next-line
    }, []);
    const handleMouseEnterRI = (index) => {
        setIsHovering(p => ({ ...p, [index]: true }))
    }
    const handleMouseLeaveRI = (index) => {
        setIsHovering(p => ({ ...p, [index]: false }));
        setCopied(p => ({ ...p, [index]: false }))
    }
    const handleClickedRI = (index) => {
        setCopied(p => ({ ...p, [index]: true }));
        navigator.clipboard.writeText(data.sub.ri[index]);
    }
    const handleCopyAll = () => {
        var text = "";
        data.sub.ri.forEach(info => {
            text += `${info}\n`;
        });
        navigator.clipboard.writeText(text);
        setCopiedAll(true);
        setTimeout(() => {
            setCopiedAll(false);
        }, 1000);
    }
    const toggleMoveSub = () => {
        setMoveShow(p => !p);
    }
    if (notFound) {
        return (
            <NotFound />
        )
    }
    return (
        err ?
            <>
                Error...
            </> : !loading ?
                <>

                    <Box sx={{ marginTop: "1rem", overflow: "auto" }}>
                        <Container maxWidth="xl" sx={{ width: "100%" }}>
                            <Link sx={{ display: "contents" }} href={data.movie.movieLink} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(data.movie.movieLink) } }} >
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer", width: "fit-content" }} >
                                    <Paper sx={{ width: "fit-content", py: "0.25rem", px: "0.25rem", m: "0.25rem", backgroundColor: "rgb(255,255,255,0.6)", transform: "scale(0.75)" }}><ArrowBackIosNewIcon sx={{ fontSize: "0.75rem", color: "black" }} /></Paper>
                                    <Typography fontFamily={mainFont} fontWeight={"bold"} fontSize={"1rem"} sx={{ lineHeight: "unset", color: darkmode.value ? "white" : 'initial' }}> {"Back To Subtitle List"}</Typography>
                                </Box>
                            </Link>

                            <Divider sx={{ borderColor: "black" }} />
                            <Box sx={{ display: "flex", marginTop: "1.25rem", flexDirection: "row" }}>
                                <Box sx={{ width: "max-content" }}>
                                    <img onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "https://api.subsource.net/notfound.jpg";
                                    }} alt={data.movie.title} src={data.movie.poster} width={'220px'} style={{ height: "330px", borderRadius: "0.5rem" }} />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", ml: "0.5rem" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Link sx={{ display: "contents" }} href={data.movie.movieLink} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(data.movie.movieLink) } }} >
                                            <Typography variant={'h6'} fontFamily={mainFont} fontWeight={"bold"} sx={{ marginTop: "-5px", marginLeft: "0.5rem", color: darkmode.value ? "white" : 'initial' }}>{data.movie.title}</Typography>
                                        </Link>
                                        <Box sx={{ marginLeft: "0.25rem", height: "100%", mt: "-3px", display: "flex", alignItems: "flex-end" }}>
                                            <a href={data?.movie?.imdbLink?.startsWith('tt') ? 'https://www.imdb.com/title/' + data?.movie?.imdbLink : data?.movie?.imdbLink} target='_blank' rel="noreferrer"><Avatar children={<img alt='IMDb' src="https://upload.wikimedia.org/wikipedia/commons/4/46/Imdb_logo_rounded_corners.png" width={'25px'} />} sx={{ backgroundColor: "gold", m: "0.25rem", width: "25px", height: "25px", boxShadow: "0px 3px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" }} /></a>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mt: "0px", ml: "0.6rem" }}>
                                        <Box >
                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "465px" }}>
                                                <Typography color="initial" fontFamily={mainFont} fontWeight={'bold'} fontSize={'0.75rem'} sx={{ color: darkmode.value ? "white" : 'initial' }}>Release Info:</Typography>
                                                <Button onClick={handleCopyAll} sx={{ fontSize: "0.65rem", padding: "0px", px: "0.25rem", textTransform: "none", fontFamily: "Regular-Family", fontWeight: "bold", color: darkmode.value ? "white" : 'initial' }} variant='outlined' color='inherit' endIcon={<CopyAllIcon />}>{copiedAll ? "Copied!" : "Copy All"}</Button>
                                            </Box>
                                            {data.sub.ri.map((info, index) => (
                                                <Tooltip key={index} title={info} arrow placement="top-start" >
                                                    <Paper sx={{ py: "0.25rem", my: "0.25rem", width: "450px", px: "0.5rem", backgroundColor: darkmode.value ? "rgb(255,255,255,0.2)" : "rgb(255,255,255,0.6)", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap", display: "flex", justifyContent: "space-between", cursor: "pointer" }} onMouseEnter={() => { handleMouseEnterRI(index) }} onMouseLeave={() => { handleMouseLeaveRI(index) }} onClick={() => { handleClickedRI(index) }}>
                                                        <Typography color={darkmode.value ? "white" : 'initial'} fontFamily={mainFont} fontSize={'0.75rem'} sx={{ lineHeight: "1rem", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "435px" }} >{info}</Typography>
                                                        {copied[index] === true ? <DoneIcon sx={{ fontSize: "0.75rem" }} /> :
                                                            <ContentCopyIcon sx={{ fontSize: "0.75rem", visibility: isHovering[index] !== undefined && isHovering[index] ? 'visible' : 'hidden' }} />
                                                        }
                                                    </Paper>
                                                </Tooltip>
                                            ))}
                                        </Box>
                                        <Typography fontFamily={mainFont} fontSize={'0.55rem'} sx={{ mt: "1rem", color: darkmode.value ? "white" : 'initial' }}>A Commentary By:</Typography>
                                        <Box sx={{ display: "flex", width: "max-content", textDecoration: "none", flexDirection: "column" }}>
                                            {/* <Box sx={{ display: "flex" }}>
                                                <Link sx={{ display: "contents", flexDirection: "row", textDecoration: "none", "&:visited": { color: darkmode.value ? "#a561e3" : "#551A8B" } }} href={"/u/" + data.user.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + data.user.id) } }}>
                                                    <Tooltip title={data.user.username} placement='top'>
                                                        <Typography fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ width: "145px", marginBottom: "0.40rem", paddingBottom: "0.15rem", boxShadow: darkmode.value ? "0px 1px 1px rgb(255,255,255,0.25)" : "0px 1px 1px rgb(0,0,0,0.25)", overflow: "hidden", textOverflow: "ellipsis" }}>{data.user.username}</Typography>
                                                    </Tooltip>
                                                    <Rating value={data.user.rating} max={5} precision={0.5} readOnly sx={{ display: data.user.rating === -1 ? 'none' : 'flex', marginLeft: "10px", marginBottom: "0.40rem", alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>
                                                </Link>
                                                <Link sx={{ display: "contents", flexDirection: "row", textDecoration: "none", "&:visited": { color: darkmode.value ? "#a561e3" : "#551A8B" } }} href={"/u/" + data.user.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + data.user.id) } }}>
                                                    <Tooltip title={data.user.username} placement='top'>
                                                        <Typography fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ width: "100%", marginBottom: "0.40rem", paddingBottom: "0.15rem", overflow: "hidden", textOverflow: "ellipsis" }}>{data.user.username}</Typography>
                                                    </Tooltip>
                                                    <Rating value={data.user.rating} max={5} precision={0.5} readOnly sx={{ display: data.user.rating === -1 ? 'none' : 'flex', marginLeft: "10px", marginBottom: "0.40rem", alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>
                                                </Link>
                                                <Tooltip title="Support Your TransRegular-Familyr" placement='top' sx={{}}>
                                                    <IconButton sx={{ marginLeft: "0.5rem", paddingTop: "0.25rem", display: "none" }} children={<img alt='donate' src={donateIcon} width={"17px"} />} />
                                                </Tooltip>
                                            </Box> */}
                                            <Box sx={{ display: "flex", width: "465px", alignItems: "center", height: "50px" }}>
                                                {data.user.badges.map((badgeId) => {
                                                    const badge = badge_definition.find((badge) => badge.badge === badgeId);
                                                    if (badge) {
                                                        return (
                                                            <Box sx={{ display: "flex", mr: "0.5rem", bgcolor: "white", boxShadow: "0 4px 11px 0 #252c6126,0 1px 3px 0 #5d649433", borderRadius: "9999px" }}>
                                                                <Tooltip key={badge.badge} title={<span style={{ whiteSpace: 'pre-line', textAlign: "center", display: "inline-block" }}>{badge.description}</span>} arrow placement='top'>
                                                                    <img src={badge.image} alt={badge.badge} width="30px" height="auto" />
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Link sx={{ display: "contents", flexDirection: "row", textDecoration: "none", "&:visited": { color: darkmode.value ? "#a561e3" : "#551A8B" } }} href={"/u/" + data.user.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + data.user.id) } }}>
                                                    <Tooltip title={data.user.username} placement='top'>
                                                        <Typography fontSize={"0.9rem"} fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ mt: "5px", paddingBottom: "0.15rem", overflow: "hidden", textOverflow: "ellipsis" }}>{data.user.username}</Typography>
                                                    </Tooltip>
                                                    <Rating value={data.user.rating} max={5} precision={0.5} readOnly sx={{ display: data.user.rating === -1 ? 'none' : 'flex', marginLeft: "10px", mt: "3px", marginBottom: "0.20rem", alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>
                                                </Link>
                                            </Box>
                                            <Divider orientation='horizontal' flexItem sx={{ borderColor: darkmode.value ? "rgb(255,255,255,0.25) !important" : "rgb(0,0,0,0.25) !important" }} />
                                            {data.sub.contributors.map(c => (
                                                <>
                                                    <Box sx={{ display: "flex", width: "465px", alignItems: "center", height: "50px" }}>
                                                        {c.badges.map((badgeId) => {
                                                            const badge = badge_definition.find((badge) => badge.badge === badgeId);
                                                            if (badge) {
                                                                return (
                                                                    <Box sx={{ display: "flex", mr: "0.5rem", bgcolor: "white", boxShadow: "0 4px 11px 0 #252c6126,0 1px 3px 0 #5d649433", borderRadius: "9999px" }}>
                                                                        <Tooltip key={badge.badge} title={badge.description} arrow placement='top'>
                                                                            <img src={badge.image} alt={badge.badge} width="30px" height="auto" />
                                                                        </Tooltip>
                                                                    </Box>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Link sx={{ display: "contents", flexDirection: "row", textDecoration: "none", "&:visited": { color: darkmode.value ? "#a561e3" : "#551A8B" } }} href={"/u/" + c.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + c.id) } }}>
                                                            <Tooltip title={c.username} placement='top'>
                                                                <Typography fontSize={"0.9rem"} fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ mt: "5px", paddingBottom: "0.15rem", overflow: "hidden", textOverflow: "ellipsis" }}>{c.username}</Typography>
                                                            </Tooltip>
                                                            <Rating value={c.rating} max={5} precision={0.5} readOnly sx={{ display: c.rating === -1 ? 'none' : 'flex', marginLeft: "10px", mt: "3px", marginBottom: "0.20rem", alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>
                                                        </Link>
                                                    </Box>
                                                    <Divider orientation='horizontal' flexItem sx={{ borderColor: darkmode.value ? "rgb(255,255,255,0.25) !important" : "rgb(0,0,0,0.25) !important" }} />
                                                </>
                                            ))}
                                        </Box>

                                        <Box sx={{ height: "fit-content", width: "417px", backgroundColor: darkmode.value ? "#434343" : "#f8f1df", py: "1.5rem", px: "1.5rem", boxShadow: darkmode.value ? "inset 1px 1px 10px 5px #181818" : "inset 1px 1px 10px 5px #d9ceb4", marginTop: "1.5rem" }}>
                                            <Typography sx={{ color: darkmode.value ? 'white' : "black", maxWidth: "450px", ml: "0.75rem", whiteSpace: "no-wrap", maxHeight: "80px", wordBreak: "break-word", textOverflow: "ellipsis", overflow: 'hidden', fontSize: "0.85rem", fontFamily: "Regular-Family" }}>{decode(data.sub.commentary)}</Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", my: "1.5rem" }}>
                                            <Box sx={{ marginRight: "0.5rem", display: "none" }}>
                                                <Button disableRipple variant="contained" color="success" sx={{ background: "linear-gradient(#49bd4e,#2e7d32)", height: "fit-content", minWidth: "35px", textTransform: "none", padding: '0px', py: "0.35rem" }}>
                                                    <Typography fontFamily={mainFont} >10</Typography>
                                                </Button>
                                            </Box>
                                            <Box sx={{ marginRight: "1rem" }}>
                                                {/* <Button onClick={handleDownload} variant="contained" color="success" sx={{ background: "linear-gradient(#49bd4e,#2e7d32)", height: "fit-content", width: "fit-content", textTransform: "none", padding: '0px', py: "0.35rem", paddingLeft: "1rem", paddingRight: "1.2rem" }}>
                                                    <DownloadIcon sx={{ marginRight: "0.35rem" }} />
                                                    <Typography fontFamily={mainFont} > Downloasss {data.sub.lang} Subtitle</Typography>
                                                </Button> */}
                                                <Button href={process.env.REACT_APP_API_URL + "/api/downloadSub/" + data.sub.downloadToken} onClick={handleDownload2} variant="contained" color="success" sx={{ background: "linear-gradient(#49bd4e,#2e7d32)", height: "fit-content", width: "fit-content", textTransform: "none", padding: '0px', py: "0.35rem", paddingLeft: "1rem", paddingRight: "1.2rem" }}>
                                                    <DownloadIcon sx={{ marginRight: "0.35rem" }} />
                                                    <Typography fontFamily={mainFont} > Download {data.sub.lang} Subtitle</Typography>
                                                </Button>
                                            </Box>
                                            <Box sx={{ display: downloaded && user ? "contents" : 'none' }}>
                                                {rated === undefined ? <>
                                                    <Box sx={{ marginRight: "0.1rem" }}>
                                                        <Button onClick={() => { handleRate('good') }} disableRipple variant="contained" color="success" sx={{ color: darkmode.value ? "white" : 'initial', bgcolor: "#808080b3", height: "100%", width: "fit-content", textTransform: "none", padding: '0px', py: "0.35rem", borderRadius: "0px", borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px", "&:hover": { backgroundColor: "#3ca141" } }}>
                                                            <img alt='good' src={goodIcon} width={'20px'} style={{ position: "absolute", top: "0.4rem" }} />
                                                            <Typography fontFamily={mainFont} sx={{ fontSize: "0.55rem", position: "absolute", top: "1.2rem" }}>Good</Typography>
                                                        </Button>
                                                    </Box>
                                                    <Box sx={{ marginRight: "0.1rem" }}>
                                                        <Button
                                                            onClick={() => { handleRate('bad') }}
                                                            fullWidth
                                                            disableRipple
                                                            variant="contained"
                                                            color="success"
                                                            sx={{ color: darkmode.value ? "white" : 'initial', bgcolor: "#808080b3", height: "100%", width: "60px", textTransform: "none", padding: '0px', py: "0.35rem", borderRadius: "0px", borderTopRightRadius: "15px", borderBottomRightRadius: "15px", "&:hover": { backgroundColor: "#ce2424" } }}
                                                        >
                                                            <img alt='bad' src={badIcon} width={'20px'} style={{ position: "absolute", top: "0.4rem" }} />
                                                            <Typography fontFamily={mainFont} sx={{ fontSize: "0.55rem", position: "absolute", top: "1.2rem" }}>Bad</Typography>
                                                        </Button>
                                                    </Box></>
                                                    : rated === 'good' ?
                                                        <><Box sx={{ marginRight: "0.1rem" }}>
                                                            <Button
                                                                disableRipple
                                                                variant="contained"
                                                                color="success"
                                                                sx={{
                                                                    bgcolor: "#808080b3",
                                                                    height: "100%",
                                                                    width: "fit-content",
                                                                    textTransform: "none",
                                                                    padding: '0px',
                                                                    py: "0.35rem",
                                                                    borderRadius: "0px",
                                                                    borderTopLeftRadius: "15px",
                                                                    borderBottomLeftRadius: "15px",
                                                                    backgroundColor: "#2e7d32",
                                                                    color: "white",
                                                                    boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                                                    "&:hover": {
                                                                        boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                                                        backgroundColor: "#2e7d32"
                                                                    }
                                                                }}
                                                                onClick={handleRemoveRate}>
                                                                <img alt='good' src={goodIcon} width={'20px'} style={{ position: "absolute", top: "0.4rem" }} />
                                                                <Typography fontFamily={mainFont} sx={{ fontSize: "0.55rem", position: "absolute", top: "1.2rem", color: darkmode.value ? "white" : 'initial' }}>Good</Typography>
                                                            </Button>
                                                        </Box>
                                                            <Box sx={{ marginRight: "0.1rem" }}>
                                                                <Button
                                                                    fullWidth
                                                                    disableRipple
                                                                    variant="contained"
                                                                    color="success"
                                                                    disabled
                                                                    sx={{
                                                                        bgcolor: "#808080b3",
                                                                        height: "100%",
                                                                        width: "60px",
                                                                        textTransform: "none",
                                                                        padding: '0px',
                                                                        py: "0.35rem",
                                                                        borderRadius: "0px",
                                                                        borderTopRightRadius: "15px",
                                                                        borderBottomRightRadius: "15px",
                                                                        "&:hover": { backgroundColor: "darkred" },
                                                                    }}
                                                                >
                                                                    <img alt='bad' src={badIcon} width={'20px'} style={{ position: "absolute", top: "0.4rem" }} />
                                                                    <Typography fontFamily={mainFont} sx={{ fontSize: "0.55rem", position: "absolute", top: "1.2rem", color: darkmode.value ? "white" : 'initial' }}>Bad</Typography>
                                                                </Button>
                                                            </Box></>
                                                        :
                                                        <><Box sx={{ marginRight: "0.1rem" }}>
                                                            <Button
                                                                disabled
                                                                disableRipple
                                                                variant="contained"
                                                                color="success"
                                                                sx={{
                                                                    bgcolor: "#808080b3",
                                                                    height: "100%",
                                                                    width: "fit-content",
                                                                    textTransform: "none",
                                                                    padding: '0px',
                                                                    py: "0.35rem",
                                                                    borderRadius: "0px",
                                                                    borderTopLeftRadius: "15px",
                                                                    borderBottomLeftRadius: "15px",
                                                                }}
                                                                onClick={handleRemoveRate}
                                                            >
                                                                <img alt='good' src={goodIcon} width={'20px'} style={{ position: "absolute", top: "0.4rem" }} />
                                                                <Typography fontFamily={mainFont} sx={{ fontSize: "0.55rem", position: "absolute", top: "1.2rem", color: darkmode.value ? "white" : 'initial' }}>Good</Typography>
                                                            </Button>
                                                        </Box>
                                                            <Box sx={{ marginRight: "0.1rem" }}>
                                                                <Button
                                                                    fullWidth
                                                                    disableRipple
                                                                    variant="contained"
                                                                    color="success"
                                                                    sx={{
                                                                        bgcolor: "#808080b3",
                                                                        height: "100%",
                                                                        width: "60px",
                                                                        textTransform: "none",
                                                                        padding: '0px',
                                                                        py: "0.35rem",
                                                                        borderRadius: "0px",
                                                                        borderTopRightRadius: "15px",
                                                                        borderBottomRightRadius: "15px",
                                                                        backgroundColor: "#aa0b0b",
                                                                        color: "white",
                                                                        boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                                                        "&:hover": {
                                                                            boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                                                            backgroundColor: "#aa0b0b"
                                                                        }
                                                                    }}
                                                                    onClick={handleRemoveRate}
                                                                >
                                                                    <img alt='bad' src={badIcon} width={'20px'} style={{ position: "absolute", top: "0.4rem" }} />
                                                                    <Typography fontFamily={mainFont} sx={{ fontSize: "0.55rem", position: "absolute", top: "1.2rem", color: darkmode.value ? "white" : 'initial' }}>Bad</Typography>
                                                                </Button>
                                                            </Box></>
                                                }
                                            </Box>
                                        </Box>
                                        <SubtitleReport show={reportShow} toggleShow={toggleReportShow} subId={data.sub.id} />
                                        <SubtitleMove show={moveShow} toggleShow={toggleMoveSub} subId={data.sub.id} />
                                        <SubtitleDetails rated={rated} details={data.sub} togglePreview={togglePreviewShow} toggleReport={toggleReportShow} isOwned={user?.id === data.user.id} privs={user?.privileges} subId={data.sub.id} toggleMoveSub={toggleMoveSub} />
                                        <SubtitlePreview preview={data.sub.preview} show={previewShow} toggleShow={togglePreviewShow} isNew={data.sub.userUploaded} />
                                    </Box>
                                </Box>
                                <SubtitleComments comments={comments} addComment={addComment} voteComment={voteComment} subData={data} submittingVote={submittingVote} setSubmittingVote={setSubmittingVote} />
                            </Box>
                        </Container>
                    </Box >
                </>
                :
                <>
                    <Box sx={{ height: "800px", display: "flex", justifyContent: "center", alignItems: "center", color: "black" }}>
                        <Grid fill={darkmode.value ? "white" : 'black'} />
                    </Box>
                </>
    )
}

export default SubtitlePage
import { Box, Divider, IconButton, Typography, Select, MenuItem, FormControl, Button, Avatar } from '@mui/material'
import React, { useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ModeIcon from '@mui/icons-material/Mode';
import SaveIcon from '@mui/icons-material/Save';
/* import DashboardProfilePhoneModal from './DashboardProfilePhoneModal'; */
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '../../../icons/telegram.svg';
import TelegramIconColored from '../../../icons/telegramColored.svg';
import DashboardImportProfileModa from './DashboardImportProfileModal';
import InstagramColored from '../../../icons/instagram.svg'
import TwitterColored from '../../../icons/twitterColor.svg'
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import FacebookColored from '../../../icons/facebookColored2.svg';
import moment from 'moment';
import DashboardSocialMediaModal from './DashboardSocialMediaModal';
import DashboardChangeProfileModal from './DashboardChangeProfileModal';
import useDarkMode from 'use-dark-mode';
import { useUserContext } from '../../../Contexts/userContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UserApi } from '../../../api/userApi';
import { useNavigate } from 'react-router-dom';
import useSnackbar from '../../../hooks/useSnackbar';
const RegularFont = 'Regular-Family';
const BoldFont = 'Regular-Family';
const countries = {
    "Afghanistan": 0,
    "Åland Islands": 1,
    "Albania": 2,
    "Algeria": 3,
    "American Samoa": 4,
    "AndorrA": 5,
    "Angola": 6,
    "Anguilla": 7,
    "Antarctica": 8,
    "Antigua and Barbuda": 9,
    "Argentina": 10,
    "Armenia": 11,
    "Aruba": 12,
    "Australia": 13,
    "Austria": 14,
    "Azerbaijan": 15,
    "Bahamas": 16,
    "Bahrain": 17,
    "Bangladesh": 18,
    "Barbados": 19,
    "Belarus": 20,
    "Belgium": 21,
    "Belize": 22,
    "Benin": 23,
    "Bermuda": 24,
    "Bhutan": 25,
    "Bolivia": 26,
    "Bosnia and Herzegovina": 27,
    "Botswana": 28,
    "Bouvet Island": 29,
    "Brazil": 30,
    "British Indian Ocean Territory": 31,
    "Brunei Darussalam": 32,
    "Bulgaria": 33,
    "Burkina Faso": 34,
    "Burundi": 35,
    "Cambodia": 36,
    "Cameroon": 37,
    "Canada": 38,
    "Cape Verde": 39,
    "Cayman Islands": 40,
    "Central African Republic": 41,
    "Chad": 42,
    "Chile": 43,
    "China": 44,
    "Christmas Island": 45,
    "Cocos (Keeling) Islands": 46,
    "Colombia": 47,
    "Comoros": 48,
    "Congo": 49,
    "Congo, The Democratic Republic of the": 50,
    "Cook Islands": 51,
    "Costa Rica": 52,
    "Cote D'Ivoire": 53,
    "Croatia": 54,
    "Cuba": 55,
    "Cyprus": 56,
    "Czech Republic": 57,
    "Denmark": 58,
    "Djibouti": 59,
    "Dominica": 60,
    "Dominican Republic": 61,
    "Ecuador": 62,
    "Egypt": 63,
    "El Salvador": 64,
    "Equatorial Guinea": 65,
    "Eritrea": 66,
    "Estonia": 67,
    "Ethiopia": 68,
    "Falkland Islands (Malvinas)": 69,
    "Faroe Islands": 70,
    "Fiji": 71,
    "Finland": 72,
    "France": 73,
    "French Guiana": 74,
    "French Polynesia": 75,
    "French Southern Territories": 76,
    "Gabon": 77,
    "Gambia": 78,
    "Georgia": 79,
    "Germany": 80,
    "Ghana": 81,
    "Gibraltar": 82,
    "Greece": 83,
    "Greenland": 84,
    "Grenada": 85,
    "Guadeloupe": 86,
    "Guam": 87,
    "Guatemala": 88,
    "Guernsey": 89,
    "Guinea": 90,
    "Guinea-Bissau": 91,
    "Guyana": 92,
    "Haiti": 93,
    "Heard Island and Mcdonald Islands": 94,
    "Holy See (Vatican City State)": 95,
    "Honduras": 96,
    "Hong Kong": 97,
    "Hungary": 98,
    "Iceland": 99,
    "India": 100,
    "Indonesia": 101,
    "Iran, Islamic Republic Of": 102,
    "Iraq": 103,
    "Ireland": 104,
    "Isle of Man": 105,
    "Israel": 106,
    "Italy": 107,
    "Jamaica": 108,
    "Japan": 109,
    "Jersey": 110,
    "Jordan": 111,
    "Kazakhstan": 112,
    "Kenya": 113,
    "Kiribati": 114,
    "Korea, Democratic People'S Republic of": 115,
    "Korea, Republic of": 116,
    "Kuwait": 117,
    "Kyrgyzstan": 118,
    "Lao People'S Democratic Republic": 119,
    "Latvia": 120,
    "Lebanon": 121,
    "Lesotho": 122,
    "Liberia": 123,
    "Libyan Arab Jamahiriya": 124,
    "Liechtenstein": 125,
    "Lithuania": 126,
    "Luxembourg": 127,
    "Macao": 128,
    "Macedonia, The Former Yugoslav Republic of": 129,
    "Madagascar": 130,
    "Malawi": 131,
    "Malaysia": 132,
    "Maldives": 133,
    "Mali": 134,
    "Malta": 135,
    "Marshall Islands": 136,
    "Martinique": 137,
    "Mauritania": 138,
    "Mauritius": 139,
    "Mayotte": 140,
    "Mexico": 141,
    "Micronesia, Federated States of": 142,
    "Moldova, Republic of": 143,
    "Monaco": 144,
    "Mongolia": 145,
    "Montserrat": 146,
    "Morocco": 147,
    "Mozambique": 148,
    "Myanmar": 149,
    "Namibia": 150,
    "Nauru": 151,
    "Nepal": 152,
    "Netherlands": 153,
    "Netherlands Antilles": 154,
    "New Caledonia": 155,
    "New Zealand": 156,
    "Nicaragua": 157,
    "Niger": 158,
    "Nigeria": 159,
    "Niue": 160,
    "Norfolk Island": 161,
    "Northern Mariana Islands": 162,
    "Norway": 163,
    "Oman": 164,
    "Pakistan": 165,
    "Palau": 166,
    "Palestinian Territory, Occupied": 167,
    "Panama": 168,
    "Papua New Guinea": 169,
    "Paraguay": 170,
    "Peru": 171,
    "Philippines": 172,
    "Pitcairn": 173,
    "Poland": 174,
    "Portugal": 175,
    "Puerto Rico": 176,
    "Qatar": 177,
    "Reunion": 178,
    "Romania": 179,
    "Russian Federation": 180,
    "RWANDA": 181,
    "Saint Helena": 182,
    "Saint Kitts and Nevis": 183,
    "Saint Lucia": 184,
    "Saint Pierre and Miquelon": 185,
    "Saint Vincent and the Grenadines": 186,
    "Samoa": 187,
    "San Marino": 188,
    "Sao Tome and Principe": 189,
    "Saudi Arabia": 190,
    "Senegal": 191,
    "Serbia and Montenegro": 192,
    "Seychelles": 193,
    "Sierra Leone": 194,
    "Singapore": 195,
    "Slovakia": 196,
    "Slovenia": 197,
    "Solomon Islands": 198,
    "Somalia": 199,
    "South Africa": 200,
    "South Georgia and the South Sandwich Islands": 201,
    "Spain": 202,
    "Sri Lanka": 203,
    "Sudan": 204,
    "Suriname": 205,
    "Svalbard and Jan Mayen": 206,
    "Swaziland": 207,
    "Sweden": 208,
    "Switzerland": 209,
    "Syrian Arab Republic": 210,
    "Taiwan, Province of China": 211,
    "Tajikistan": 212,
    "Tanzania, United Republic of": 213,
    "Thailand": 214,
    "Timor-Leste": 215,
    "Togo": 216,
    "Tokelau": 217,
    "Tonga": 218,
    "Trinidad and Tobago": 219,
    "Tunisia": 220,
    "Turkey": 221,
    "Turkmenistan": 222,
    "Turks and Caicos Islands": 223,
    "Tuvalu": 224,
    "Uganda": 225,
    "Ukraine": 226,
    "United Arab Emirates": 227,
    "United Kingdom": 228,
    "United States": 229,
    "United States Minor Outlying Islands": 230,
    "Uruguay": 231,
    "Uzbekistan": 232,
    "Vanuatu": 233,
    "Venezuela": 234,
    "Viet Nam": 235,
    "Virgin Islands, British": 236,
    "Virgin Islands, U.S.": 237,
    "Wallis and Futuna": 238,
    "Western Sahara": 239,
    "Yemen": 240,
    "Zambia": 241,
    "Zimbabwe": 242
}
const lastSeens = [
    'Everyone',
    'No One'
]
function DashboardProfile() {
    const { showSnackbar } = useSnackbar();
    const darkmode = useDarkMode();
    const user = useUserContext();
    const navigate = useNavigate();
    const [editingCountry, setEditingCountry] = useState(false);
    const [newCountry, setNewCountry] = useState(countries[user.country]);
    /* const [showPhoneModal, setShowPhoneModal] = useState(false); */
    const [showImportProfileModal, setShowImportProfileModal] = useState(false);
    const [showSocialMediaModal, setShowSocialMediaModal] = useState(false);
    const [showChangeProfileModal, setShowChangeProfileModal] = useState(false);
    const [socialMediaClicked, setSocialMediaClicked] = useState('');
    const [currentSocialMedia, setCurrentSocialMedia] = useState('');
    const [lastSeen, setLastSeen] = useState(user.lastSeenStatus ? 0 : 1);
    const [editingLastSeen, setEditingLastseen] = useState(false);
    const queryClient = useQueryClient();
    const { refetch: refetchCountry, isFetching: isFetchingCountry } = useQuery({
        queryKey: ["changeCountry"],
        staleTime: 0,
        gcTime: 0,
        enabled: false,
        queryFn: async () => {
            try {
                const result = await UserApi.changeCountry(Object.keys(countries).find(key => countries[key] === newCountry));
                if (result) {
                    if (result?.success === true) {
                        queryClient.invalidateQueries({
                            queryKey: ['getUser'],
                        });
                    }
                    setEditingCountry(false);
                }
                return;
            } catch (error) {
                setEditingLastseen(false);
                showSnackbar("Server error.")
            }
        },
    })
    const { refetch: refetchLastseen, isFetching: isFetchingLastSeen } = useQuery({
        queryKey: ["changeLastseen"],
        staleTime: 0,
        gcTime: 0,
        enabled: false,
        queryFn: async () => {
            try {
                await UserApi.changeLastSeenStatus(!lastSeen);
                queryClient.invalidateQueries({
                    queryKey: ['getUser'],
                });
                setEditingLastseen(false);
                return;
            } catch (error) {
                setEditingLastseen(false);
                showSnackbar("Server error.")
            }
        },
    })
    const handleEditCountryBtnClick = () => {
        setEditingCountry(!editingCountry);
        setNewCountry(countries[user.country])
    }
    const handleEditLastSeen = () => {
        setEditingLastseen(!editingLastSeen);
    }
    const handleSelectCountryChange = (e) => {
        setNewCountry(e.target.value)
    }
    const handleSelectLastseenChange = (e) => {
        console.log(e.target.value)
        setLastSeen(e.target.value)
    }
    const handleSaveCountry = () => {
        refetchCountry();
    }
    const handleSaveLastSeen = () => {
        refetchLastseen();
    }
    const toggleChangeProfileModal = () => {
        setShowChangeProfileModal(p => !p)
    }
    const toggleImportModal = (state = null) => {
        if (state) {
            setShowImportProfileModal(state)
        } else {
            setShowImportProfileModal(p => !p)
        }
    }
    const toggleSocialMediaModal = () => {
        setShowSocialMediaModal(p => !p)
    }
    const handleSocialMediaClicked = (e) => {
        setCurrentSocialMedia(e.target.value);
        setSocialMediaClicked(parseInt(e.target.name))
        toggleSocialMediaModal();
    }
    return (
        <>
            {/* <DashboardProfilePhoneModal show={showPhoneModal} toggleShow={togglePhoneModal} /> */}
            <DashboardChangeProfileModal show={showChangeProfileModal} toggleShow={toggleChangeProfileModal} />
            <DashboardImportProfileModa show={showImportProfileModal} toggleShow={toggleImportModal} />
            <DashboardSocialMediaModal show={showSocialMediaModal} toggleShow={toggleSocialMediaModal} socialMedia={socialMediaClicked} currentSocialMedia={currentSocialMedia} />
            <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", py: "5rem", minWidth: "max-content", height: "35rem", minHeight: "80vh" }}>
                <Box sx={{ display: "flex", boxShadow: "inset #009688 0 0 0 5px,inset #059c8e 0 0 0 1px,inset #0cab9c 0 0 0 10px,inset #1fbdae 0 0 0 11px,inset #8ce9ff 0 0 0 16px,inset #48e4d6 0 0 0 17px,inset #e5f9f7 0 0 0 21px,inset #bfecf7 0 0 0 22px", padding: "25px", height: "min-content" }}>
                    <Box sx={{ height: "auto", backgroundColor: "teal", width: "225px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", py: "3rem", px: "0.5rem" }}>
                            <Avatar sx={{ width: "6rem", height: "6rem", alignSelf: "center", mb: "2rem" }} children={user.avatar ? "" : <AccountCircleIcon sx={{ width: "6rem", height: "6rem" }} />} key={Date.now()} src={user.avatar ? "https://api.subsource.net" + user.avatar : ""} alt={user.username} />
                            <Typography fontFamily={BoldFont} textAlign={'center'} fontSize={'1.4rem'} color={darkmode.value ? "white" : "initial"}>{user.username}</Typography>
                            <Typography sx={{ whiteSpace: "break-spaces" }} fontFamily={RegularFont} textAlign={'center'} fontSize={'1rem'} color={darkmode.value ? "white" : "initial"}>{user.bio || "No bio is set."}</Typography>
                            <IconButton sx={{ marginTop: "1rem" }} children={<ModeIcon sx={{ fontSize: "1.5rem" }} />} disableRipple disableFocusRipple onClick={toggleChangeProfileModal} />
                        </Box>
                    </Box>
                    <Box sx={{ width: "37rem", height: "auto", backgroundColor: "black" }}>
                        <Box sx={{ padding: "1.5rem", color: "floralwhite" }} >
                            <Typography fontFamily={BoldFont} fontSize={'1.3rem'}>Informations</Typography>
                            <Divider sx={{ borderColor: "rgb(255 255 255 / 20%)" }} />
                            <Box sx={{ py: "1.5rem", display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginBottom: "0.25rem" }}>
                                <Box sx={{ width: "70%" }} >
                                    <Typography fontFamily={BoldFont} fontSize={'1.3rem'}>Email</Typography>
                                    <Typography fontFamily={BoldFont} fontSize={user?.email?.length > 25 || undefined ? '1.1rem' : "1.3rem"} sx={{ opacity: "60%" }}>{user.email}</Typography>
                                </Box>

                                <Box sx={{ width: "30%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography fontFamily={BoldFont} fontSize={'1.3rem'}>Phone </Typography>
                                        {/* {<IconButton children={<ModeIcon sx={{ fontSize: "1.1rem", color: "floralwhite" }} />} onClick={togglePhoneModal} />} */}
                                    </Box>

                                    <Typography fontFamily={BoldFont} fontSize={'1.3rem'} sx={{ opacity: "60%" }}>{user.country === 'Iran, Islamic Republic Of' ? user.phone ? user.phone : "Not Set" : "N/A"}</Typography>
                                </Box>

                                <Box sx={{ width: "70%", marginTop: "1.5rem" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography fontFamily={BoldFont} fontSize={'1.3rem'}>Country </Typography>
                                        {<IconButton children={<ModeIcon sx={{ fontSize: "1.1rem", color: "floralwhite" }} />} onClick={handleEditCountryBtnClick} />}
                                    </Box>
                                    {editingCountry ?
                                        <>
                                            <FormControl sx={{ display: "flex", flexDirection: "row" }}>
                                                <Select
                                                    id="countrySelect"
                                                    value={newCountry}
                                                    onChange={handleSelectCountryChange}
                                                    size='small'
                                                    sx={{ '.MuiSelect-select': { color: "floralwhite" }, ".MuiSelect-iconStandard": { color: "white" } }}
                                                    variant='standard'
                                                >
                                                    {Object.keys(countries).map(c =>
                                                        <MenuItem value={countries[c]}><Typography fontFamily={RegularFont}>{c}</Typography></MenuItem>
                                                    )}
                                                </Select>
                                                <IconButton sx={{ color: "floralwhite" }} children={<SaveIcon />} onClick={handleSaveCountry} disabled={isFetchingCountry}></IconButton>
                                            </FormControl>
                                        </>
                                        :
                                        <>
                                            <Typography fontFamily={BoldFont} fontSize={'1.3rem'} sx={{ opacity: "60%" }}>{user.country || "N/A"}</Typography>
                                        </>}
                                </Box>
                                <Box sx={{ marginTop: "1.5rem", width: "30%" }}>
                                    <Typography fontFamily={BoldFont} fontSize={'1.3rem'}>Register Date</Typography>
                                    <Typography fontFamily={BoldFont} fontSize={"1.3rem"} sx={{ opacity: "60%", marginTop: "0.25rem" }}>{moment(user.signupDate).format("YYYY/MM/DD")}</Typography>
                                </Box>
                                <Box sx={{ width: "70%", marginTop: "1.5rem", height: "4rem" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography fontFamily={BoldFont} fontSize={'1.3rem'}>{editingLastSeen ? "Who can see my last seen time:" : "Last Seen"}</Typography>
                                        {<IconButton children={<ModeIcon sx={{ fontSize: "1.1rem", color: "floralwhite" }} />} onClick={handleEditLastSeen} />}
                                    </Box>
                                    {editingLastSeen ?
                                        <>
                                            <FormControl sx={{ display: "flex", flexDirection: "row" }}>
                                                <Select
                                                    id="countrySelect"
                                                    value={lastSeen}
                                                    onChange={handleSelectLastseenChange}
                                                    size='small'
                                                    sx={{ '.MuiSelect-select': { color: "floralwhite" }, ".MuiSelect-iconStandard": { color: "white" } }}
                                                    variant='standard'
                                                >
                                                    {lastSeens.map((l, index) =>
                                                        <MenuItem value={index}><Typography fontFamily={RegularFont}>{l}</Typography></MenuItem>
                                                    )}
                                                </Select>
                                                <IconButton sx={{ color: "floralwhite" }} children={<SaveIcon />} onClick={handleSaveLastSeen} disabled={isFetchingLastSeen}></IconButton>
                                            </FormControl>
                                        </>
                                        :
                                        <>
                                            <Typography fontFamily={BoldFont} fontSize={'1.3rem'} sx={{ opacity: "60%" }}>{lastSeens[lastSeen] || "N/A"}</Typography>
                                        </>}
                                </Box>
                                <Box sx={{ visibility: !user.isSubsceneAccount ? "visible" : "hidden", minWidth: "100%", my: "0.5rem", display: 'flex', alignItems: "center", marginTop: "1.5rem", justifyContent: "center" }}>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "#5B9946", height: 'fit-content', textTransform: "none", fontFamily: BoldFont, fontSize: "1rem", px: '2rem', color: darkmode.value ? "black" : "black", "&:hover": { backgroundColor: "#5b994670" } }}
                                        disableRipple
                                        onClick={() => navigate("/dashboard/tickets")}
                                    >
                                        <Typography>Submit a <strong> support ticket </strong> to claim your Subscene profile.</Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Divider sx={{ borderColor: "rgb(255 255 255 / 20%)" }} />
                            <Box>
                                <IconButton value={user?.socialMedia?.insta || ''} name={0} onClick={handleSocialMediaClicked} disableRipple children={!user?.socialMedia?.insta ? <InstagramIcon sx={{ fontSize: '2.5rem', mx: "0.25rem", pointerEvents: 'none', filter: darkmode.value ? "invert(0.5)" : "invert(1)" }} /> : <img src={InstagramColored} alt="Instagram" width={'48px'} height={'40px'} style={{ pointerEvents: 'none' }} />} />
                                <IconButton value={user?.socialMedia?.twitter || ''} name={1} onClick={handleSocialMediaClicked} disableRipple children={!user?.socialMedia?.twitter ? <TwitterIcon sx={{ fontSize: '2.5rem', mx: "0.25rem", pointerEvents: 'none', filter: darkmode.value ? "invert(0.5)" : "invert(1)" }} /> : <img src={TwitterColored} alt="Twitter" width={'48px'} height={'40px'} style={{ pointerEvents: 'none' }} />} />
                                <IconButton value={user?.socialMedia?.telegram || ''} name={2} onClick={handleSocialMediaClicked} disableRipple children={!user?.socialMedia?.telegram ? <img src={TelegramIcon} alt="Telegram" width={'48px'} style={{ opacity: "70%", pointerEvents: 'none', filter: darkmode.value ? "invert(0.5)" : "invert(1)" }} height={'40px'} /> : <img src={TelegramIconColored} alt="Telegram" width={'48px'} height={'40px'} style={{ pointerEvents: 'none' }} />} />
                                <IconButton value={user?.socialMedia?.facebook || ''} name={3} onClick={handleSocialMediaClicked} disableRipple children={!user?.socialMedia?.facebook ? <FacebookSharpIcon sx={{ fontSize: '2.5rem', mx: "0.25rem", pointerEvents: 'none', filter: darkmode.value ? "invert(0.5)" : "invert(1)" }} /> : <img src={FacebookColored} alt="Discord" width={'35px'} height={'35px'} style={{ pointerEvents: 'none' }} />} />
                            </Box>
                        </Box>
                        <Box>
                        </Box>
                    </Box>
                </Box>

            </Box >
        </>

    )
}

export default DashboardProfile
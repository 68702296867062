import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import notFoundIMage from '../imgs/notfound.png'
import notFoundIMageLight from '../imgs/notfound_light.png'
import useDarkMode from 'use-dark-mode'
function NotFound() {
    const darkmode = useDarkMode();
    return (
        <Box sx={{ display: "flex", flex: 1, mt: "10%", justifyContent: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography sx={{ fontFamily: "Regular-Family", fontSize: "200px", fontWeight: "bold", background: darkmode.value ? "White" : "linear-gradient(180deg, rgba(0, 128, 128, 0.5) 0%, #008080 100%)", backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: 'transparent', }}>Oops!</Typography>
                <Typography sx={{ fontSize: "30px", textAlign: "center", fontWeight: "bold", color: darkmode.value ? "#FFFFFFB2" : "#008281" }}>404 - page not found</Typography>
                <Typography sx={{ fontSize: "18px", textAlign: "center", color: darkmode.value ? "#FFFFFFB2" : "initial", fontFamily: "Regular-Family" }}>Have you lost your way, buddy?! Go back to:</Typography>
                <Button
                    variant='contained'
                    disableRipple
                    onClick={() => {
                        window.location.href = '/'
                    }}
                    disableElevation
                    sx={{
                        "&:hover": { backgroundColor: darkmode.value ? "rgb(255 255 255 / 75%)" : "rgb(0 130 129 / 85%)" },
                        backgroundColor: darkmode.value ? "white" : "#008281",
                        textTransform: "none",
                        fontWeight: "bold",
                        color: darkmode.value ? "black" : "white",
                        mt: "1rem",
                        width: "max-content",
                        alignSelf: "center",
                        px: "2rem"
                    }}
                >Home</Button>
            </Box>
            <Box>
                <img src={darkmode.value ? notFoundIMage : notFoundIMageLight} width={650} alt='not found' />
            </Box>
        </Box>
    )
}

export default NotFound
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Rating from '@mui/material/Rating';
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import TelegramIconColored from '../icons/telegramColored.svg';
import InstagramColored from '../icons/instagram.svg'
import TwitterColored from '../icons/twitterColor.svg'
import FacebookColored from '../icons/facebookColored2.svg';
import IconButton from '@mui/material/IconButton'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LogoSolo from "../icons/logoNew.png"
import ssLogo from "../imgs/ss.png"
import ForwardToInboxSharpIcon from '@mui/icons-material/ForwardToInboxSharp';
import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import ContribIcon from '../icons/contribIcon.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '../css/UserProfile.css';
import { Avatar, Link, Tooltip } from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import adminIcon from '../icons/Admin.png'
import up1 from '../icons/up1.webp'
import up2 from '../icons/up2.webp'
import up3 from '../icons/up3.webp'
import tr1 from '../icons/tr1.webp'
import tr2 from '../icons/tr2.webp'
import tr3 from '../icons/tr3.webp'
import do1 from '../icons/do1.webp'
import do2 from '../icons/do2.webp'
import do3 from '../icons/do3.webp'
import do4 from '../icons/do4.webp'
import rt1 from '../icons/rt1.webp'
import rt2 from '../icons/rt2.webp'

import axios from 'axios';
import moment from 'moment';
import { Grid } from 'react-loading-icons';
import useDarkMode from 'use-dark-mode';
import NotFound from './NotFound';
import UserReport from '../Components/Reports/UserReport';
import UserMessage from '../Components/UserMessage';
import useSnackbar from '../hooks/useSnackbar';
function UserProfilePage() {
    const darkmode = useDarkMode();
    const params = useParams();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(undefined);
    const [notFound, setNotFound] = useState(false);
    const [reportShow, setReportShow] = useState(false);
    const [messageShow, setMessageShow] = useState(false);
    const toggleReportShow = () => {
        setReportShow(p => !p);
    }
    const toggleMessageShow = () => {
        setMessageShow(p => !p);
    }
    useEffect(() => {
        setUserData(undefined)
        axios.post(process.env.REACT_APP_API_URL + "/api/getProfile", { id: params.id }, { withCredentials: true }).then(res => {
            if (res.data.success && res.data.userData) {
                document.title = `SubSource: ${res?.data?.userData?.username}'s Profile.`
                setUserData(res?.data?.userData)
            } else {
                showSnackbar('Error.');
                window.location.href = "/"
            }
        }).catch(err => {
            if (err.response.status === 404) {
                setNotFound(true)
            }
            console.log(err);
        })
    }, [params.id])

    const [showSubsceneRegister, setShowSubsceneRegister] = useState(false);
    if (notFound) {
        return (
            <NotFound />
        )
    }
    return (
        userData === undefined ?
            <>
                <Box sx={{ height: "80vh", display: "flex", justifyContent: "center", }}>
                    <Box sx={{ display: "flex", alignItems: "center", margin: "0 auto" }}>
                        <Grid fill={darkmode.value ? "white" : 'black'} fontSize={'0.5rem'} />
                    </Box>
                </Box>
            </> :
            <>
                <Box sx={{ width: "1000px", margin: "0 auto", mt: "3rem", display: "flex", gap: "1rem", justifyContent: "space-between" }}>
                    <Box sx={{ width: "560px", mb: "6rem" }}>
                        <Typography variant='h6' sx={{ fontFamily: "Regular-Family", color: darkmode.value ? "white" : 'initial' }}>User Profile</Typography>
                        <Divider
                            variant="fullWidth"
                            orientation="horizontal"
                            sx={{ borderColor: darkmode.value ? "white" : "black" }}
                        />
                        <Box sx={{ display: "flex", gap: "0.75rem", mt: "1rem" }}>
                            <Paper elevation={3} sx={{ display: "flex", height: "8rem", padding: "0.5rem", mb: "3rem" }}>
                                <Avatar sx={{ width: "8rem", height: "8rem", alignSelf: "center", borderRadius: "0%" }} children={userData.avatar ? "" : <AccountCircleIcon sx={{ width: "8rem", height: "8rem" }} />} key={Date.now()} src={userData.avatar ? "https://api.subsource.net" + userData.avatar : ""} alt={userData.username} />
                            </Paper>

                            <Box sx={{ display: "flex", height: "max-content", alignItems: "center", mt: "1rem" }}>
                                <Typography fontWeight={'bold'} fontSize={'1.5rem'} sx={{ color: darkmode.value ? "white" : 'initial' }}>{userData.username}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", height: "40px", mt: "1rem" }}>
                                {
                                    userData.badges.includes('admin') && <Tooltip title={'Administrator'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="admin" src={adminIcon} width={"20px"} />
                                        </Box>
                                    </Tooltip>
                                }

                                {
                                    userData.badges.includes('up1') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Verified Uploader\nMore than 200 subtitles uploaded!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up1" src={up1} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('up2') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Persistent Uploader\nMore than 1000 subtitles uploaded!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up2" src={up2} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('up3') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Legendary Uploader\nMore than 5000 subtitles uploaded!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={up3} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('tr1') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Verified TransRegular-Familyr\nMore than 75 translations & 1 year of practice!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={tr1} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('tr2') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Distinguished TransRegular-Familyr\nMore than 400 translations & 4 years of practice!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={tr2} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('tr3') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'The Ancient One\nMore than 1000 translations & 10 years of practice!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={tr3} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('do1') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Bronze Donator'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={do1} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('do2') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Silver Donator'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={do2} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('do3') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Gold Donator'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={do3} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('do4') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'The Generous One'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="up3" src={do4} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('rt1') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Subtitle Supporter\nMore than 1000 rates given!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="rt1" src={rt1} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }
                                {
                                    userData.badges.includes('rt2') && <Tooltip PopperProps={{ sx: { whiteSpace: "break-spaces", textAlign: "center" } }} title={'Subtitle Enthusiast\nMore than 10,000 rates given!'} arrow placement='top' classes={{ tooltip: "badgeTooltip", arrow: "badgeTooltip" }}>
                                        <Box className={`button-24 ${darkmode.value && "dark-mode"}`} sx={{ "&:hover": { transform: "scale(1.5)" } }}>
                                            <img alt="rt2" src={rt2} width={"50px"} />
                                        </Box>
                                    </Tooltip>
                                }

                            </Box>
                        </Box>
                        {
                            userData.isBanned && <Box>
                                <Typography sx={{ fontFamily: "Helvetiva", fontSize: "18px", fontWeight: "bold", color: darkmode.value ? "#c30000" : "darkred" }}>Penalty: This user is <strong>permenantly</strong> banned from SubSource.</Typography>
                            </Box>
                        }

                        <Box sx={{ display: "flex", justifyContent: "space-between", my: "1rem" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Link href={"/u/" + params.id + "/subtitles"} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + params.id + "/subtitles") } }} className="button-24 contribSub" sx={{ textDecoration: "none", backgroundColor: darkmode.value ? "#44474a !important" : "initial" }}>
                                    <Typography fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ color: darkmode.value ? 'white' : "black", fontSize: "1.05rem" }}>{userData.subCount} Subtitles </Typography>
                                    <Tooltip title="Number of Collaborations" placement='top'>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <AddCircleSharpIcon sx={{ color: darkmode.value ? "#868ab3" : "#484c7a", fontSize: "0.9rem", ml: "0.25rem", mt: '1px' }}></AddCircleSharpIcon>
                                            <Typography fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ ml: "0.15rem", color: darkmode.value ? "#868ab3" : "#484c7a" }}>{userData.contribCount}</Typography>
                                        </Box>
                                    </Tooltip>
                                </Link>
                            </Box>
                            <Link href={"/u/" + params.id + "/ratings"} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + params.id + "/ratings") } }} sx={{ color: darkmode.value ? "white" : 'initial', textDecoration: "none", fontFamily: "Regular-Family", display: "contents" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", }}>
                                    {userData.ratings} Ratings
                                    <Rating value={userData.rate} max={5} precision={0.5} readOnly sx={{ display: userData.rate === -1 ? 'none' : 'flex', alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px", height: 'auto', width: "auto" }}>
                            {userData?.lastNine?.map(x => (
                                <Link href={x.fullLink} sx={{ textDecoration: "none", color: "black" }}>
                                    <Paper variant="elevation" elevation={3} sx={{ padding: "8px", width: "160px", height: "280px", cursor: "pointer" }} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(x.fullLink) } }}>
                                        <Box className="contribIconBox" sx={{ display: x.isContrib === true ? 'flex' : 'none' }}>
                                            <img src={ContribIcon} alt='Contributed' className='contribIcon' />
                                        </Box>
                                        <img alt={x.subMovieName} src={x.poster} style={{ width: "160px", height: "240px" }} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "https://api.subsource.net/notfound.jpg";
                                        }}></img>
                                        <Tooltip title={x.subMovieName} placement='top'>
                                            <Typography fontSize={"0.65rem"} textAlign={"center"} sx={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>{x.subMovieName}</Typography>
                                        </Tooltip>
                                        <Typography fontSize={"0.65rem"} textAlign={"center"}>{x.downloads} Downloads</Typography>
                                    </Paper>
                                </Link>
                            ))}
                            <Button href={"/u/" + params.id + "/subtitles"} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + params.id + "/subtitles") } }} variant="text" fullWidth sx={{ mt: "0.5rem", bgcolor: "white", boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)", backgroundColor: "#ffffff42" }} disableRipple>
                                view all
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: "30%" }}>
                        <Typography variant='h6' sx={{ fontFamily: "Regular-Family", color: darkmode.value ? "white" : 'initial' }}>Details</Typography>
                        <Divider
                            variant="fullWidth"
                            orientation="horizontal"
                            sx={{ borderColor: darkmode.value ? "white" : "black", mb: "0.5rem" }}
                        />
                        <Box >
                            <Box sx={{ display: 'flex', width: "auto" }}>
                                <Paper sx={{ py: "0.25rem", display: "flex", my: "0.25rem", justifyContent: "center", width: "100%", px: "0.5rem", backgroundColor: darkmode.value ? "rgb(255,255,255,0.2)" : "rgb(255,255,255,0.6)", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>
                                    <Typography sx={{ fontSize: "0.9rem", display: "flex", alignItems: "center" }}><img alt='subsource' src={LogoSolo} style={{ marginRight: "0.25rem", width: "20px", height: "25px" }} /> Registered: {moment(userData.signup).format("YYYY/MM/DD")}</Typography>
                                </Paper>
                                <Paper sx={{ p: "0px", ml: "5px", my: "0.25rem", display: userData.signup_scene ? "flex" : "none", px: "2px", backgroundColor: darkmode.value ? "rgb(255,255,255,0.2)" : "rgb(255,255,255,0.6)" }}>
                                    <IconButton onClick={() => { setShowSubsceneRegister(p => !p) }} sx={{ p: "0px" }} children={<ErrorOutlineIcon />}>
                                    </IconButton>
                                </Paper>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Paper sx={{ py: showSubsceneRegister ? "0.25rem" : "0px", my: showSubsceneRegister ? "0.25rem" : "0px", width: "110px", display: 'flex', justifyContent: "center", transition: "0.5s all ease-in-out", height: showSubsceneRegister ? "21px" : "0px", px: "0.5rem", backgroundColor: "#0080804d", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>
                                    <img alt='ss' src={ssLogo} style={{ marginRight: "0.5rem", width: "20px" }} /><Typography sx={{ fontSize: "0.9rem" }}> {moment(userData.signup_scene).format("YYYY/MM/DD")}</Typography>
                                </Paper>
                            </Box>
                            <Paper sx={{ py: "0.25rem", my: "0.25rem", width: "auto", textAlign: "center", px: "0.5rem", backgroundColor: darkmode.value ? "rgb(255,255,255,0.2)" : "rgb(255,255,255,0.6)", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>
                                <Typography sx={{ fontSize: "0.9rem" }}>Last Seen: {userData?.lastSeen}</Typography>
                            </Paper>
                            <Link sx={{ display: "contents" }} href={"/u/" + params.id + "/ratings_given"} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + params.id + "/ratings_given") } }}>
                                <Paper sx={{ cursor: "pointer", py: "0.25rem", my: "0.25rem", width: "auto", textAlign: "center", px: "0.5rem", backgroundColor: darkmode.value ? "rgb(255,255,255,0.2)" : "rgb(255,255,255,0.6)", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>
                                    <Typography sx={{ fontSize: "0.9rem" }}>{userData.ratings_given} Ratings Given</Typography>
                                </Paper>
                            </Link>

                            <Paper sx={{ py: "0.25rem", my: "0.25rem", width: "auto", textAlign: "center", px: "0.5rem", textOverflow: "ellipsis", backgroundColor: darkmode.value ? "rgb(255,255,255,0.2)" : "rgb(255,255,255,0.6)", overflow: "hidden", textWrap: "nowrap", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",/*  "&:hover": { backgroundColor: "#00808066" } */ }} onClick={toggleReportShow}>
                                <FlagSharpIcon sx={{ height: "20px", width: "20px", mr: "0.25rem" }} />
                                <Typography sx={{ fontSize: "0.9rem" }}>Report User</Typography>
                            </Paper>
                            <Paper sx={{ py: "0.25rem", my: "0.25rem", width: "auto", textAlign: "center", px: "0.5rem", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: darkmode.value ? "rgb(255,255,255,0.2)" : "rgb(255,255,255,0.6)", textWrap: "nowrap", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", /* "&:hover": { backgroundColor: "#00808066" } */ }} onClick={toggleMessageShow}>
                                <ForwardToInboxSharpIcon sx={{ height: "20px", width: "20px", mr: "0.25rem" }} />
                                <Typography sx={{ fontSize: "0.9rem" }}>Private Message</Typography>
                            </Paper>
                            <Typography variant='h6' sx={{ fontFamily: "Regular-Family", mt: '1rem', color: darkmode.value ? "white" : 'initial' }}>Bio</Typography>
                            <Divider
                                variant="fullWidth"
                                orientation="horizontal"
                                sx={{ borderColor: darkmode.value ? "white" : 'initial', mb: "0.5rem" }}
                            />
                            <Paper sx={{ width: "auto", mt: "1rem", p: '0.5rem' }}>
                                <Typography sx={{ fontFamily: "Regular-Family-Bold", padding: "0.5rem", wordWrap: "break-word", whiteSpace: "break-spaces", color: darkmode.value ? "white" : 'initial' }} dir="auto">
                                    {userData.bio || "No bio is set."}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                    <IconButton onClick={() => { window.open("https://t.me/" + userData?.socialMedia?.telegram, "_blank") }} sx={{ px: "0.5rem", py: "0.5rem", display: userData?.socialMedia?.telegram ? "flex" : "none" }} children={<img src={TelegramIconColored} alt="Telegram" width={'26px'} height={'26px'} style={{ pointerEvents: 'none' }} />} />
                                    <IconButton onClick={() => { window.open("https://instagram.com/" + userData?.socialMedia?.insta, "_blank") }} sx={{ px: "0.5rem", py: "0.5rem", display: userData?.socialMedia?.insta ? "flex" : "none" }} children={<img src={InstagramColored} alt="Telegram" width={'26px'} height={'26px'} style={{ pointerEvents: 'none' }} />} />
                                    <IconButton onClick={() => { window.open("https://twitter.com/" + userData?.socialMedia?.twitter, "_blank") }} sx={{ px: "0.5rem", py: "0.5rem", display: userData?.socialMedia?.twitter ? "flex" : "none" }} children={<img src={TwitterColored} alt="Telegram" width={'30px'} height={'30px'} style={{ pointerEvents: 'none' }} />} />
                                    <IconButton onClick={() => { window.open("https://www.facebook.com/" + userData?.socialMedia?.facebook, "_blank") }} sx={{ px: "0.5rem", py: "0.5rem", display: userData?.socialMedia?.facebook ? "flex" : "none" }} children={<img src={FacebookColored} alt="Telegram" width={'24px'} height={'24px'} style={{ pointerEvents: 'none' }} />} />
                                </Box>
                            </Paper>
                            {/* <Box sx={{ display: "none" }}>
                            <a href="/" class="donate">Donate Your TransRegular-Familyr</a>
                        </Box> */}
                        </Box>
                    </Box>
                </Box >
                <UserReport show={reportShow} toggleShow={toggleReportShow} userId={params.id} />
                <UserMessage show={messageShow} toggleShow={toggleMessageShow} username={userData?.username || ""} userId={params?.id} />
            </>
    )
}

export default UserProfilePage
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import useSnackbar from '../../hooks/useSnackbar';
const mainFont = "Regular-Family";

function CustomTab() {
    const darkmode = useDarkMode();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [loadedImage, setLoadedImage] = useState('');
    const [type, setType] = useState(0);
    const [creating, setCreating] = useState(false);
    const titleRef = useRef();
    const imageLinkRef = useRef();
    const releaseYearRef = useRef();
    const seasonRef = useRef();
    const sourcelinkRef = useRef();
    const handleSubmit = () => {
        var expr = /^[\p{L}\p{N}\p{Zs}?()[/|\]'":!&+@,._ -]+$/mu
        if (!expr.test(titleRef.current.value)) {
            return showSnackbar("Unallowed Characters in title.", 'error');
        }
        if (type === 0) {
            return showSnackbar("Invalid Type.", 'error');
        }
        if (titleRef.current.value.length <= 2) {
            return showSnackbar("Title must be at least 3 characters long.", 'error');
        }
        if (releaseYearRef.current.value.length !== 4 && isNaN(releaseYearRef.current.value)) {
            return showSnackbar("Invalid Release Year.", 'error');
        }
        if (type === 1 && isNaN(seasonRef.current.value)) {
            return showSnackbar("Invalid Season", 'error');
        }
        if (!creating) {
            setCreating(true);
            axios.post(process.env.REACT_APP_API_URL + "/api/createMovieCustom", {
                sourceLink: sourcelinkRef.current.value || "",
                season: seasonRef.current.value,
                year: releaseYearRef.current.value,
                title: titleRef.current.value,
                type: type === 1 ? "TVSeries" : "Movie",
                poster: loadedImage,
            }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    navigate('/upload/' + res.data.movieId);
                } else {
                    showSnackbar(res.data.errMsg || "Error.", "error")
                }
                setCreating(false);
            }).catch(err => {
                setCreating(false);
                showSnackbar("Error.", 'error')
            })
        }
    }
    return (
        <>
            <Box sx={{ maxHeight: "700px", width: "70%", overflowY: "auto", pr: "1rem" }} className={'scrollCustom'}>
                <Typography sx={{ fontFamily: mainFont, fontSize: "1.4rem", color: darkmode.value ? "white" : 'initial' }}>Add New Movie / TV-Series</Typography>
                <Divider sx={{ borderColor: "rgb(0,0,0,0.35)", marginBottom: "1rem" }} />
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <TextField
                            id="image"
                            label="Image link (optional)"
                            placeholder='Insert image link here...'
                            variant="outlined"
                            size='small'
                            inputRef={imageLinkRef}
                            fullWidth
                        >
                        </TextField>
                        <Button onClick={() => setLoadedImage(imageLinkRef.current.value)} disabled={creating} variant='contained' color='success' sx={{ textTransform: "none" }}>
                            Load
                        </Button>
                    </Box>
                    <Box>
                        <TextField
                            helperText="Enter links from reliable sources such as: MyDramaList, Kinopoisk & ivi.tv"
                            id="sourcelink"
                            label="Source Link (optional)"
                            variant="outlined"
                            placeholder='Enter source link here...'
                            size='small'
                            inputRef={sourcelinkRef}
                            fullWidth
                        >
                        </TextField>
                    </Box>
                    <Box>
                        <TextField
                            id="title"
                            label="Title"
                            variant="outlined"
                            placeholder='Insert title here...'
                            size='small'
                            inputRef={titleRef}
                            fullWidth
                        >
                        </TextField>
                    </Box>
                    <Box>
                        <TextField
                            id="releaseYear"
                            label="Release Year"
                            variant="outlined"
                            placeholder='Insert year of release here...'
                            size='small'
                            inputRef={releaseYearRef}
                            fullWidth
                        >
                        </TextField>
                    </Box>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="type-label" size='small'>Type</InputLabel>
                            <Select
                                labelId="type-label"
                                id="typeLabel"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                label="Type"
                                size='small'
                            >
                                <MenuItem value={0} disabled>Select Type</MenuItem>
                                <MenuItem value={1}>TV-Series</MenuItem>
                                <MenuItem value={2}>Movie</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: type === 1 ? "flex" : 'none' }}>
                        <TextField
                            id="season"
                            label="Season"
                            variant="outlined"
                            placeholder='Enter Season here... (0 for Extras/Complete Series)'
                            size='small'
                            inputRef={seasonRef}
                            fullWidth
                        >
                        </TextField>
                    </Box>
                    <Button disabled={type === 0 || creating} variant='contained' onClick={handleSubmit} sx={{ textTransform: "none", my: "1rem", bgcolor: "teal", "&:hover": { bgcolor: "#008080c2" } }}>Create</Button>
                </Box>

            </Box >
            <Box sx={{ width: "30%", marginLeft: "1rem" }}>
                <Typography sx={{ fontFamily: mainFont, fontSize: "1.4rem", color: darkmode.value ? "white" : 'initial' }}>Movie/Series Info</Typography>
                <Divider sx={{ borderColor: "rgb(0,0,0,0.35)", marginBottom: "1rem" }} />
                {!loadedImage ? <><Typography fontFamily={"Regular-Family"} sx={{ color: darkmode.value ? "white" : 'initial' }}>Load the image to show here.</Typography></> :
                    <>
                        <Box>
                            <img alt={'poster'} src={loadedImage} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://api.subsource.net/notfound.jpg";
                            }} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </Box>
                    </>
                }

            </Box>
        </>
    )
}

export default CustomTab
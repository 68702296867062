// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#commentSection {
    display: flex;
    background-color: white;
    min-height: 100px;
    max-width: 400px;
    padding: 0.25rem;
    padding-left: 0.5rem;
    font-family: 'iransans';
    border-radius: 5px;
    white-space: break-word;
}

#commentSection:read-write:focus-visible {
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Upload/Upload.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,uBAAuB;IACvB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB","sourcesContent":["#commentSection {\r\n    display: flex;\r\n    background-color: white;\r\n    min-height: 100px;\r\n    max-width: 400px;\r\n    padding: 0.25rem;\r\n    padding-left: 0.5rem;\r\n    font-family: 'iransans';\r\n    border-radius: 5px;\r\n    white-space: break-word;\r\n}\r\n\r\n#commentSection:read-write:focus-visible {\r\n    outline: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import Header from '../Components/Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Components/Footer/Footer';

function MainLayout() {
    const [appBarHeight, setAppBarHeight] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const updateMarginTop = () => {
            const appBar = document.getElementById('AppBar');
            if (appBar) {
                const height = appBar.getBoundingClientRect().height;
                setAppBarHeight(height);
            }
        };

        updateMarginTop();
        window.addEventListener('resize', updateMarginTop);

        return () => {
            window.removeEventListener('resize', updateMarginTop);
        };
    }, []);

    const isDashboardPage = location.pathname.includes('dashboard');

    return (
        <>
            <Header />
            <div
                id="bodyContainer"
                style={{
                    marginTop: `${isDashboardPage ? appBarHeight : appBarHeight + 10}px`,
                }}
            >
                <Outlet />
            </div>
            <Footer />
        </>
    );
}

export default MainLayout;

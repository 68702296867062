import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Button, Dialog, Paper, IconButton } from '@mui/material';
import GroupAddSharpIcon from '@mui/icons-material/GroupAddSharp';
import moment from 'moment'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import axios from 'axios';
const RegularFont = 'Regular-Family';
function ContribBubble({ time, me, subId, state, userName, userId, subLink, handleContribStateChange, handleHideContrib }) {
    const [dialogShow, setDialogShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const handleDialogClose = () => {
        setDialogShow(false);
    }
    const handleDialogOpen = () => {
        setDialogShow(true);
    }
    const handleHideMessage = () => {
        axios.post(process.env.REACT_APP_API_URL + "/api/contribStateUpdate", { subId, from: userId, state: 3, my: me }, { withCredentials: true }).then(res => {
            handleHideContrib(subId);
        }).catch(err => {
            console.log(err);
        })
    }
    const handleAccept = () => {
        setSubmitting(true)
        axios.post(process.env.REACT_APP_API_URL + "/api/contribStateUpdate", { subId, from: userId, state: 1, my: me }, { withCredentials: true }).then(res => {
            handleContribStateChange(subId, 1);
            handleDialogClose();
            setSubmitting(false)
        }).catch(err => {
            console.log(err);
            setSubmitting(false)
        })

    }
    const handleDecline = () => {
        setSubmitting(true)
        axios.post(process.env.REACT_APP_API_URL + "/api/contribStateUpdate", { subId, from: userId, state: 2, my: me }, { withCredentials: true }).then(res => {
            handleContribStateChange(subId, 2);
            handleDialogClose();
            setSubmitting(false)
        }).catch(err => {
            console.log(err);
            setSubmitting(false)
        })
    }
    return (
        me ?
            <Box sx={{ width: "max-content", display: "flex", flexDirection: "column", placeSelf: "end" }}>
                <Box sx={{
                    maxWidth: "17rem",
                    backgroundColor: "cornflowerblue",
                    my: "1rem",
                    '--r': "25px",
                    '--t': "15px",
                    WebkitMask: 'radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)calc(var(--r) / -2) padding-box,radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space padding-box',
                    '--_d': '100%',
                    borderRight: `var(--t) solid #0000`,
                    marginLeft: `var(--t)`,
                    overflowWrap: "anywhere",
                    placeSelf: "end",
                    padding: `calc(2 * var(--r) / 3)`
                }}>
                    <Typography fontFamily={RegularFont} variant="h5" color="initial" sx={{ width: "100%" }}>
                        {state === 0 && <>{`You have requested ${userName} to join as a contributor in your subtitle.`}</>}
                        {state === 1 && <>{`${userName} has `}{state === 1 ? <span style={{ color: "darkgreen" }}>accepted</span> : <span style={{ color: "darkred" }}>declined</span>}{` your subtitle contribution request.`}</>}
                        {state === 2 && <>{`${userName} has `}{state === 1 ? <span style={{ color: "darkgreen" }}>accepted</span> : <span style={{ color: "darkred" }}>declined</span>}{` your subtitle contribution request.`}</>}
                    </Typography>
                    <Typography fontFamily={RegularFont} fontSize={'0.75rem'} color="initial" sx={{ width: "100%" }}>{moment(time).format('lll')}</Typography>
                </Box>
                {state === 0 && <Box sx={{ display: "flex", justifyContent: "space-between", px: "1rem", mb: "0.5rem" }}>
                    <Button onClick={handleDialogOpen} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        View
                    </Button>
                    <Button target='_blank' href={subLink} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Subtitle
                    </Button>
                </Box>
                }
                {state === 1 && <Box sx={{ display: "flex", justifyContent: "space-between", px: "1rem", mb: "0.5rem" }}>
                    <Button onClick={handleHideMessage} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Hide
                    </Button>
                    <Button target='_blank' href={subLink} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Subtitle
                    </Button>
                </Box>}
                {state === 2 && <Box sx={{ display: "flex", justifyContent: "space-between", px: "1rem", mb: "0.5rem" }}>
                    <Button onClick={handleHideMessage} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Hide
                    </Button>
                    <Button target='_blank' href={subLink} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Subtitle
                    </Button>
                </Box>}
            </Box> : <Box sx={{ width: "max-content" }}>
                <Box sx={{
                    maxWidth: "17rem",
                    backgroundColor: "gainsboro",
                    mt: "1rem",
                    mb: "0.25rem",
                    '--r': "25px",
                    '--t': "15px",
                    WebkitMask: 'radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)calc(var(--r) / -2) padding-box,radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space padding-box',
                    '--_d': '0%',
                    borderLeft: `var(--t) solid #0000`,
                    marginRight: `var(--t)`,
                    overflowWrap: "anywhere",
                    padding: `calc(2 * var(--r) / 3)`,
                    placeSelf: "start",
                }}>
                    <Typography fontFamily={RegularFont} variant="h5" color="initial" sx={{ width: "100%" }}>
                        {state === 0 && <>{`${userName} has requested you to join as a contributor in his subtitle.`}</>}
                        {state === 1 && <>{`You have `}{state === 1 ? <span style={{ color: "darkgreen" }}>accepted</span> : <span style={{ color: "darkred" }}>declined</span>}{` ${userName}'s contribution request.`}</>}
                        {state === 2 && <>{`You have `}{state === 1 ? <span style={{ color: "darkgreen" }}>accepted</span> : <span style={{ color: "darkred" }}>declined</span>}{` ${userName}'s contribution request.`}</>}
                    </Typography>
                    <Typography fontFamily={RegularFont} fontSize={'0.75rem'} color="initial" sx={{ width: "100%" }}>{moment(time).format('lll')}</Typography>
                </Box>
                {state === 0 && <Box sx={{ display: "flex", justifyContent: "space-between", px: "1rem", mb: "0.5rem" }}>
                    <Button onClick={handleDialogOpen} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        View
                    </Button>
                    <Button target='_blank' href={subLink} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Subtitle
                    </Button>
                </Box>}
                {state === 1 && <Box sx={{ display: "flex", justifyContent: "space-between", px: "1rem", mb: "0.5rem" }}>
                    <Button onClick={handleHideMessage} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Hide
                    </Button>
                    <Button target='_blank' href={subLink} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Subtitle
                    </Button>
                </Box>}
                {state === 2 && <Box sx={{ display: "flex", justifyContent: "space-between", px: "1rem", mb: "0.5rem" }}>
                    <Button onClick={handleHideMessage} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Hide
                    </Button>
                    <Button target='_blank' href={subLink} variant="text" color="primary" disableRipple sx={{ width: "49%", background: "rgba(255, 255, 255, 0.17)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12.5px)", WebkitBackdropFilter: "blur(12.5px)", "&:hover": { background: "rgba(255, 255, 255, 0.3)" }, textTransform: "none", color: "white", fontSize: "1rem" }}>
                        Subtitle
                    </Button>
                </Box>}
                <Dialog open={dialogShow} onClose={handleDialogClose} aria-labelledby="ContribWindow">
                    <IconButton aria-label="close" onClick={handleDialogClose} children={<CloseSharpIcon />} sx={{ position: "absolute", right: "0", p: "1rem" }} disableRipple />
                    <Paper elevation={1} sx={{ width: "auto", height: "auto" }}>
                        <Box sx={{ px: "3rem", py: "1.5rem" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                <GroupAddSharpIcon sx={{ fontSize: "3.5rem" }} />
                                <Typography fontWeight={"Bold"} variant='h5'>Subtitle Contribution</Typography>
                            </Box>
                            <Typography sx={{ mt: "1rem" }}>Do you accept to contribute in {userName}'s Subtitle?</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", gap: "3rem", mt: "1rem" }}>
                                <Button variant='contained' fullWidth disableRipple onClick={handleAccept} disabled={submitting}>
                                    Accept
                                </Button>
                                <Button variant='outlined' fullWidth disableRipple onClick={handleDecline} disabled={submitting}>
                                    Decline
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Dialog>
            </Box>

    )
}

export default ContribBubble
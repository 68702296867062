import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Avatar, Typography } from '@mui/material'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'
import { ThreeDots } from 'react-loading-icons';
TimeAgo.addDefaultLocale(en)
const BoldFont = 'Regular-Family';
function Chats({ chats, currentChat, setCurrentChat, loading, handleReadAll }) {
    chats = chats.sort((a, b) => b.lastTime - a.lastTime);
    return (
        <>
            {loading ?
                <>
                    <Box sx={{ p: "4.5rem" }}>
                        <ThreeDots />
                    </Box>
                </>
                :
                chats.length > 0 ? chats.map((chat, index) => (
                    <Paper onClick={() => { handleReadAll(chat.chatId); setCurrentChat({ chatId: chat.chatId, userId: chat.userId }) }} key={chat.chatId} variant="elevation" elevation={2} sx={{ bgcolor: currentChat?.chatId === chat?.chatId || currentChat?.userId === chat?.userId ? "rgb(255,255,255,0.35)" : "rgb(255,255,255,0.18)", my: "0.25rem", display: "flex", borderRadius: "15px", cursor: "pointer", width: '100%' }}>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", px: "0.5rem" }}>
                            <Avatar sx={{ backgroundColor: "mediumpurple", width: "3rem", height: "3rem" }} src={chat?.userAvatar ? "https://api.subsource.net" + chat?.userAvatar : ""}>{chat?.targetName?.slice(0, 1)}</Avatar>
                            <Box sx={{ p: "0.5rem", display: "flex", width: '100%', justifyContent: "space-between" }}>
                                <Box sx={{ width: "100%" }}>
                                    <Typography fontFamily={BoldFont} sx={{ fontSize: "18px", marginBottom: "-0.2rem", color: "rgb(255,255,255,0.9)", maxWidth: "160px", overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}>{chat.targetName}</Typography>
                                    <Typography fontFamily={"Regular-Family"} sx={{ fontSize: "0.85rem", color: "rgb(255,255,255,0.4)", textWrap: "nowrap", overflow: "hidden", maxWidth: "160px", textOverflow: "ellipsis" }}>{chat?.messages[0]?.text || "No Messages Yet."}</Typography>
                                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "right", position: "relative" }}>
                                        <Typography fontSize={'0.65rem'} color={'floralwhite'}>
                                            {chat?.messages.length > 0 && <ReactTimeAgo date={new Date(chat?.messages[0]?.time)} locale="en-US" />}
                                        </Typography>
                                        <Box sx={{ position: "absolute", width: "20px", height: "20px", bgcolor: "white", transform: "translateY(-30px)", borderRadius: "50%", display: chat?.unreads ? "flex" : 'none', alignItems: "center", justifyContent: "center", }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: chat?.unreads?.length < 3 ? "0.65rem" : "0.85rem" }}>{chat?.unreads || 0}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                ))
                    :
                    <Typography variant='h5' fontFamily={BoldFont} sx={{ textAlign: "center" }}>
                        Not found...
                    </Typography>
            }
        </>
    )
}
export default Chats
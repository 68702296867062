import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import { Button, IconButton, TextField, Checkbox, FormControlLabel } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import ReCAPTCHA from "react-google-recaptcha"

import useDarkMode from 'use-dark-mode';
import { UserApi } from '../../api/userApi';
import { useQuery } from '@tanstack/react-query';
const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
const validatePassword = (password) => {
    return password.length > 7;
};

function SignUpDialog({ handleClose, isOpen, openLogIn, openForgetPassword }) {
    const darkmode = useDarkMode();

    const [creds, setCreds] = useState({ email: "", username: "", password: "" });
    const [submiting, setSubmiting] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [isSubscene, setIsSubscene] = useState(false);
    const [error, setError] = useState({ color: "red", text: "" });
    const [usernameValid, setUsernameValid] = useState(false)
    const [captchaSolved, setCaptchaSolved] = useState(false);
    const captchaRef = useRef();
    const { refetch } = useQuery({
        queryKey: ['signup'],
        queryFn: async () => {
            try {
                const signupData = await UserApi.signup(creds.email, creds.username, creds.password, isSubscene, captchaRef.current.getValue());
                if (signupData) {
                    if (signupData?.success) {
                        setError({ ...error, text: "Registered Successfully. Please verify your email.", color: "green" });
                    } else {
                        if (signupData?.errCode === 3) {
                            setError({ ...error, text: "Email and Username Already in use.", color: "red" })
                        }
                        if (signupData?.errCode === 2) {
                            setError({ ...error, text: "Email Already in use.", color: "red" })
                        }
                        if (signupData?.errCode === 1) {
                            setError({ ...error, text: "Username Already in use.", color: "red" })
                        }
                        if (signupData?.errCode === 6) {
                            setError({ ...error, text: "Error Registering User.", color: "red" })
                        }
                        if (signupData?.errCode === 5) {
                            setError({ ...error, text: "Error Registering User.", color: "red" })
                        }
                        if (signupData?.errCode === 4) {
                            setError({ ...error, text: "Error Registering User.", color: "red" })
                        }
                        if (signupData?.errCode === 0) {
                            setError({ ...error, text: "Error Registering User.", color: "red" })
                        }
                        if (signupData?.errCode === 7) {
                            setError({ ...error, text: "Captcha Verification Failed.", color: "red" })
                        }
                    }
                    setSubmiting(false);
                    setSubmitDisable(false);
                    captchaRef.current.reset();
                }
                return { success: true };
            } catch (err) {
                console.log({ err });
                setError({ ...error, text: "Server error.", color: "red" })
                setSubmiting(false);
                setSubmitDisable(false);
            }
        },
        enabled: false
    });
    const validateUsername = (_uname) => {
        var expr = /^[a-zA-Z0-9._]*$/;
        return expr.test(_uname) && _uname.length >= 3;
    }
    const handleCredsChange = (e) => {
        if (e.target.name === 'username') {
            if (!validateUsername(e.target.value)) {
                setUsernameValid(false);
                setError({ ...error, text: "Only Alphabets, Numbers, Dot and Underscore allowed in Username and must be at least 3 characters long.", color: "red" });
            } else {
                if (error.text === "Only Alphabets, Numbers, Dot and Underscore allowed in Username and must be at least 3 characters long.") {
                    setError({ ...error, text: "" });
                }
                setUsernameValid(true);
            }
        }
        if (e.target.name === "password") {
            if (e.target.value.length <= 7) {
                setError({ ...error, text: "Password must be atleast 8 characters.", color: "red" });
            } else {
                if (!validateUsername(creds.username)) {
                    setError({ ...error, text: "Only Alphabets, Numbers, Dot and Underscore allowed in Username and must be at least 3 characters long.", color: "red" });
                } else {
                    setError({ ...error, text: "", color: "red" });
                }
            }
        }
        setCreds({ ...creds, [e.target.name]: e.target.value });
    }
    const handleIsSubsceneChange = (e, val) => {
        setIsSubscene(val);
    }
    useEffect(() => {
        if (validateEmail(creds.email) && validatePassword(creds.password) && validateUsername(creds.username)) {
            setSubmitDisable(false)
        } else {
            setSubmitDisable(true)
        }
    }, [creds]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleRegister = () => {
        var expr = /^[a-zA-Z0-9._]*$/;
        if (validateEmail(creds.email) && validatePassword(creds.password) && validateUsername(creds.username) && !submiting && usernameValid && expr.test(creds.username)) {
            setSubmiting(true);
            setSubmitDisable(true);
            setCaptchaSolved(false);
            refetch();
            /* axios.post(process.env.REACT_APP_API_URL + "/auth/register", { email: creds.email, username: creds.username, password: creds.password, isSubscene, c: captchaRef.current.getValue() }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setError({ ...error, text: "Registered Successfully. Please verify your email.", color: "green" });
                } else {
                    if (res.data.errCode === 3) {
                        setError({ ...error, text: "Email and Username Already in use." })
                    }
                    if (res.data.errCode === 2) {
                        setError({ ...error, text: "Email Already in use." })
                    }
                    if (res.data.errCode === 1) {
                        setError({ ...error, text: "Username Already in use." })
                    }
                    if (res.data.errCode === 6) {
                        setError({ ...error, text: "Error Registering User." })
                    }
                    if (res.data.errCode === 5) {
                        setError({ ...error, text: "Error Registering User." })
                    }
                    if (res.data.errCode === 4) {
                        setError({ ...error, text: "Error Registering User." })
                    }
                    if (res.data.errCode === 0) {
                        setError({ ...error, text: "Error Registering User." })
                    }
                    if (res.data.errCode === 7) {
                        setError({ ...error, text: "Captcha Verification Failed." })
                    }
                }
                setSubmiting(false);
                setSubmitDisable(false);
                captchaRef.current.reset();
            }).catch(err => {
                setError({ ...error, text: "Error Registering User." })
                setSubmiting(false);
                setSubmitDisable(false);
                console.log(err)
            }) */
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                sx: { maxHeight: "unset !important" }
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "right", p: "1rem" }}>
                <IconButton children={<CloseIcon />} sx={{ borderRadius: "0px", padding: "0px" }} disableRipple onClick={() => { handleClose() }} />
            </Box>
            <DialogTitle sx={{ alignSelf: "center", fontWeight: "bold", fontFamily: "Regular-Family", py: "0.25rem", fontSize: "2.25rem", px: "9rem" }}>Register</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: error.text === "" ? '1rem' : '0px', overflow: "hidden" }}>
                {/* <DialogContentText sx={{ fontFamily: "Regular-Family", marginBottom: "2.5rem" }}>
                    To Login to this website, please enter your email address and password.
                </DialogContentText> */}
                <TextField
                    onChange={handleCredsChange}
                    autoFocus
                    margin="dense"
                    id="name"
                    name="username"
                    label="Username"
                    type="text"
                    sx={{ width: '70%', '.MuiInputBase-root': { borderRadius: "10px" } }}
                    variant="outlined"
                />
                <TextField
                    onChange={handleCredsChange}
                    margin="dense"
                    id="name"
                    name="email"
                    label="Email Address"
                    type="email"
                    sx={{ width: '70%', '.MuiInputBase-root': { borderRadius: "10px" } }}
                    variant="outlined"
                />
                <TextField
                    onChange={handleCredsChange}
                    margin="dense"
                    id="name"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    sx={{ width: '70%', '.MuiInputBase-root': { borderRadius: "10px" } }}
                    variant="outlined"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>

                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isSubscene}
                            onChange={handleIsSubsceneChange}
                            value="checkedB"
                            color="primary"
                        />
                    }
                    label={"Click here if you have a subscene account\nand you want to claim it!"}
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.85rem", whiteSpace: "break-spaces", color: darkmode.value ? "#ff5f5f" : "red" }, mt: "1rem", textAlign: "center", mb: "1rem" }}
                />
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={(e) => { setCaptchaSolved(true) }} onExpired={() => { setCaptchaSolved(false) }} ref={captchaRef} />
            </DialogContent>
            <DialogActions sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography sx={{ display: error.text !== '' ? "flex" : 'none', top: '-10px', paddingBottom: "1rem", maxWidth: "350px", textAlign: "center" }} color={error.color} fontFamily={'Regular-Family'}>{error.text}</Typography>

                <LoadingButton
                    variant='contained'
                    disabled={submitDisable || !captchaSolved}
                    children={<Typography fontFamily={'Regular-Family'} fontSize={'1.2rem'}>Sign Up</Typography>}
                    sx={{
                        width: "66%",
                        marginBottom: "3rem",
                        textTransform: "none",
                        borderRadius: "10px"
                    }}
                    disableElevation
                    disableRipple
                    onClick={handleRegister}
                    loading={submiting}
                />
                <Button
                    variant=''
                    children={<Typography fontFamily={'Regular-Family'} fontSize={'0.9rem'}>Already Registered? Click to login</Typography>}
                    sx={{
                        width: "66%",
                        textTransform: "none",
                        borderRadius: "10px",
                        "&:hover": { backgroundColor: 'inherit' },
                        color: darkmode.value ? "lightskyblue" : "blue"
                    }}
                    disableElevation
                    disableRipple
                    onClick={() => {
                        handleClose();
                        openLogIn();
                    }} />
                <Button
                    variant=''
                    children={<Typography fontFamily={'Regular-Family'} fontSize={'0.9rem'}>Forgot Password?</Typography>}
                    sx={{
                        width: "66%",
                        marginBottom: "1rem",
                        textTransform: "none",
                        borderRadius: "10px",
                        "&:hover": { backgroundColor: 'inherit' },
                        color: darkmode.value ? "lightskyblue" : "blue"
                    }}
                    disableElevation
                    disableRipple
                    onClick={() => { openForgetPassword(); handleClose() }}
                />
            </DialogActions>
        </Dialog >
    )
}

export default SignUpDialog
import { Box, Button, Divider, Link, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import DownloadsSkeleton from './DownloadsSkeleton';
import axios from 'axios'
import { UserApi } from '../../../api/userApi';
import useDarkMode from 'use-dark-mode';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import goodIcon from "../../../icons/good.png"
import badIcon from "../../../icons/bad.png"
import useSnackbar from '../../../hooks/useSnackbar';
function DashboardDownloads() {
    const { showSnackbar } = useSnackbar();
    const darkmode = useDarkMode();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [rating, setRating] = useState(false);
    const queryClient = useQueryClient();
    const { data: downloads, isLoading, } = useQuery({
        queryKey: ["getDashboardDownloads", { page }],
        gcTime: 0,
        staleTime: 0,
        queryFn: async () => {
            try {
                const result = await UserApi.getDownloads(page)
                setPageCount(Math.ceil(result?.total / itemsPerPage) > 5 ? 5 : Math.ceil(result?.total / itemsPerPage));
                setTotalData(result?.total);
                setRating(false);
                return result?.downloads;
            } catch (error) {
                showSnackbar("Server error.");
            }
        }
    })
    const handlePageClick = (event) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        setPage(event.selected);
    };
    const handleRate = (rate, id) => {
        if (!rating) {
            setRating(id);
            axios.post(process.env.REACT_APP_API_URL + "/api/rateSub", { id: id, rate: rate }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    queryClient.invalidateQueries({
                        queryKey: ["getDashboardDownloads"]
                    })
                } else if (res.data.errMsg) {
                    setRating(false)
                    showSnackbar(res.data.errMsg);
                }
            }).catch(err => {
                console.log(err);
                setRating(false);
            })
        }
    }
    const handleRemoveRate = (id) => {
        if (!rating) {
            setRating(id);
            axios.post(process.env.REACT_APP_API_URL + "/api/rateSub", { id: id, remove: true }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    queryClient.invalidateQueries({
                        queryKey: ["getDashboardDownloads"]
                    })
                } else if (res.data.errMsg) {
                    setRating(false)
                    showSnackbar(res.data.errMsg);
                }
            }).catch(err => {
                setRating(false);
            })
        }
    }
    return (
        <Box sx={{ minHeight: "60vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1.5rem", mt: "5rem", color: darkmode.value ? 'white' : "black", px: "3rem" }}>
            {isLoading ? <DownloadsSkeleton /> : downloads.length > 0 ? downloads.sort((a, b) => new Date(b.date) - new Date(a.date)).map(x => (
                <>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "50px", gap: "3rem", fontFamily: "Regular-Family" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", wordBreak: "break-word", width: "65%" }}>
                            <Link href={x.fullLink} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(x.fullLink) } }}>
                                {x.name}
                            </Link>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", textWrap: "nowrap", minWidth: "20%", flex: 1 }}>

                            <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>by <Link href={'/u/' + x.uploaderId} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/u/' + x.uploaderId) } }} sx={{ textDecoration: "none", fontWeight: "bold", color: darkmode.value ? "white" : "black" }}><Tooltip placement='top' title={x.uploaderName}>{x.uploaderName}</Tooltip></Link></Typography>
                            {x.contribs.map(c => (
                                <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>by <Link href={'/u/' + c.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/u/' + c.id) } }} sx={{ textDecoration: "none", fontWeight: "bold", color: darkmode.value ? "white" : "black" }}><Tooltip placement='top' title={c.username}>{c.username}</Tooltip></Link></Typography>
                            ))}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", height: "100%", gap: "0.5rem", width: "25%" }}>
                            <Button
                                onClick={() => { x.rated === true ? handleRemoveRate(x.id) : handleRate('good', x.id) }}
                                disableRipple
                                disabled={x.rated === false || rating === x.id}
                                variant="contained"
                                color="success"
                                sx={{
                                    color: darkmode.value ? "white" : 'initial',
                                    bgcolor: x.rated === true ? "#00828187" : darkmode.value ? "#008281" : "white",
                                    height: "50px",
                                    width: "130px",
                                    textTransform: "none",
                                    padding: '0px',
                                    py: "0.35rem",
                                    borderRadius: "0px",
                                    borderTopLeftRadius: "15px",
                                    borderBottomLeftRadius: "15px",
                                    "&:hover": x.rated === true ? {
                                        boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                        backgroundColor: "#008281ba"
                                    } : {
                                        backgroundColor: "#008281ba"
                                    },
                                    boxShadow: x.rated === true ? "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)" : "2px 2px 5px 0px #0000001A",
                                }}>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <img alt='good' src={goodIcon} width={'30px'} />
                                    <Typography fontFamily={'Regular-Family'} sx={{ fontSize: "15px", fontWeight: "bold" }}>Good</Typography>
                                </Box>

                            </Button>
                            <Button
                                onClick={() => { x.rated === false ? handleRemoveRate(x.id) : handleRate('bad', x.id) }}
                                fullWidth
                                disableRipple
                                disabled={x.rated === true || rating === x.id}
                                variant="contained"
                                color="success"
                                sx={{
                                    color: darkmode.value ? "white" : 'initial',
                                    bgcolor: x.rated === false ? "#aa0b0b" : darkmode.value ? "#DF2D2D" : "white",
                                    height: "50px",
                                    width: "130px",
                                    textTransform: "none",
                                    padding: '0px',
                                    py: "0.35rem",
                                    borderRadius: "0px",
                                    borderTopRightRadius: "15px",
                                    borderBottomRightRadius: "15px",
                                    "&:hover": x.rated === false ? {
                                        boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                        backgroundColor: "#aa0b0b"
                                    } : {
                                        backgroundColor: "#aa0b0b"
                                    },
                                    boxShadow: x.rated === false ? "inset 0px 2px 4px -1px rgba(0,0,0,0.2),inset 0px 4px 5px 0px rgba(0,0,0,0.14),inset 0px 1px 10px 0px rgba(0,0,0,0.12)" : "2px 2px 5px 0px #0000001A",
                                }}
                            >
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <img alt='good' src={badIcon} width={'30px'} />
                                    <Typography fontFamily={'Regular-Family'} sx={{ fontSize: "15px", fontWeight: "bold" }}>Bad</Typography>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                    <Divider sx={{ borderColor: darkmode.value ? "rgb(255,255,255,0.35)" : "rgb(0,0,0,0.35)" }} flexItem />
                </>
            )) :
                <Typography sx={{ fontSize: "80px" }}>
                    No Downloads
                </Typography>
            }
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                {
                    totalData > 10 ? <ReactPaginate
                        containerClassName={darkmode.value ? "allsubs-pagination dark-mode" : "allsubs-pagination"}
                        breakLabel="..."
                        breakLinkClassName='breaklink'
                        nextLabel="»"
                        nextClassName='nextpage'
                        previousClassName='prevpage'
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        previousLabel="«"
                        forcePage={page || 0}
                        renderOnZeroPageCount={null}
                        pageClassName='page'
                        pageLinkClassName='pagelink'
                        activeClassName='active'
                    /> : <></>
                }
            </Box>
        </Box>
    )
}

export default DashboardDownloads
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Fonts/Lato-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Fonts/Lato-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Fonts/Lato-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Fonts/Vazirmatn-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Fonts/Vazirmatn-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Regular-Family';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
    unicode-range: U+0020-007F;
}

@font-face {
    font-family: 'Medium-Family';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
    unicode-range: U+0020-007F;
}

@font-face {
    font-family: 'Bold-Family';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
    unicode-range: U+0020-007F;
}

@font-face {
    font-family: 'Regular-Family';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    unicode-range: U+0600-06FF;
}

@font-face {
    font-family: 'Medium-Family';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    unicode-range: U+0600-06FF;
}

@font-face {
    font-family: 'Bold-Family';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
    unicode-range: U+0600-06FF;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,2DAAoD;IACpD,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;IAC5B,2DAAmD;IACnD,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,2DAAiD;IACjD,0BAA0B;AAC9B;;AAEA;IACI,6BAA6B;IAC7B,+DAA2D;IAC3D,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;IAC5B,+DAA2D;IAC3D,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,+DAAyD;IACzD,0BAA0B;AAC9B","sourcesContent":["@font-face {\r\n    font-family: 'Regular-Family';\r\n    src: url('./Fonts/Lato-Regular.woff') format('woff');\r\n    unicode-range: U+0020-007F;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Medium-Family';\r\n    src: url('./Fonts/Lato-Medium.woff') format('woff');\r\n    unicode-range: U+0020-007F;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Bold-Family';\r\n    src: url('./Fonts/Lato-Bold.woff') format('woff');\r\n    unicode-range: U+0020-007F;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Regular-Family';\r\n    src: url('./Fonts/Vazirmatn-Medium.ttf') format('truetype');\r\n    unicode-range: U+0600-06FF;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Medium-Family';\r\n    src: url('./Fonts/Vazirmatn-Medium.ttf') format('truetype');\r\n    unicode-range: U+0600-06FF;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Bold-Family';\r\n    src: url('./Fonts/Vazirmatn-Bold.ttf') format('truetype');\r\n    unicode-range: U+0600-06FF;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import './Upload.css'
import useDarkMode from 'use-dark-mode';
function CommentInput({ text, setText, disabled }) {
    const darkmode = useDarkMode(false);
    const onChange = (e) => {
        setText(e.target.value)
    }
    return (
        <Box sx={{ marginTop: "1rem", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "0.5rem", fontWeight: "bold", color: darkmode.value ? "white" : "initial" }}>Caption</Typography>
            <Box>
                <TextField
                    disabled={disabled}
                    sx={{ minWidth: "80%", bgcolor: darkmode.value ? "#525252" : "white" }}
                    minRows={2}
                    multiline
                    inputProps={{ dir: "auto", style: { fontFamily: "Regular-Family" } }}
                    InputProps={{ style: { fontFamily: "Regular-Family", color: darkmode.value ? "white" : 'black' } }}
                    value={text}
                    onChange={onChange}
                >

                </TextField>
            </Box>

        </Box >
    )
}

export default CommentInput
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { IconButton, InputBase, Paper } from '@mui/material'
import Search from '@mui/icons-material/Search'
import Chats from './Chats';
import Messages from './Messages';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios'
import './Messages.css';
import NewChatModal from './NewChatModal';
import useDarkMode from 'use-dark-mode';
import { useQueryClient } from '@tanstack/react-query';
const BoldFont = 'Regular-Family';

function DashboardMessages() {
    const darkmode = useDarkMode();
    const [loading, setLoading] = useState(true);
    const [chats, setChats] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentChat, setCurrentChat] = useState(null);
    const [visibleChats, setVisibleChats] = useState([]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const filterChats = async (e) => {
        setVisibleChats(chats.filter(x => x.targetName.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    const queryClient = useQueryClient();
    const handleReadAll = (chatid) => {
        const newChats = [...chats]
        const newChats2 = [...visibleChats]
        const chat = newChats.find(x => x.chatId === chatid);
        const chat2 = newChats.find(x => x.chatId === chatid);
        if (chat) {
            chat.unreads = 0;
        }
        if (chat2) {
            chat2.unreads = 0;
        }
        setChats(newChats);
        setVisibleChats(newChats2);
        axios.post(process.env.REACT_APP_API_URL + "/api/readAllMessages", { chatid: chatid }, { withCredentials: true }).then(res => {
            queryClient.invalidateQueries({
                queryKey: ['getUnreadMessages']
            })
        }).catch(err => {
            console.log({ err });
        })
    }
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/getChats", { withCredentials: true }).then(res => {
            if (res.data.success) {
                setChats(res.data.chats);
                setVisibleChats(res.data.chats);
                setLoading(false)
            }
        }).catch(err => {

        })
        return;
    }, [])
    return (
        <>
            <NewChatModal setChats={setChats} handleClickOpen={handleClickOpen} open={open} handleClose={handleClose} setCurrentChat={setCurrentChat} setVisibleChats={setVisibleChats} />
            <Box sx={{ display: "flex", width: "100%", height: "800px" }}>
                <Box sx={{ width: "27rem", height: "100%", backgroundColor: darkmode.value ? "#2a2a2a" : "#232D3F", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <Paper variant="elevation" elevation={1} sx={{ m: "1rem", backgroundColor: "rgb(255,255,255,0.24)", display: "flex" }}>
                        <InputBase
                            startAdornment={<Search sx={{ fontSize: "2.2rem", p: "0.5rem", color: "initial" }} />}
                            sx={{ fontSize: "1.5rem", fontFamily: BoldFont, color: "cornsilk" }}
                            placeholder='Search...'
                            onChange={filterChats}
                            endAdornment={
                                <IconButton onClick={handleClickOpen} disableRipple size='small' sx={{ backgroundColor: "#1976d2", color: "white", mx: "0.5rem", "&:hover": { opacity: "0.8" } }}>
                                    <AddIcon />
                                </IconButton>}
                        >
                        </InputBase>
                    </Paper>
                    <Box sx={{ display: "flex", p: "1rem", flexDirection: "column", overflowY: "auto", overflowX: "hidden", justifyContent: "flex-start", mb: "0.5rem" }} className="scrollCustom">
                        <Chats chats={visibleChats} currentChat={currentChat || null} setCurrentChat={setCurrentChat} loading={loading} handleReadAll={handleReadAll} />
                    </Box>
                </Box>
                <Box sx={{ backgroundColor: darkmode.value ? "#2a2a2a" : "#232D3F", height: "100%", width: "100%" }}>
                    {currentChat ? <Messages chats={chats} setVisibleChats={setVisibleChats} setChats={setChats} chat={chats.filter(x => x.chatId === currentChat.chatId)[0]}></Messages> : <></>}
                </Box>
            </Box>
        </>
    )
}

export default DashboardMessages
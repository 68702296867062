import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainApi } from '../api/mainApi';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Link, Paper, Typography } from '@mui/material';
import Grid from 'react-loading-icons/dist/esm/components/grid';
import useDarkMode from 'use-dark-mode';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FullSearch() {
    const params = useParams();
    const navigate = useNavigate();
    const darkmode = useDarkMode();
    const { data: searchResult, isFetching: searchResultIsLoading } = useQuery({
        queryKey: ["searchMoviesUpload", params.query],
        queryFn: async () => {
            return await MainApi.searchMoviesFull(params.query);
        },
    });
    if (searchResultIsLoading) {
        return (
            <Box sx={{ height: "800px", display: "flex", justifyContent: "center", alignItems: "center", color: "black" }}>
                <Grid fill={darkmode.value ? "white" : 'black'} />
            </Box>
        )
    }
    return (
        <>
            <Box sx={{ mt: "5rem", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", mx: "10.5%", gap: "0.5rem" }} >
                <Typography sx={{ fontWeight: "bold", fontSize: "30px", color: darkmode.value ? 'white' : "#21495F" }}>
                    Search Results
                </Typography>
                <Divider flexItem sx={{ borderColor: darkmode.value ? "white" : "black", borderBottomWidth: "2px" }} />
                <Box sx={{ display: "flex", width: "100%", gap: "3rem", color: darkmode.value ? 'white' : "initial" }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "1rem", flex: 1, maxHeight: "70vh", overflowY: "scroll" }} className="scrollCustom">
                        <Typography sx={{ fontFamily: "Regular-Family" }}>Movies (#{searchResult.found.filter(x => x.type === "Movie").length})</Typography>
                        {searchResult.found.filter(x => x.type === "Movie").slice(0, 20).map(found => (
                            <>
                                <Box sx={{ mr: "3rem" }}>
                                    <Link sx={{ display: "contents" }} onClick={(e) => { if (found.type === "Movie") { e.stopPropagation(); e.preventDefault(); navigate(found.fullLink) } }} href={found.type === "Movie" && found.fullLink}>
                                        <Accordion key={found.id} disableGutters sx={{ cursor: "pointer", boxShadow: "none", background: "transparent !important", }} onClick={(e) => { found.type === "Movie" && console.log("navigate") }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ display: found.type === "Movie" ? "none" : "flex" }} />}
                                                sx={{ pointerEvents: found.type === "Movie" ? "none" : "initial", p: "0px", "& .MuiAccordionSummary-content": { m: "0px" } }}
                                                onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
                                            >
                                                <Box sx={{ display: "flex", gap: "0.5rem", cursor: "pointer" }} >
                                                    <img src={found.poster} onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = "https://api.subsource.net/notfound.jpg";
                                                    }} width={"80px"} height={"120px"} alt='poster' />
                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                        <Typography >{found.title} ({found.releaseYear})</Typography>
                                                        <Box>
                                                            <Typography >Available Subs: {found.subCount}</Typography>
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ display: 'flex', flexDirection: "column", gap: "0.5rem" }}>
                                                {
                                                    found?.seasons?.map(x => {
                                                        if (x.number === 0) {
                                                            x.number = Infinity
                                                        }
                                                        return x;
                                                    }).sort((a, b) => a.number - b.number).map(season => (
                                                        <Link sx={{ display: "contents" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigate(season.fullLink) }} href={season.fullLink}>
                                                            <Paper sx={{ padding: "0.25rem" }} elevation={2} >
                                                                <Typography sx={{ fontFamily: "Regular-Family", fontSize: "18px" }}>{season.number !== Infinity ? "Season " + season.number : "Extras (Complete Series)"}</Typography>
                                                            </Paper>
                                                        </Link>
                                                    ))
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </Link>
                                    <Divider flexItem sx={{ mt: "1rem" }} />
                                </Box>

                            </>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "1rem", flex: 1, maxHeight: "70vh", overflowY: "scroll" }} className="scrollCustom">
                        <Typography sx={{ fontFamily: "Regular-Family", color: darkmode.value ? 'white' : "initial" }}>TV-Series (#{searchResult.found.filter(x => x.type === "TVSeries").length})</Typography>
                        {searchResult.found.filter(x => x.type === "TVSeries").length === 0 && <Typography sx={{ fontSize: "24px" }}>No Results</Typography>}
                        {searchResult.found.filter(x => x.type === "TVSeries").length > 0 &&
                            searchResult.found.filter(x => x.type === "TVSeries").sort((a, b) => b.fs - a.fs).map(found => (
                                <>
                                    <Box sx={{ mr: "3rem" }}>
                                        <Link sx={{ display: "contents" }} onClick={(e) => { if (found.type === "Movie") { e.stopPropagation(); e.preventDefault(); navigate(found.fullLink) } }} href={found.type === "Movie" && found.fullLink}>
                                            <Accordion key={found.id} disableGutters sx={{ cursor: "pointer", boxShadow: "none", background: "transparent !important", }} onClick={(e) => { found.type === "Movie" && console.log("navigate") }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon sx={{ display: found.type === "Movie" ? "none" : "flex" }} />}
                                                    sx={{ pointerEvents: found.type === "Movie" ? "none" : "initial", p: "0px", "& .MuiAccordionSummary-content": { m: "0px" } }}
                                                    onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
                                                >
                                                    <Box sx={{ display: "flex", gap: "0.5rem", cursor: "pointer" }} >
                                                        <img src={found.poster} onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://api.subsource.net/notfound.jpg";
                                                        }} width={"80px"} height={"120px"} alt='poster' />
                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <Typography >{found.title} ({found.releaseYear})</Typography>
                                                            <Box>
                                                                <Typography >Available Subs: {found.subCount}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ display: 'flex', flexDirection: "column", gap: "0.5rem" }}>
                                                    {
                                                        found?.seasons?.map(x => {
                                                            if (x.number === 0) {
                                                                x.number = Infinity
                                                            }
                                                            return x;
                                                        }).sort((a, b) => a.number - b.number).map(season => (
                                                            <Link sx={{ display: "contents" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigate(season.fullLink) }} href={season.fullLink}>
                                                                <Paper sx={{ padding: "0.25rem" }} elevation={2} >
                                                                    <Typography sx={{ fontFamily: "Regular-Family", fontSize: "18px" }}>{season.number !== Infinity ? "Season " + season.number : "Extras (Complete Series)"}</Typography>
                                                                </Paper>
                                                            </Link>
                                                        ))
                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                        </Link>
                                        <Divider flexItem sx={{ mt: "1rem" }} />
                                    </Box>

                                </>
                            ))
                        }

                    </Box>
                </Box>
            </Box >

        </>
    )
}

export default FullSearch
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material'
import React from 'react'
import TelegramIconColored from '../../icons/telegramColored.svg';
import InstagramColored from '../../icons/instagram.svg'
import TwitterColored from '../../icons/twitterColor.svg'
import FullLogo from "../../icons/logo_full.png";
import FullLogoDark from "../../icons/logo_full_dark.png";
import EmailIcon from '../../icons/Email_icon.png';
import { useNavigate } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
function Footer() {
    const darkmode = useDarkMode(false);
    const navigate = useNavigate();
    return (
        <Box sx={{ width: "100%", pt: "7rem", pb: "2rem", height: 'auto', marginTop: "auto", background: darkmode.value ? "none" : "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 100%)", color: "black", position: "relative" }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px" }}>
                <Box>
                    <img src={darkmode.value ? FullLogoDark : FullLogo} alt='SubSource' height={100} />
                </Box>

                <Box sx={{ display: "flex", gap: "50px" }}>
                    <Link sx={{ textDecoration: "none", color: darkmode.value ? "#ffffff87" : "#00000091", "&:hover": { textDecoration: "underline", color: darkmode.value ? "white" : "black" } }} href="/dmca" onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/dmca') } window.scrollTo(0, 0); }}>
                        <Typography sx={{ fontFamily: "Regular-Family", fontSize: "25px" }}>DMCA</Typography>
                    </Link>
                    <Link sx={{ textDecoration: "none", color: darkmode.value ? "#ffffff87" : "#00000091", "&:hover": { textDecoration: "underline", color: darkmode.value ? "white" : "black" } }} href="/about" onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/about') }; window.scrollTo(0, 0); }}>
                        <Typography sx={{ fontFamily: "Regular-Family", fontSize: "25px" }}>About</Typography>
                    </Link>
                    <Link sx={{ textDecoration: "none", color: darkmode.value ? "#ffffff87" : "#00000091", "&:hover": { textDecoration: "underline", color: darkmode.value ? "white" : "black" } }} href="/terms" onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/terms') }; window.scrollTo(0, 0); }}>
                        <Typography sx={{ fontFamily: "Regular-Family", fontSize: "25px" }}>Terms & Conditions</Typography>
                    </Link>
                    <Link sx={{ textDecoration: "none", color: darkmode.value ? "#ffffff87" : "#00000091", "&:hover": { textDecoration: "underline", color: darkmode.value ? "white" : "black" } }} href="/terms" onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/policy') }; window.scrollTo(0, 0); }}>
                        <Typography sx={{ fontFamily: "Regular-Family", fontSize: "25px" }}>Privacy Policy</Typography>
                    </Link>
                    <Link sx={{ textDecoration: "none", color: darkmode.value ? "#ffffff87" : "#00000091", "&:hover": { textDecoration: "underline", color: darkmode.value ? "white" : "black" } }} href="/contactus" onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/contactus') }; window.scrollTo(0, 0); }}>
                        <Typography sx={{ fontFamily: "Regular-Family", fontSize: "25px" }}>Contact us</Typography>
                    </Link>
                </Box>
                <Box sx={{ display: "flex", gap: "50px" }} id="ContactUs">
                    <Tooltip title="@SubSource_Net" placement='top'>
                        <IconButton disableRipple href='https://t.me/SubSource_Net' target='_blank'>
                            <img src={TelegramIconColored} width={50} alt='Telegram' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="@SubSource_Net" placement='top'>
                        <IconButton disableRipple href='https://instagram.com/subsource_net' target='_blank'>
                            <img src={InstagramColored} width={50} alt='Instagram' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="@SubSource_Net" placement='top'>
                        <IconButton disableRipple href='https://twitter.com/SubSource_Net' target='_blank'>
                            <img src={TwitterColored} width={50} alt='Twitter' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="support@subsource.net" placement='top'>
                        <IconButton disableRipple href='mailto:support@subsource.net'>
                            <img src={EmailIcon} width={50} alt='Email' />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                </Box>
                <Box >
                    <Typography textAlign={"center"} fontFamily={"Regular-Family"} fontSize={"15px"} fontWeight={'200'} color={darkmode.value ? "white" : "black"}>© {new Date().getFullYear()} SubSource. All Rights Reserved.</Typography>
                </Box>
            </Box>

        </Box>
    )
}

export default Footer
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Button, Paper, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp';

const types = [
    "Select Release Type",
    "WEB",
    "BLURAY",
    "HDTV",
    "CAM/HDTS/HDRIP",
    "UNOFFICIAL/LEAKED",
    "DVDRip",
    "Other",
];

function ReleaseTypeFilter({ typesFilter, setTypesFilter, urlFilter, setLoading, reload }) {
    const [isEditing, setIsEditing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeType = (event) => {
        const { target: { value } } = event;
        setTypesFilter(typeof value === 'string' ? value.split(',') : value);
    };

    const handleSaveFilters = () => {
        setIsEditing(false);
        setLoading(true);
        try {
            window.localStorage.setItem('release_types', JSON.stringify(typesFilter));
        } catch (error) {
            console.log({ error });
        }
        reload();
    };

    const handleScroll = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            {isEditing ? (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                    <FormControl style={{ minWidth: 120 }} size='small'>
                        <InputLabel htmlFor='selected-type'>Type</InputLabel>
                        <Select
                            open={isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            value={typesFilter}
                            onChange={handleChangeType}
                            inputProps={{ name: 'type', id: 'selected-type' }}
                            size="small"
                            multiple
                            input={<OutlinedInput label="Type" />}
                            renderValue={(selected) => selected.length === 0 ? "All Release Types" : selected.join(', ')}
                            displayEmpty={false}
                            MenuProps={{
                                PaperProps: {
                                    onMouseDown: (e) => e.preventDefault(),
                                },
                                disableScrollLock: true,
                            }}
                            sx={{ width: "250px" }}
                        >
                            {types.map((type, index) => (
                                <MenuItem key={type} value={type} disabled={index === 0}>
                                    <Checkbox checked={typesFilter.indexOf(type) > -1} />
                                    <ListItemText primary={type} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant='outlined' disableRipple sx={{ height: "auto" }} onClick={handleSaveFilters}>
                        <SaveIcon />
                    </Button>
                </Box>
            ) : (
                <Paper>
                    <Button
                        disabled={urlFilter !== undefined}
                        onClick={() => setIsEditing(true)}
                        disableRipple
                        sx={{
                            textWrap: "nowrap",
                            '&.Mui-selected': {
                                backgroundColor: "#0080808f",
                                boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)",
                                "&:hover": { backgroundColor: "#0080808f" },
                            },
                            textTransform: "none",
                            "&:hover": { backgroundColor: "initial" },
                            py: "0.5rem",
                            px: "0.75rem"
                        }}
                    >
                        <FilterAltSharpIcon sx={{ mr: "0.5rem" }} />
                        <Typography variant='h7'>
                            {urlFilter !== undefined ? urlFilter : typesFilter.length === 0 ? "All Release Types" : typesFilter.length === 1 ? typesFilter[0] : `${typesFilter[0]} And ${typesFilter.length - 1} more.`}
                        </Typography>
                    </Button>
                </Paper>
            )}
        </Box>
    );
}

export default ReleaseTypeFilter;

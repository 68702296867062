import axios from "axios"

export const api = axios.create({
    withCredentials: true,
    baseURL: "https://api.subsource.net/",
})

const errorHandler = (error) => {
    const statusCode = error.response?.status

    if (statusCode && statusCode !== 401) {
        //console.error(error)
    }

    return Promise.reject(error)
}

api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})
import { Box, Button, Divider, Link, Paper, Rating, Tooltip, Typography } from '@mui/material'
import axios from 'axios';
import SubtitlesRoundedIcon from '@mui/icons-material/SubtitlesRounded';
import React, { useContext, useEffect, useState } from 'react'
import { Grid } from 'react-loading-icons';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ContribIcon from '../../../icons/contribIcon.png';
import ReactTimeAgo from 'react-time-ago'
import "../../../css/Dashboard.css"
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../Contexts/userContext';
import useDarkMode from 'use-dark-mode';
import useSnackbar from '../../../hooks/useSnackbar';
const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? 1 : ((x > y) ? -1 : 0));
    });
}
function DashboardMySubscene() {
    const { showSnackbar } = useSnackbar();
    const darkmode = useDarkMode();
    const navigate = useNavigate();
    const [subs, setSubs] = useState([]);
    const [subsLoading, setSubsLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [ratings, setRatings] = useState([]);
    const [ratingsLoading, setRatingsLoading] = useState(true);
    const [subCount, setsubCount] = useState(0);
    const [rateCount, setRateCount] = useState(0);
    const [contribs, setContribs] = useState(0);
    const user = useUserContext();
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/user/mySubsource/getSubs", { withCredentials: true }).then(res => {
            setSubs(res.data.subs);
            setsubCount(res.data.count)
            setContribs(res.data.contribsLength);
            setSubsLoading(false)
            setRatings(sortByKey(res.data.rates, 'date') || []);
            setRateCount(res.data.ratesCount || 0)
            setRatingsLoading(false);
        }).catch(err => {
            console.log(err)
        });
    }, [])
    const handleSubDelete = (id) => {
        var answer = window.confirm("Confirm Deleting Sub?");
        if (answer) {
            setDeleting(true);
            axios.post(process.env.REACT_APP_API_URL + "/api/user/subs/delete", { subId: id }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    return window.location.reload();
                } else {
                    console.log(res.data.err || "Error.")
                }
                setDeleting(false);
            }).catch(err => {
                showSnackbar("Error.");
                console.log({ err })
                setDeleting(false);
            });
        }
    }
    return (
        <Box sx={{ paddingTop: "3rem", display: "flex", gap: "1rem", mb: "1rem", width: "850px", margin: "0 auto", minHeight: "80vh" }}>
            <Box sx={{ width: "100%", p: "0.5rem" }}>
                <Box className="button-24 contribSub" sx={{ backgroundColor: darkmode.value ? "#44474a !important" : "initial", width: "fit-content !important", ml: "7px !important", py: "0.5rem !important", display: subsLoading || ratingsLoading ? "none" : "flex", "&:active": { boxShadow: "inset 0px 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0px 4px 5px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important" }, boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important" }}>
                    <Link sx={{ display: "contents", textDecoration: "none" }} href={"/u/" + user.id + "/ratings"} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + user.id + "/ratings") } }}>
                        <Typography fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ color: darkmode.value ? "white" : "black", fontSize: "1.4rem" }}>{rateCount} Ratings</Typography>
                    </Link>
                </Box>
                {ratingsLoading || subsLoading ?
                    <>
                        <Box sx={{ height: "80vh", display: "flex", justifyContent: "center", }}>
                            <Box sx={{ display: "flex", alignItems: "center", margin: "0 auto" }}>
                                <Grid fill={darkmode.value ? "white" : 'black'} fontSize={'0.5rem'} />
                            </Box>
                        </Box>
                    </>
                    :
                    <>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", p: "0.5rem", mt: "1rem" }}>
                            {ratings.length > 0 ? ratings.map(x => (
                                <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: "0.25rem", maxWidth: "100%", py: "0.5rem", mb: "0.25rem" }} elevation={5}>
                                    <Box sx={{ display: "flex", textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Tooltip placement='top' title={`Rated ${x.good ? "Good" : "Bad"}`}>
                                            <SubtitlesRoundedIcon sx={{ mr: "0.25rem", color: x.good ? "teal" : "#dd0303", fontSize: "1.25rem", mt: "3px" }} />
                                        </Tooltip>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Link href={x.subLink} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(x.subLink) } }} sx={{ textWrap: "nowrap", textDecoration: "none", overflow: "hidden", textOverflow: "ellipsis", fontFamily: "Regular-Family", fontSize: "1.05rem", display: "flex", alignItems: "center" }}>{x.subMovieName}</Link>
                                            <span style={{ fontSize: "0.8rem", opacity: "0.65", textTransform: "capitalize", marginLeft: "5px", fontFamily: "Regular-Family" }}>• <ReactTimeAgo date={new Date(x.date)} /></span>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", mr: "1rem" }}>
                                        <Typography sx={{ justifySelf: "flex-end", mr: "0.5rem" }}> By</Typography>
                                        <Link href={'/u/' + x.fromId} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/u/' + x.fromId) } }} sx={{ justifySelf: "flex-end", mr: "0.5rem", fontFamily: "Regular-Family" }}>{x.fromName}</Link>
                                        <Rating value={x.fromRating} max={5} precision={0.5} readOnly sx={{ display: x.fromRating !== -1 ? 'flex' : "none", alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>
                                    </Box>
                                </Paper>
                            )) : <Typography sx={{ color: darkmode.value ? "white" : "initial" }}>No Ratings Found.</Typography>}
                        </Box>
                        <Divider sx={{ my: "2rem" }} />
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "1.5rem", paddingLeft: "0.5rem" }}>
                                <Link sx={{ display: "contents", textDecoration: "none" }} href={"/u/" + user.id + "/subtitles"} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + user.id + "/subtitles") } }}>
                                    <Box className="button-24 contribSub" sx={{ backgroundColor: darkmode.value ? "#44474a !important" : "inital", display: subsLoading || ratingsLoading ? "none" : "flex", "&:active": { boxShadow: "inset 0px 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0px 4px 5px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important" }, boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important" }}>
                                        <Typography fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ color: darkmode.value ? "white" : "black", fontSize: "1.4rem", py: "0.5rem !important" }}>{subCount} Subtitles </Typography>
                                        <AddCircleSharpIcon sx={{ fontSize: "1.1rem", ml: "0.25rem", mt: '1px', color: darkmode.value ? "#868ab3" : "#484c7a" }}></AddCircleSharpIcon>
                                        <Typography fontFamily={"Regular-Family"} fontWeight={"bold"} sx={{ ml: "0.15rem", fontSize: "1.2rem", color: darkmode.value ? "#868ab3" : "#484c7a" }}>{contribs}</Typography>
                                    </Box>
                                </Link>

                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.75rem", p: "0.5rem", mt: "1rem" }}>
                                {
                                    subs.length > 0 ?
                                        subs.map((x, index) => (
                                            <Paper key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: "0.25rem", flexDirection: "row" }} elevation={5}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <img src={x.poster} onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = "https://api.subsource.net/notfound.jpg";
                                                    }} style={{ width: "100px", aspectRatio: "1/1.5", borderRadius: "10px" }} alt={x.subMovieName}></img>
                                                    <Box sx={{ display: "flex", flexDirection: "column", ml: "0.5rem", justifyContent: "space-between", py: "0.5rem", paddingTop: "0.75rem", textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                                            <Tooltip placement='top' title={x.subMovieName}>
                                                                <Link href={x.fullLink} sx={{ fontFamily: "Regular-Family", fontSize: "1rem", maxWidth: "335px", textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textDecoration: "none" }}>{x.subMovieName}</Link>
                                                            </Tooltip>
                                                            <Typography sx={{ fontFamily: "Regular-Family", fontSize: "1rem", mb: "0.35rem" }}>{x.subLang}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', gap: "1rem" }}>
                                                            <Button onClick={() => { handleSubDelete(x.id) }} disabled={deleting || !x.userUploaded} color='error' variant='outlined' sx={{ px: "1px", py: "1px", textTransform: "none", width: "fit-content", borderRadius: "15px", fontSize: "0.75rem", minWidth: "50px", mb: "0.25rem" }}>Delete</Button>
                                                            <Button href={'/edit/' + x.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/edit/' + x.id) } }} disabled={deleting || !x.userUploaded} variant='outlined' sx={{ px: "1px", py: "1px", textTransform: "none", width: "fit-content", borderRadius: "15px", fontSize: "0.75rem", minWidth: "50px", mb: "0.25rem" }}>Edit</Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: "flex", gap: "2.5rem", mr: "2.5rem" }}>
                                                    <Tooltip placement='top' title="Collaborated">
                                                        <Box sx={{ display: x?.isContrib ? "flex" : "none", alignItems: "center" }}>
                                                            <img src={ContribIcon} alt='Contributed' style={{ width: "20px", height: "15px", filter: darkmode.value ? "invert(1)" : "none" }} />
                                                        </Box>
                                                    </Tooltip>
                                                    <Link href={x?.isContrib ? null : '/ratings/' + x.id} onClick={(e) => { if (!x.isContrib) if (!e.ctrlKey) { e.preventDefault(); navigate('/ratings/' + x.id) } }} sx={{ fontFamily: "Regular-Family", display: "flex", alignItems: "center", textDecoration: "none", maxWidth: "35px", justifyContent: "center", color: x?.isContrib ? '#b9bcbc' : darkmode.value ? 'white' : 'initial' }}><SubtitlesRoundedIcon sx={{ fontSize: "1rem", color: x?.isContrib ? "#b9bcbc" : "teal", mr: "0.25rem" }} />{x.ratings}</Link>
                                                    <Link href={x.fullLink} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(x.fullLink) } }} sx={{ fontFamily: "Regular-Family", display: "flex", alignItems: "center", textDecoration: "none", maxWidth: "35px", justifyContent: "center" }}><ChatRoundedIcon sx={{ fontSize: "1rem", color: "gray", mr: "0.25rem" }} />{x.comments}</Link>
                                                    <Link sx={{ fontFamily: "Regular-Family", display: "flex", alignItems: "center", textDecoration: "none", maxWidth: "35px", justifyContent: "center" }}><DownloadRoundedIcon sx={{ fontSize: "1rem", color: "darkred", mt: "1px", mr: "3px" }} />{x.downloads}</Link>
                                                </Box>
                                            </Paper>
                                        ))

                                        :
                                        <Typography sx={{ color: darkmode.value ? "white" : "initial" }}>No Subtitles Found.</Typography>
                                }
                            </Box>
                        </Box>
                    </>
                }
            </Box >
        </Box >
    )
}

export default DashboardMySubscene
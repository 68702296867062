// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollCustom::-webkit-scrollbar {
    width: 12px;
}

.scrollCustom::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #95a5a6;
    border: 1px solid #cacaca;
}

.scrollCustom::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #2c3e50;
}`, "",{"version":3,"sources":["webpack://./src/Components/SeriesSeasons/SeriesSeasons.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".scrollCustom::-webkit-scrollbar {\r\n    width: 12px;\r\n}\r\n\r\n.scrollCustom::-webkit-scrollbar-track {\r\n    border-radius: 8px;\r\n    background-color: #95a5a6;\r\n    border: 1px solid #cacaca;\r\n}\r\n\r\n.scrollCustom::-webkit-scrollbar-thumb {\r\n    border-radius: 8px;\r\n    background-color: #2c3e50;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Box, Divider, Link, Paper, Rating, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FlagIcon from '@mui/icons-material/Flag';
import axios from 'axios'
import NotFound from './NotFound';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid } from 'react-loading-icons';
import useDarkMode from 'use-dark-mode';
import { useUserContext } from '../Contexts/userContext';
import { useQuery } from '@tanstack/react-query';
import { deleteRateApi } from '../api/adminApi';
import RatingReport from '../Components/Reports/RatingReport';
import useSnackbar from '../hooks/useSnackbar';
const mainFont = "Regular-Family"

function RatingsPage() {
    window.scrollTo(0, 0);
    const { showSnackbar } = useSnackbar();
    const darkmode = useDarkMode();
    const params = useParams();
    const navigate = useNavigate();
    const user = useUserContext();
    const [showReport, setShowReport] = useState(false);
    const [reportRate, setReportRate] = useState({});
    const toggleReportShow = () => setShowReport(p => !p);
    const [loading, setLoading] = useState(true);
    const [deleteCreds, setDeleteCreds] = useState(undefined);
    const [data, setData] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const { isFetching: isDeleting, refetch } = useQuery({
        queryKey: ["deleteRate", deleteCreds],
        queryFn: async () => {
            try {
                console.log("Fetching");
                console.log({ deleteCreds });
                await deleteRateApi(deleteCreds.subId, deleteCreds.ratedFrom);
                window.location.reload();
            } catch (error) {
                showSnackbar("Server Error.");
            }
        },
        enabled: false
    })
    useEffect(() => {
        if (deleteCreds !== undefined)
            refetch();
    }, [deleteCreds, refetch])
    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + "/api/getSubRatings", { subId: params?.id }, { withCredentials: true }).then(res => {
            if (res.data.success) {
                setData(res.data);
            } else {
                showSnackbar("Error");
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 404) {
                setNotFound(true)
            }
            console.log({ err })
        })
        // eslint-disable-next-line
    }, []);
    const handleDeleteRate = (subId, ratedFrom) => {
        if (!isDeleting) {
            const answer = window.confirm("Confirm Deleting Rate from " + ratedFrom + " ?")
            if (answer) {
                setDeleteCreds({ subId, ratedFrom })
            }
        }
    }

    if (notFound) {
        return (
            <NotFound />
        )
    }
    if (loading) {
        return (
            <>
                <Box sx={{ height: "80vh", display: "flex", justifyContent: "center", }}>
                    <Box sx={{ display: "flex", alignItems: "center", margin: "0 auto" }}>
                        <Grid fill={darkmode.value ? "white" : 'black'} fontSize={'0.5rem'} />
                    </Box>
                </Box>
            </>
        )
    }
    return (
        <>
            <RatingReport show={showReport} toggleShow={toggleReportShow} ratedFrom={reportRate.ratedFrom} subId={reportRate.subId} />
            <Box sx={{ display: "flex", gap: "2rem", flexDirection: "row", mt: "2rem", px: { xs: "3%", md: "3%", lg: "13vw" }, minWidth: "1000px", color: darkmode.value ? 'white' : "initial" }}>
                <Box sx={{ width: "70%" }}>
                    <Link sx={{ display: "contents" }} href={data.sub.fullLink} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(data.sub.fullLink) } }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Paper sx={{ width: "fit-content", py: "0.25rem", px: "0.25rem", m: "0.25rem", backgroundColor: "rgb(255,255,255,0.6)", transform: "scale(0.75)" }}><ArrowBackIosNewIcon sx={{ fontSize: "0.75rem", color: "black" }} /></Paper>
                            <Typography fontFamily={mainFont} fontWeight={"bold"} fontSize={"1rem"} sx={{ lineHeight: "unset", color: darkmode.value ? "white" : "black" }}> {"Back To Subtitle"}</Typography>
                        </Box>
                    </Link>
                    <Divider sx={{ borderColor: "rgb(0,0,0,0.35)", marginBottom: "1rem" }} />
                    <Typography variant='h5' fontFamily={mainFont} fontWeight={'bold'}>{data.rates.length} Ratings</Typography>
                    <Box>
                        {data.rates.sort((a, b) => b.ratingsGiven - a.ratingsGiven).map(rate => (
                            <Paper elevation={4} sx={{ paddingRight: ".5rem", height: "30px", my: "0.5rem", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingLeft: "0.5rem" }}>
                                <Link href={"/u/" + rate.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + rate.id) } }} sx={{ display: "contents", textDecoration: "none", color: darkmode.value ? 'white' : 'black' }} >
                                    <Typography sx={{ fontSize: "0.85rem", display: "flex", width: "45%" }}>{rate.username}
                                        {rate?.rated === -1 || rate?.rated === null || rate?.rated === undefined ? <></> : <Rating value={rate.rated} max={5} precision={0.5} readOnly sx={{ marginLeft: "10px", alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>}
                                    </Typography>
                                </Link>
                                <Box sx={{ display: "flex", width: "30%", alignItems: "center" }}>
                                    <Typography sx={{ width: "40px", color: rate.rate === false ? darkmode.value ? "red" : "red" : darkmode.value ? "Green" : "Green", fontFamily: mainFont }}>{rate.rate ? "Good" : "Bad"} </Typography>
                                    <FlagIcon sx={{ ml: "0.25rem", "&:hover": { color: "red" }, cursor: "pointer", fontSize: '0.9rem' }} onClick={() => { setReportRate({ ratedFrom: rate.id, subId: params.id }); toggleReportShow() }} />
                                    {user?.privileges?.includes('3') && <DeleteOutlineIcon sx={{ ml: "0.25rem", color: "darkred", "&:hover": { color: "red" }, cursor: "pointer", fontSize: '1rem' }} onClick={() => handleDeleteRate(params.id, rate.id)} />}
                                </Box>
                                <Typography sx={{ width: "25%", fontSize: "0.85rem" }}>Ratings Given: {rate.ratingsGiven}</Typography>
                            </Paper>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ width: "30%" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", visibility: "hidden" }}>
                        <Paper sx={{ width: "fit-content", py: "0.25rem", px: "0.25rem", m: "0.25rem", backgroundColor: "rgb(255,255,255,0.6)", transform: "scale(0.75)" }}><ArrowBackIosNewIcon sx={{ fontSize: "0.75rem", color: "black" }} /></Paper>
                        <Typography fontFamily={mainFont} fontWeight={"bold"} fontSize={"1rem"} sx={{ lineHeight: "unset" }}> </Typography>
                    </Box>
                    <Divider sx={{ borderColor: "rgb(0,0,0,0.35)", marginBottom: "2rem" }} />
                    <Typography sx={{ fontWeight: "bold" }}>{data.sub.movieName}</Typography>
                    {data.sub.releases.map(r => (
                        <Tooltip title={r} arrow placement="top-start" >
                            <Paper elevation={3} sx={{ py: "0.25rem", my: "0.25rem", width: "95%", px: "0.5rem", backgroundColor: darkmode.value ? "#181818" : "ghostwhite", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>
                                <Typography fontFamily={mainFont} fontSize={'0.75rem'} sx={{ lineHeight: "1rem", overflow: "hidden", textOverflow: "ellipsis" }} >{r}</Typography>
                            </Paper>
                        </Tooltip>
                    ))}
                    <Typography sx={{ fontWeight: "bold", mt: "0.5rem", mb: "0.5rem" }}>Language: {data.sub.lang}</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box>
                            <Typography sx={{ fontWeight: "bold", marginRight: "0.55rem" }}>Owner: </Typography>
                        </Box>
                        <Box>
                            {[data.owner].concat(data.contribs).map(author => (
                                <Link sx={{ display: "contents", "&:visited": { color: darkmode.value ? "#a561e3" : "#551A8B" } }} href={"/u/" + author.id} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/u/" + author.id) } }}>
                                    <Box sx={{ display: "flex", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>

                                        <Typography sx={{ fontWeight: "bold", width: "145px", overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis" }}>{author.username}</Typography>
                                        {author?.rate === -1 || author?.rate === null || author?.rate === undefined ? <></> : <Rating value={author.rate} max={5} precision={0.5} readOnly sx={{ marginLeft: "6px", mb: "2px", alignSelf: "center", "& .MuiRating-decimal": { marginLeft: "-2px", color: "teal" } }} size='small' ></Rating>}

                                    </Box>
                                </Link>
                            ))}
                        </Box>
                    </Box>

                </Box>
            </Box >
        </>

    )
}

export default RatingsPage
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tickets-box {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 15px 20px;
    border-left: solid 1px rgba(0, 0, 0, 0.65);
    border-bottom: solid 1px rgba(0, 0, 0, 0.65);
}

.tickets-box.dark-mode {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 15px 20px;
    border-left: solid 1px rgba(255, 255, 255, 0.65);
    border-bottom: solid 1px rgba(255, 255, 255, 0.65);
    background-color: rgb(36, 36, 36);
}

.tickets-box:hover {
    cursor: pointer;
}

.tickets-box.active {
    background-color: hsla(0, 0%, 50%, 0.15);
}

.tickets-box.active.dark-mode {
    background-color: hsla(0, 0%, 50%, 0.435);
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Tickets/DashboardTickets.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,0CAA0C;IAC1C,4CAA4C;AAChD;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,gDAAgD;IAChD,kDAAkD;IAClD,iCAAiC;AACrC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".tickets-box {\r\n    align-self: stretch;\r\n    flex-grow: 0;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    border-radius: 10px;\r\n    padding: 15px 20px;\r\n    border-left: solid 1px rgba(0, 0, 0, 0.65);\r\n    border-bottom: solid 1px rgba(0, 0, 0, 0.65);\r\n}\r\n\r\n.tickets-box.dark-mode {\r\n    align-self: stretch;\r\n    flex-grow: 0;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    border-radius: 10px;\r\n    padding: 15px 20px;\r\n    border-left: solid 1px rgba(255, 255, 255, 0.65);\r\n    border-bottom: solid 1px rgba(255, 255, 255, 0.65);\r\n    background-color: rgb(36, 36, 36);\r\n}\r\n\r\n.tickets-box:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n.tickets-box.active {\r\n    background-color: hsla(0, 0%, 50%, 0.15);\r\n}\r\n\r\n.tickets-box.active.dark-mode {\r\n    background-color: hsla(0, 0%, 50%, 0.435);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Box, Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { MainApi } from '../api/mainApi';
import News from '../Components/HomePage/News/News';
import RecentSubs from '../Components/HomePage/RecentSubs/RecentSubs';
import Movies from '../Components/HomePage/Movies/Movies';
function HomePage() {
    const [movies, setMovies] = useState([]);
    const [series, setSeries] = useState([]);
    const [news, setNews] = useState([]);
    const [recents, setRecents] = useState([]);
    const [loading, setLoading] = useState(true);
    const { data } = useQuery({
        queryKey: ['getHome'],
        staleTime: 60000,
        gcTime: 60000,
        queryFn: async () => {
            try {
                return await MainApi.getHome();
            } catch (error) {
                if (error.response.status === 429) {
                    console.log(error.response.status)
                    document.body.innerHTML = "<div><h1>Too Many Requests</h1></div>"
                }
            }
        },
    })
    useEffect(() => {
        if (data) {
            document.title = `SubSource: Download subtitles for your movies and Tv shows!`;
            setMovies(data.popularMovies.sort((a, b) => b.p - a.p) || []);
            setSeries(data.popularSeries.sort((a, b) => b.p - a.p) || []);
            setRecents(data.recentSubs || []);
            setNews(data.news || []);
            setLoading(false);
        }
    }, [data])

    return (
        <Container sx={{ mt: "2rem", display: "flex", justifyContent: "center", alignItems: "flex-start", mx: "10.5%", gap: "6rem", justifySelf: "center" }} maxWidth="xl">
            <Box sx={{ height: "auto", width: "60%", minWidth: "60%" }}>
                <Movies isLoading={loading} data={movies} type={'Movies'} />
                <Movies isLoading={loading} data={series} type={'Series'} />
                <RecentSubs isLoading={loading} subs={recents} />
            </Box>
            <News isLoading={loading} news={news} />
        </Container>
    )
}

export default HomePage
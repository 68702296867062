// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
    overflow: hidden;
    min-height: 9rem;
}

.dropzone-preview {
    min-height: 50px;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
}

.dropzone-preview,
progress[value="100"] {
    accent-color: green;
}

progress:not([value="100"]) {
    accent-color: red;
}

.scrollCustom::-webkit-scrollbar {
    width: 12px;
}

.scrollCustom::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #95a5a6;
    border: 1px solid #cacaca;
}

.scrollCustom::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #2c3e50;
}

.addFilesBtn {
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/css/UploadPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;IAEI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".dropzone {\r\n    overflow: hidden;\r\n    min-height: 9rem;\r\n}\r\n\r\n.dropzone-preview {\r\n    min-height: 50px;\r\n    padding: 0;\r\n    padding-left: 1rem;\r\n    padding-right: 1rem;\r\n}\r\n\r\n.dropzone-preview,\r\nprogress[value=\"100\"] {\r\n    accent-color: green;\r\n}\r\n\r\nprogress:not([value=\"100\"]) {\r\n    accent-color: red;\r\n}\r\n\r\n.scrollCustom::-webkit-scrollbar {\r\n    width: 12px;\r\n}\r\n\r\n.scrollCustom::-webkit-scrollbar-track {\r\n    border-radius: 8px;\r\n    background-color: #95a5a6;\r\n    border: 1px solid #cacaca;\r\n}\r\n\r\n.scrollCustom::-webkit-scrollbar-thumb {\r\n    border-radius: 8px;\r\n    background-color: #2c3e50;\r\n}\r\n\r\n.addFilesBtn {\r\n    margin-bottom: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import axios from 'axios';
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { decode } from 'html-entities';
import useDarkMode from 'use-dark-mode';
import useSnackbar from '../../hooks/useSnackbar';
const mainFont = "Regular-Family";


function TmdbTab() {
    const darkmode = useDarkMode();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [tmdbLink, setTmdblink] = useState('');
    const [season, setSeason] = useState(-1);
    const [creating, setCreating] = useState(false);
    const [tmdbLoading, setTmdbLoading] = useState(false);
    const [loadedTmdb, setLoadedTmdb] = useState(undefined);
    const [movie, setMovie] = useState(undefined);
    const [yearDisabled, setYearDisabled] = useState(true);
    const [movieYear, setMovieYear] = useState('');
    const [manualSeasonShow, setManualSeasonShow] = useState(false);
    const [manualSeason, setManualSeason] = useState('');
    const ryRef = useRef();
    const handleLoadData = () => {
        if (tmdbLink !== "") {
            if (tmdbLink.includes("themoviedb.org/")) {
                setTmdbLoading(true);
                axios.post(process.env.REACT_APP_API_URL + "/api/loadTmdbData", { tmdbLink: tmdbLink }, { withCredentials: true }).then(res => {
                    if (res.data.success) {
                        setMovie(res.data.movie);
                        setLoadedTmdb(tmdbLink);
                        setSeason(-1);
                        if (res.data.movie?.releaseYear === undefined) {
                            setYearDisabled(false);
                            setMovieYear('')
                            setTimeout(() => {
                                ryRef.current.focus();
                            }, 1);
                        } else {
                            setYearDisabled(true);
                            setMovieYear(res.data.movie.releaseYear)
                        }
                        if (res.data.movie?.type === "TVSeries") {
                            if (res.data.movie?.seasonNumbers.length === 0) {
                                setSeason(-2)
                                setManualSeasonShow(true);
                                setManualSeason('')
                            } else {
                                setSeason(-1);
                                setManualSeasonShow(false);
                                setManualSeason('')
                            }
                        } else {
                            setManualSeasonShow(false);
                        }
                    } else {
                        showSnackbar(res.data.errMsg || "Error.")
                    }
                    setTmdbLoading(false);
                }).catch(err => {
                    setTmdbLoading(false);
                    showSnackbar("Error.")
                })
            } else {
                return showSnackbar("TMDb Link not valid.");
            }
        }
    }
    const handleCreate = () => {
        if (tmdbLink !== "") {
            if (tmdbLink.includes("themoviedb.org/")) {
                var link = tmdbLink;
                setCreating(true);
                axios.post(process.env.REACT_APP_API_URL + "/api/createMovieTmdb", { tmdbLink: link, season: season !== undefined ? season === -2 ? manualSeason : season : null, year: movieYear }, { withCredentials: true }).then(res => {
                    if (res.data.success) {
                        navigate('/upload/' + res.data.movieId);
                    } else {
                        showSnackbar(res.data.errMsg || "Error.")
                    }
                    setCreating(false);
                }).catch(err => {
                    setCreating(false);
                    showSnackbar("Error.")
                })
            } else {
                return showSnackbar("TMDb Link not valid.");
            }
        }
    }
    const darkTheme = createTheme({
        palette: {
            mode: darkmode.value ? "dark" : "light",
        },
    });
    return (
        <><ThemeProvider theme={darkTheme}>
            <Box sx={{ maxHeight: "700px", width: "70%", overflowY: "auto", pr: "1rem" }} className={'scrollCustom'}>
                <Typography sx={{ fontFamily: mainFont, fontSize: "1.4rem", color: darkmode.value ? "white" : 'initial' }}>Add New Movie / TV-Series</Typography>
                <Divider sx={{ borderColor: "rgb(0,0,0,0.35)", marginBottom: "1rem" }} />
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <TextField
                            id="tmdb"
                            label="TMDb Link"
                            variant="outlined"
                            size='small'
                            value={tmdbLink}
                            onChange={(e, v) => { setTmdblink(e.target.value) }}
                            fullWidth
                        >
                        </TextField>
                        <Button onClick={handleLoadData} disabled={tmdbLoading || creating} variant='contained' color='success' sx={{ textTransform: "none" }}>
                            Load
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                disabled
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={movie?.type === "Movie" ? 2 : movie?.type === "TVSeries" ? 1 : 0}
                                label="Type"
                                size='small'
                            >
                                <MenuItem value={0} disabled>N/A</MenuItem>
                                <MenuItem value={1}>TV-Series</MenuItem>
                                <MenuItem value={2}>Movie</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Season</InputLabel>
                            <Select
                                disabled={movie?.type !== "TVSeries"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={season}
                                label="Season"
                                onChange={(e) => { setSeason(e.target.value); e.target.value === -2 ? setManualSeasonShow(true) : setManualSeasonShow(false) }}
                                size='small'
                            >
                                <MenuItem value={-1} disabled>N/A</MenuItem>
                                {movie?.seasonNumbers?.map(x => (
                                    <MenuItem key={x} value={x}>Season {x}</MenuItem>
                                ))}
                                <MenuItem value={-2}>Select Manually</MenuItem>
                                <MenuItem value={0}>Others (Including Complete Series)</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: manualSeasonShow ? "flex" : "none", gap: "1rem" }}>
                        <TextField
                            id="Season Number"
                            label="Season Number"
                            variant="outlined"
                            placeholder='Type season number here.'
                            value={manualSeason}
                            onChange={(e) => { setManualSeason(e.target.value); }}
                            size='small'
                            fullWidth
                        >
                        </TextField>
                    </Box>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <TextField
                            disabled={yearDisabled}
                            id="year"
                            label="Release Year"
                            placeholder='Type Release Year here.'
                            variant="outlined"
                            value={movieYear}
                            onChange={(e) => { setMovieYear(e.target.value); }}
                            size='small'
                            fullWidth
                            inputRef={ryRef}
                        >
                        </TextField>
                    </Box>
                    <Button disabled={creating || tmdbLoading || !movie || (movie.type === "TVSeries" && season === -1) || loadedTmdb !== tmdbLink} variant='contained' onClick={handleCreate} sx={{ textTransform: "none", my: "1rem", bgcolor: "teal", "&:hover": { bgcolor: "#008080c2" } }}>Create</Button>
                </Box>

            </Box >
            <Box sx={{ width: "30%", marginLeft: "1rem" }}>
                <Typography sx={{ fontFamily: mainFont, fontSize: "1.4rem", color: darkmode.value ? "white" : 'initial' }}>Movie/Series Info</Typography>
                <Divider sx={{ borderColor: "rgb(0,0,0,0.35)", marginBottom: "1rem" }} />
                {!movie ? <><Typography fontFamily={"Regular-Family"} sx={{ color: darkmode.value ? "white" : 'initial' }}>Load From TMDb To Display Information</Typography></> :
                    <>
                        <Typography sx={{ marginTop: "1.5rem", fontFamily: "Regular-Family", fontSize: "1.8rem", fontWeight: "bold", marginBottom: "1rem", color: darkmode.value ? "white" : 'initial' }}>{decode(movie?.title)} ({movie?.releaseYear})</Typography>
                        <Box>
                            <img alt={movie?.title} src={movie?.poster || ""} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://api.subsource.net/notfound.jpg";
                            }} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </Box>
                    </>
                }

            </Box>
        </ThemeProvider>
        </>
    )
}

export default TmdbTab
import React, { useEffect, useState, useContext, useRef } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Badge, Button, Chip, Container, IconButton, Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import LoginDialog from './LoginDialog';
import SignUpDialog from './SignUpDialog';
import { useUserContext } from '../../Contexts/userContext';
import { useLocation, useNavigate } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import FullLogo from "../../icons/logo_full.png";
import FullLogoDark from "../../icons/logo_full_dark.png";
import { ThreeDots } from 'react-loading-icons';
import axios from 'axios';
import SearchMovies from './SearchMovies';
import ForgetPasswordDialog from './ForgetPasswordDialog';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import DarkModeToggleBtn from '../DarkModeToggleBtn';
import useDarkMode from 'use-dark-mode';
import { CurrentMovieContext } from '../../Contexts/currentMovieContext';
import LogoutIcon from '@mui/icons-material/Logout';
import { useQuery } from '@tanstack/react-query';
import { UserApi } from '../../api/userApi';
import useSnackbar from '../../hooks/useSnackbar';
const navItems = [{ name: 'Recent Subtitles', link: "/recent" }, { name: 'Upload', link: "/upload" }];
const RegularFont = 'Regular-Family';
function Header() {
    const darkmode = useDarkMode(false);
    const controllerRef = useRef();
    const navigate = useNavigate()
    const user = useUserContext();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpenSignUp, setDialogSignUpOpen] = useState(false);
    const [dialogOpenForgetPassword, setDialogOpenForgetPassword] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [foundMovies, setFoundMovies] = useState([]);
    const [foundUsers, setFoundUsers] = useState([]);
    const [searchFocused, setSearchFocused] = useState(false);
    const [subCount, setSubCount] = useState(undefined);
    const [ticketCount, setTicketCount] = useState(0);
    const { currentMovieCtx } = useContext(CurrentMovieContext)
    const [um, setUm] = useState(0);
    const searchBarRef = useRef();
    const searchBoxRef = useRef();
    const searchInputRef = useRef();
    const location = useLocation();
    const { showSnackbar } = useSnackbar();
    const { refetch: refetchNotifs } = useQuery({
        queryKey: ['getNotifs'],
        queryFn: async () => {
            try {
                const _res = await UserApi.getNotifs();
                setUm(_res.um || 0);
                setSubCount(_res.subtitles);
                setTicketCount(_res.new_tickets);

                return _res;
            } catch (error) {
                console.log(error)
            }
        },
        enabled: user !== undefined,
    })
    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleForgetPasswordOpen = () => {
        setDialogOpenForgetPassword(true);
    }
    const handleCloseForgetPassword = () => {
        setDialogOpenForgetPassword(false);
    }
    const handleClickOpenSignUp = () => {
        setDialogSignUpOpen(true);
    };
    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleCloseSignUp = () => {
        setDialogSignUpOpen(false);
    };
    const handleNavigation = (link) => {
        if (link === "/upload") {
            if (user) {
                const currentLoc = location.pathname;
                if (currentLoc.startsWith("/subtitle") && currentMovieCtx) {
                    navigate(link + "/" + currentMovieCtx || "");
                } else if (currentLoc.startsWith("/subtitles") && currentMovieCtx) {
                    navigate(link + "/" + currentMovieCtx || "");
                } else {
                    navigate(link);
                }
            } else {
                showSnackbar('You need to be logged in.', 'error')
            }
        } else {
            navigate(link);
        }
    }
    const getUrlHrefs = (link) => {
        if (link === "/upload") {
            if (user) {
                const currentLoc = location.pathname;
                if (currentLoc.startsWith("/subtitle") && currentMovieCtx) {
                    return link + "/" + currentMovieCtx || "";
                } else if (currentLoc.startsWith("/subtitles") && currentMovieCtx) {
                    return link + "/" + currentMovieCtx || "";
                } else {
                    return link;
                }
            } else {
                return "/"
            }
        } else {
            return link;
        }
    }
    const handleSearchTextChange = (e) => {
        setSearching(true)
        setSearchText(e.target.value);
        setSearchFocused(true);
        if (e.target.value === "") {
            setSearching(false);
            setFoundMovies([])
            setFoundUsers([])
        }
    }
    useEffect(() => {
        if (searchText.length >= 1) {
            const delayDebounceFn = setTimeout(() => {
                if (controllerRef.current) {
                    controllerRef.current.abort();
                }
                controllerRef.current = new AbortController();
                const signal = controllerRef.current.signal;
                axios.post(process.env.REACT_APP_API_URL + "/api/searchMovie", { query: searchText }, { signal: signal, withCredentials: true }).then(res => {
                    if (res.data.success) {
                        setSearching(false);
                        setFoundMovies(res.data.found)
                        setFoundUsers(res.data.users)
                    }
                }).catch(err => {
                    if (err.name !== 'CanceledError') {
                        setSearching(false)
                    }
                    setFoundMovies([])
                    setFoundUsers([])
                })
            }, 600)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText])
    const handleCloseSearch = () => {
        setSearchFocused(false);
    }
    document.onclick = (e) => {
        if (e.isTrusted) {
            if ((e.clientX >= searchBoxRef.current.getBoundingClientRect().left && e.clientX <= searchBoxRef.current.getBoundingClientRect().right && e.clientY >= searchBoxRef.current.getBoundingClientRect().top && e.clientY <= searchBoxRef.current.getBoundingClientRect().bottom) || (e.clientX >= searchBarRef.current.getBoundingClientRect().left && e.clientX <= searchBarRef.current.getBoundingClientRect().right && e.clientY >= searchBarRef.current.getBoundingClientRect().top && e.clientY <= searchBarRef.current.getBoundingClientRect().bottom)) {
                searchInputRef.current.focus();
                setSearchFocused(true);
            } else {
                setSearchFocused(false);
            }
        }
    }
    useEffect(() => {
        if (user) {
            /* axios.get(process.env.REACT_APP_API_URL + "/api/user/getSubcount", { withCredentials: true }).then(res => {
                setSubCount(res.data.count || 0);
            }).catch(err => {
                setSubCount(0);
            }) */
            refetchNotifs();
        }
        // eslint-disable-next-line
    }, [user])
    return (
        <>
            <LoginDialog handleClose={handleClose} isOpen={dialogOpen} handleRegisterOpen={handleClickOpenSignUp} openForgetPassword={handleForgetPasswordOpen} />
            <SignUpDialog handleClose={handleCloseSignUp} isOpen={dialogOpenSignUp} openLogIn={handleClickOpen} openForgetPassword={handleForgetPasswordOpen} />
            <ForgetPasswordDialog handleClose={handleCloseForgetPassword} isOpen={dialogOpenForgetPassword} openLogIn={handleClickOpen} />
            <Box sx={{ px: { md: "0%", lg: "0%" } }}>
                <AppBar id="AppBar" position="fixed" color='inherit' sx={{ alignItems: "center", padding: "1rem", backgroundColor: darkmode.value ? "#121212" : "#fff", maxWidth: "3840px", right: "auto" }}>
                    <Container maxWidth="xl">
                        <Toolbar sx={{ minHeight: "50px!important", width: "100%" }}>
                            <Link href="/" sx={{ display: "flex" }}>
                                <Box sx={{ display: "flex", marginLeft: "-10px", cursor: "pointer", filter: "none" }} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/') } }}>
                                    <img src={darkmode.value ? FullLogoDark : FullLogo} alt='subsource' width={'160px'} />
                                </Box>
                            </Link>
                            <Box sx={{ flex: "auto" }}>
                                {user?.privileges?.length && !window.location.pathname.includes('admin') ? (
                                    <Link
                                        href="/admin"
                                        sx={{
                                            color: darkmode.value ? 'white' : 'black',
                                            whiteSpace: 'nowrap',
                                            fontWeight: '600',
                                            fontFamily: RegularFont,
                                            textDecoration: 'none',
                                            textTransform: 'none',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        Admin Panel
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>

                            {user ?
                                <>
                                    <Button href={'/u/' + user.id} sx={{
                                        "&:hover": { backgroundColor: 'inherit' },
                                        color: darkmode.value ? 'white' : 'black',
                                        fontWeight: '600',
                                        transition: '0ms all ease-in-out', fontFamily: RegularFont, textTransform: "none", fontSize: "1rem"
                                    }}
                                        onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/u/' + user.id) } }}>
                                        {user.username}
                                    </Button>
                                    <Link href="/dashboard/mysubsource" sx={{ display: "contents" }} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/dashboard/mysubsource') } }}>
                                        <Chip
                                            label={subCount === undefined ?
                                                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>My Subtitles <ThreeDots fill='black' width={'25px'} /></Box>
                                                : <>My Subtitles {subCount}</>}
                                            sx={{ "&:active": { boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" }, "& .MuiChip-label": { fontFamily: RegularFont, fontWeight: "600", fontSize: "1rem", color: darkmode.value ? "white" : "black" }, boxShadow: "inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)", backgroundColor: "#0080808f", "&:hover": { backgroundColor: "#0080808f" } }}>
                                        </Chip>
                                    </Link>
                                    <IconButton href={'/dashboard/messages'} sx={{ "&:hover": { background: "none" } }} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/dashboard/messages') } }}>
                                        <Badge badgeContent={um || 0} color="primary">
                                            <MailIcon color="action" sx={{ color: darkmode.value ? "white" : 'initial' }} />
                                        </Badge>
                                    </IconButton>
                                    <IconButton href={'/dashboard/tickets'} onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate('/dashboard/tickets') } }} sx={{ "&:hover": { background: "none" } }}>
                                        <Badge badgeContent={ticketCount} color="primary" >
                                            <HeadsetMicOutlinedIcon color="action" sx={{ color: darkmode.value ? "white" : 'initial' }} />
                                        </Badge>
                                    </IconButton>
                                    <IconButton sx={{ "&:hover": { background: "none" } }} onClick={() => { window.location.href = "https://api.subsource.net/auth/logout" }}>
                                        <LogoutIcon sx={{ color: "#DF2D2D" }} />
                                    </IconButton>
                                </> :
                                <>
                                    <Button key={'Login'} sx={{
                                        "&:hover": { backgroundColor: 'inherit' },
                                        color: darkmode.value ? "white" : 'black',
                                        fontWeight: '600',
                                        transition: '0ms all ease-in-out', fontFamily: RegularFont, textTransform: "none", fontSize: "1rem"
                                    }}
                                        onClick={handleClickOpen}>
                                        {'Login'}
                                    </Button>
                                    <Button key={'Register'}
                                        sx={{
                                            "&:hover": { backgroundColor: 'inherit' },
                                            color: darkmode.value ? "white" : 'black',
                                            fontWeight: '600',
                                            transition: '0ms all ease-in-out', fontFamily: RegularFont, textTransform: "none", fontSize: "1rem"

                                        }}
                                        onClick={handleClickOpenSignUp}
                                    >
                                        {'Register'}
                                    </Button></>
                            }
                            <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: '.5rem', borderColor: darkmode.value ? "white" : "initial", opacity: "0.6" }} />
                            <Box sx={{ visibility: searchFocused ? "visible" : "hidden", position: "fixed", top: '0', bottom: "0", left: "0", right: "0", backgroundColor: "rgb(0,0,0,0.6)", zIndex: '1' }}>
                            </Box>
                            <Box ref={searchBarRef} sx={{ display: "flex", mx: ".5rem", backgroundImage: !searchFocused ? darkmode.value ? "linear-gradient(#545454, #3e3e3e)" : "linear-gradient(#e9e6e6, #e9e6e6)" : darkmode.value ? "linear-gradient(#2f2f2f, rgb(0 0 0 / 0%))" : "linear-gradient(white, rgb(255,255,255,0.7))", zIndex: "2", py: '.25rem', borderRadius: "10px", width: searchFocused ? "400px" : "250px", transition: "0.2s all ease-in", boxShadow: searchFocused ? "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)" : "" }}>
                                <SearchIcon sx={{ alignSelf: "center", px: "0.25rem", color: darkmode.value ? "white" : 'initial' }} />
                                <InputBase
                                    fullWidth
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    placeholder="Search title or IMDB link..."
                                    inputProps={{
                                        'aria-label': 'search',
                                        color: "floralwhite",
                                    }}
                                    sx={{
                                        "input::placeholder": {
                                            color: darkmode.value ? "white" : 'black'
                                        },
                                    }}
                                    inputRef={searchInputRef}
                                />
                                <Box ref={searchBoxRef} sx={{ display: searchFocused === false ? "none" : foundMovies.length >= 0 || searching ? "flex" : 'none', zIndex: "1", overflow: "hidden", borderRadius: "10px", height: "auto", width: "400px", position: "absolute", bgcolor: darkmode.value ? "rgb(18,18,18)" : "white", top: "3rem", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)" }}>
                                    {searching
                                        ?
                                        <Box sx={{ margin: '0 auto', my: "2rem", display: searchText.length > 0 ? "flex" : 'none' }}><ThreeDots fill={darkmode.value ? "white" : "black"} width={"100px"} ></ThreeDots></Box>
                                        :
                                        <>
                                            <SearchMovies users={foundUsers} setFoundUsers={setFoundUsers} movies={foundMovies} handleClose={handleCloseSearch} setSearchText={setSearchText} setFoundMovies={setFoundMovies} setSearching={setSearching} searchText={searchText} />
                                        </>
                                    }
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                {navItems.map((item) => (
                                    <Button href={getUrlHrefs(item.link)} key={item.name} sx={{
                                        "&:hover": { backgroundColor: 'inherit' },
                                        color: darkmode.value ? "white" : "black",
                                        fontWeight: '600',
                                        transition: '0ms all ease-in-out', fontFamily: RegularFont, textTransform: "none", fontSize: "1rem",
                                    }}
                                        onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); handleNavigation(item.link) } }}>
                                        {item.name}
                                    </Button>
                                ))}
                            </Box>
                            <DarkModeToggleBtn />
                        </Toolbar>
                    </Container>

                </AppBar>
            </Box >
        </>
    )
}

export default Header
import { Box, Divider, Link, Skeleton, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import SubtitleIcon from '../../Reusables/SubtitleIcon';
function Movies({ data, isLoading, type }) {
    const navigate = useNavigate();
    const darkmode = useDarkMode();
    if (isLoading)
        return (
            <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography fontFamily={"Medium-Family"} fontSize={"30px"} fontWeight={"bold"} color={darkmode.value ? "white" : "#21495F"}>{type === "Series" ? "Popular TV Subtitles" : "Popular Movie Subtitles"}</Typography>
                    <Divider sx={{ borderColor: darkmode.value ? "white" : "black", borderBottomWidth: "2px" }} />
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.75rem", my: "1rem" }}>
                        {[1, 2, 3].map(x => (
                            <Box key={x} sx={{ width: "100%", display: "flex", backgroundColor: darkmode.value ? "#222222" : "white", borderRadius: "10px", boxShadow: "0px 0px 12px #00000040", height: "215px" }}>
                                <Box sx={{ display: "flex", padding: "15px" }}>
                                    <Box className='image-hover'>
                                        <Skeleton animation="wave" variant="rectangular" sx={{ width: "125px", height: "187px", borderRadius: "7px" }} />
                                    </Box>
                                    <Box sx={{ px: "1.25rem" }}>
                                        <Box sx={{ display: 'flex', alignItems: "flex-start", gap: "0.5rem", }}>
                                            <Skeleton animation="wave" variant="rounded" sx={{ height: "30px", width: "300px" }}></Skeleton>
                                            <Skeleton animation="wave" variant='circular' width={'30px'} height={"30px"} />
                                        </Box>
                                        <Box sx={{ mt: "0.5rem" }}>
                                            {[1, 2, 3].map(sub => (
                                                <Skeleton key={sub} animation="wave" variant="rounded" sx={{ fontSize: "11px", width: "300px", mb: "0.25rem" }}></Skeleton>
                                            ))}
                                            <Skeleton animation="wave" variant="rounded" sx={{ fontSize: "11px", width: "300px", mb: "0.25rem" }}></Skeleton>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        ))}
                    </Box>
                </Box>
            </>
        )
    return (
        <Box sx={{ display: data?.length > 0 ? "flex" : "none", flexDirection: "column" }}>
            <Typography fontFamily={"Medium-Family"} fontSize={"30px"} fontWeight={"bold"} color={darkmode.value ? "white" : "#21495F"}>{type === "Series" ? "Popular TV Subtitles" : "Popular Movie Subtitles"}</Typography>
            <Divider sx={{ borderColor: darkmode.value ? "white" : "black", borderBottomWidth: "2px" }} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.75rem", my: "1rem" }}>
                {data?.map(x => (
                    <Box key={x.link} sx={{ width: "100%", display: "flex", backgroundColor: darkmode.value ? "#222222" : "white", borderRadius: "10px", boxShadow: "0px 0px 12px #00000040", height: "215px" }}>
                        <Box sx={{ display: "flex", padding: "15px" }}>
                            <Box className='image-hover'>
                                <img fetchPriority='high' onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://api.subsource.net/notfound.jpg";
                                }} src={x.poster} alt={x.title} style={{ height: "187px", width: "125px", borderRadius: "7px", cursor: "pointer" }} onClick={() => navigate(x.fullLink)} />
                            </Box>
                            <Box sx={{ px: "1.25rem" }}>
                                <Box sx={{ display: 'flex', alignItems: "flex-start", gap: "0.5rem", }}>
                                    <Link onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate(x.fullLink) } }} href={x.fullLink} sx={{ textDecoration: "none", fontFamily: "Regular-Family", fontWeight: "bold", fontSize: "20px", color: darkmode.value ? "white" : "#21495F" }}>{x.title}</Link>
                                </Box>
                                <Box sx={{ mt: "0.5rem", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                    {x.subs.map(sub => (
                                        <Box sx={{ backgroundColor: darkmode.value ? "rgba(0, 130, 132, 0.25)" : "rgb(0, 93, 132, 0.08)", borderRadius: "6px", width: "max-content", px: "0.25rem" }}>
                                            <Link
                                                key={sub.id}
                                                onClick={(e) => { if (!e.ctrlKey) { e.preventDefault(); navigate("/subtitle/" + sub.linkName + "/" + sub.language + "/" + sub.id) } }}
                                                href={"/subtitle/" + sub.linkName + "/" + sub.language + "/" + sub.id}
                                                sx={{
                                                    textDecoration: "none",
                                                    color: darkmode.value ? "white" : "#21495F"
                                                }}
                                            ><Typography sx={{ fontFamily: "Regular-Family", fontSize: "14px", display: "flex", alignItems: "center", }}><SubtitleIcon style={{ fontSize: "1rem", color: "green", mr: "0.15rem" }} />{sub.languageFormatted} Subtitle By {sub.uploadedByUsername}</Typography></Link>
                                        </Box>

                                    ))}
                                    <Typography sx={{ fontFamily: "Regular-Family", fontSize: "0.8rem", display: "flex", alignItems: "center", color: darkmode.value ? "white" : 'initial' }}>{x.totalDownloads} downloads of {x.totalSubs} subtitles</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default Movies